import { botPattern } from '../platform'

const TRANSLATE_TARGET_PAGE = [
  'index', // TOPページ
  'services-id', // サービス詳細
  'categories-categoryId', // カテゴリ
  'categories-categoryId-categoryTypeId', // カテゴリタイプあり
  'search', // カテゴリ検索
  'talkrooms-talkroomId', //トークルーム
  'talkrooms-talkroomId-information', // トークルーム取引詳細,
  'mypage-orders-canceled', // 取引管理
  'mypage-orders-closed', // 取引管理
  'mypage-orders-flags', // 取引管理
  'mypage-orders-open', // 取引管理
  'mypage-orders-requests', // 取引管理
  'mypage-orders-required', // 取引管理
  'mypage-orders-trash', // 取引管理
  'users-id-reviews', // 実績・評価
  'users-id-careers', // スキル・経歴
  'users-id-portfolios', // ポートフォリオ
  'customize-requests-add-userId-serviceId', // サービス経由の見積もり提案
  'customize-requests-add-userId', // プロフィール経由の見積もり提案
  'users-id-services', // サービス出品
  'users-id-prices', // 商品価格
  'users-id', // ホーム
  'users-search', // 出品者検索
  'users', // 出品者検索TOP
  'mypage-ordermade-requests-id-detail', // プロフィール経由の見積もり相談確認画面（マイページ）
  'mypage-language', //言語設定画面
  'mypage-customize-requests-id-detail', // サービス経由の見積もり確認画面（マイページ）
  'pages-about', // 初心者ガイド
  'register-top', // 会員登録トップ
  'register-buyer_provider', // 会員登録[登録目的を選択]
  'register-purpose-entity', //会員登録[基本情報入力]
  'register-buyer-entity-user_interest_categories', // 会員登録[興味のあるカテゴリ]
  'register-purpose-entity-user_name', // 会員登録[完了]
  'users-id-skills', // 出品者プロフィール（職種・スキル）
  'message-directMessagesRoomId-report' // DMからの通報
]
export const translateTargetPage = (routerName: string) => {
  // Botのブラウザ設定が不明かつwindowオブジェクトにアクセスするため、一応try catch を実施。
  try {
    if (process.client && window.$nuxt && window.$nuxt.context && window.$nuxt.context.store) {
      const userAgent = window.$nuxt?.context?.store?.state?.auth?.ua || ''
      const isBot = botPattern.test(userAgent)
      if (isBot) return false
    }
  } catch (error) {
    // なにもしない
  }
  return TRANSLATE_TARGET_PAGE.includes(routerName)
}
