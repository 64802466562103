import { grpc } from '@improbable-eng/grpc-web'
import { NodeHttpTransport } from '@improbable-eng/grpc-web-node-http-transport'

/**
 * '1.1.1'形式のアプリバージョン文字列として正しいかどうかを判定する
 * @param {string} version アプリバージョン文字列
 */
const _isValidAppVersion = version => {
  if (typeof version !== 'string' || !version.match(/\d+\.\d+\.\d+/)) {
    return false
  }
  return true
}
/**
 * 2つのアプリバージョンを比較する
 * @param {string} appVersion '1.1.1'形式のアプリバージョン文字列
 * @param {string} compareVersion '1.1.1'形式のアプリバージョン文字列
 * @return {boolean} appVersionの方が大きい場合true, 小さい場合false, 等しい場合null
 */
const _appVersionCompare = (appVersion, compareVersion) => {
  appVersion = appVersion.split('.').map(Number)
  compareVersion = compareVersion.split('.').map(Number)

  for (let i = 0; i < 3; i++) {
    // i桁目の大小比較をする
    if (appVersion[i] > compareVersion[i]) {
      return 1
    } else if (appVersion[i] < compareVersion[i]) {
      return -1
    }
    // i桁目が等しい場合次の桁の比較へ進む
  }

  return 0
}

import * as user from './auth/user'
const modules = {
  user
}

// initial state
export const state = () => ({
  appId: null,
  appStoreUrl: null,
  sToken: null,
  sTokenP: null,
  sTokenE: null,
  ccuid: null,
  ua: null,
  ip: null,
  initialReferer: null,
  cakeSession: null
})

// getters
export const getters = {
  isLoggedIn(state) {
    return !!(state.user && state.user.id)
  },
  isFromCoconalaIOS(state) {
    if (!process.env.config) return false
    if (state.appId === process.env.config['app-ids']['ios']) {
      return true
    }
    if (state.ua && state.ua.includes(process.env.config['app-ids']['ios'])) {
      return true
    }
    return false
  },
  isFromCoconalaAndroid(state) {
    if (!process.env.config) return false
    if (state.appId === process.env.config['app-ids']['android']) {
      return true
    }
    if (state.ua && state.ua.includes(process.env.config['app-ids']['android'])) {
      return true
    }
    return false
  },
  isFromCoconalaApp(state, getters) {
    return getters.isFromCoconalaIOS || getters.isFromCoconalaAndroid
  },
  isFromIE(state) {
    return state.ua.indexOf('Trident') != -1 || state.ua.indexOf('MSIE') != -1
  },
  appVersion(state, getters) {
    if (getters.isFromCoconalaApp) {
      return state.ua.split(';')[0].match(/\d+\.\d+\.\d+/)[0]
    } else {
      return null
    }
  },
  // アプリバージョンが引数の比較バージョンより大きい場合trueを返す（等しい場合はfalse）
  appVersionGt: (state, getters) => compareVersion => {
    if (!_isValidAppVersion(compareVersion) || !_isValidAppVersion(getters.appVersion)) {
      return false
    }
    const result = _appVersionCompare(getters.appVersion, compareVersion)
    return result > 0
  },
  // アプリバージョンが引数の比較バージョンと等しいか大きい場合trueを返す
  appVersionGte: (state, getters) => compareVersion => {
    if (!_isValidAppVersion(compareVersion) || !_isValidAppVersion(getters.appVersion)) {
      return false
    }
    const result = _appVersionCompare(getters.appVersion, compareVersion)
    return result >= 0
  },
  // アプリバージョンが引数の比較バージョンと等しい場合trueを返す
  appVersionEq: (state, getters) => compareVersion => {
    if (!_isValidAppVersion(compareVersion) || !_isValidAppVersion(getters.appVersion)) {
      return false
    }
    const result = _appVersionCompare(getters.appVersion, compareVersion)
    return result === 0
  },
  // アプリバージョンが引数の比較バージョンより小さい場合trueを返す（等しい場合はfalse）
  appVersionLt: (state, getters) => compareVersion => {
    if (!_isValidAppVersion(compareVersion) || !_isValidAppVersion(getters.appVersion)) {
      return false
    }
    const result = _appVersionCompare(getters.appVersion, compareVersion)
    return result < 0
  },
  // アプリバージョンが引数の比較バージョンと等しいか小さい場合trueを返す
  appVersionLte: (state, getters) => compareVersion => {
    if (!_isValidAppVersion(compareVersion) || !_isValidAppVersion(getters.appVersion)) {
      return false
    }
    const result = _appVersionCompare(getters.appVersion, compareVersion)
    return result <= 0
  },
  // アプリバナーABテスト: 未ログインサービス詳細ページ・サービス検索ページのABテスト用
  uiPatternForSp: state => {
    return state.ccuid ? parseInt(state.ccuid.split(':')[1], 16) % 2 : 0
  }
}

// actions
export const actions = {
  clearAllModules({ commit, dispatch }) {
    commit('RESET_SESSION_TOKEN')
    Object.keys(modules).forEach(moduleName => {
      commit(`${moduleName}/RESET`)
    })
    dispatch('pages/enterprise/invitations/reset', null, { root: true })
    dispatch('pages/enterprise/members/reset', null, { root: true })
    dispatch('pages/enterprise/orders/reset', null, { root: true })
  },

  async tryAuth({ commit, dispatch, state }, { token }) {
    try {
      if (!token) {
        throw 16
      }
      const responseCode = await new Promise(async (resolve, reject) => {
        const [{ UpdateLastLoginRequest }, { UserService }] = await Promise.all([
          import('~/stub/apigateway/user/user_pb'),
          import('~/stub/apigateway/user/user_pb_service')
        ])
        const request = new UpdateLastLoginRequest()
        const metadata = {
          'x-auth-token': [token]
        }
        grpc.invoke(UserService.UpdateLastLogin, {
          request,
          metadata,
          host: process.env.config.grpcWebUrl,
          transport: NodeHttpTransport(),
          onEnd: (code, msg, trailers) => {
            resolve(code)
          }
        })
      })

      if (responseCode === grpc.Code.OK) {
        commit('SET_SESSION_TOKEN', token)
        if (this.$cookies.get(process.env.config['session-key-private'])) {
          commit(
            'SET_SESSION_TOKEN_P',
            this.$cookies.get(process.env.config['session-key-private'])
          )
        }
        if (this.$cookies.get(process.env.config['session-key-business'])) {
          commit(
            'SET_SESSION_TOKEN_E',
            this.$cookies.get(process.env.config['session-key-business'])
          )
        }
        return Promise.resolve(true)
      } else {
        throw responseCode
      }
    } catch (code) {
      if (code === 16) {
        await dispatch('clearAllModules')
        this.$cookies.remove(process.env.config['session-key'], {
          domain: '.coconala.com',
          path: '/',
          httpOnly: true,
          secure: true
        })
        return Promise.resolve(false)
      }
      return Promise.reject(code)
    }
  },

  async setAppId({ commit }) {
    let appId = this.$cookies.get(process.env.config['app-id-key'])
    let validAppIds = process.env.config['app-ids']
    if (appId && Object.values(validAppIds).includes(appId)) {
      commit('SET_APP_ID', appId)
    } else {
      commit('SET_APP_ID', null)
    }
  }
}

// mutations
export const mutations = {
  SET_APP_ID(state, appId) {
    state.appId = appId
  },
  SET_APP_STORE_URL(state, appStoreUrl) {
    state.appStoreUrl = appStoreUrl
  },
  SET_SESSION_TOKEN(state, token) {
    state.sToken = token
  },
  SET_SESSION_TOKEN_P(state, token) {
    state.sTokenP = token
  },
  SET_SESSION_TOKEN_E(state, token) {
    state.sTokenE = token
  },
  SET_CCUID(state, ccuid) {
    state.ccuid = ccuid
  },
  SET_CAKEPHP(state, cakeSession) {
    state.cakeSession = cakeSession
  },
  SET_USER_AGENT(state, ua) {
    state.ua = ua
  },
  SET_CLIENT_IP(state, ip) {
    state.ip = ip
  },
  SET_INITIAL_REFERER(state, initialReferer) {
    state.initialReferer = initialReferer
  },
  RESET_SESSION_TOKEN(state) {
    state.sToken = null
  },
  SET_AMZN_TRACE_ID(state, amznTraceId) {
    state.amznTraceId = amznTraceId
  }
}
