// source: apigateway/request/request.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.request.AddAnswerReply', null, global);
goog.exportSymbol('proto.request.AddAnswerRequest', null, global);
goog.exportSymbol('proto.request.AddBookmarkRequest', null, global);
goog.exportSymbol('proto.request.AddBookmarkResponse', null, global);
goog.exportSymbol('proto.request.AddQuestionReply', null, global);
goog.exportSymbol('proto.request.AddQuestionRequest', null, global);
goog.exportSymbol('proto.request.AddRequestInformationReply', null, global);
goog.exportSymbol('proto.request.AddRequestInformationRequest', null, global);
goog.exportSymbol('proto.request.Answer', null, global);
goog.exportSymbol('proto.request.AttachmentFiles', null, global);
goog.exportSymbol('proto.request.Condition', null, global);
goog.exportSymbol('proto.request.CopyRequest', null, global);
goog.exportSymbol('proto.request.DeleteBookmarkRequest', null, global);
goog.exportSymbol('proto.request.DeleteBookmarkResponse', null, global);
goog.exportSymbol('proto.request.DeleteRequestInformationReply', null, global);
goog.exportSymbol('proto.request.DeleteRequestInformationRequest', null, global);
goog.exportSymbol('proto.request.ExtraCondition', null, global);
goog.exportSymbol('proto.request.GetMyRequestsRequest', null, global);
goog.exportSymbol('proto.request.GetMyRequestsResponse', null, global);
goog.exportSymbol('proto.request.GetPopularRequestsRequest', null, global);
goog.exportSymbol('proto.request.GetPopularRequestsResponse', null, global);
goog.exportSymbol('proto.request.GetQuestionsReply', null, global);
goog.exportSymbol('proto.request.GetQuestionsRequest', null, global);
goog.exportSymbol('proto.request.GetRecommendRequestsRequest', null, global);
goog.exportSymbol('proto.request.GetRecommendRequestsResponse', null, global);
goog.exportSymbol('proto.request.GetRequestCopyRequest', null, global);
goog.exportSymbol('proto.request.GetRequestCopyResponse', null, global);
goog.exportSymbol('proto.request.GetSearchRequestsConditionReply', null, global);
goog.exportSymbol('proto.request.IsAuthorizedRequest', null, global);
goog.exportSymbol('proto.request.IsAuthorizedResponse', null, global);
goog.exportSymbol('proto.request.IsQuestionAuthorizedRequest', null, global);
goog.exportSymbol('proto.request.IsQuestionAuthorizedResponse', null, global);
goog.exportSymbol('proto.request.ListBookmarksRequest', null, global);
goog.exportSymbol('proto.request.ListBookmarksResponse', null, global);
goog.exportSymbol('proto.request.ListCopyTargetRequestsRequest', null, global);
goog.exportSymbol('proto.request.ListCopyTargetRequestsResponse', null, global);
goog.exportSymbol('proto.request.ListProposedRequestsRequest', null, global);
goog.exportSymbol('proto.request.ListProposedRequestsResponse', null, global);
goog.exportSymbol('proto.request.MasterCategory', null, global);
goog.exportSymbol('proto.request.MyRequest', null, global);
goog.exportSymbol('proto.request.PriceRange', null, global);
goog.exportSymbol('proto.request.ProposedRequest', null, global);
goog.exportSymbol('proto.request.Question', null, global);
goog.exportSymbol('proto.request.QuestionReason', null, global);
goog.exportSymbol('proto.request.Reason', null, global);
goog.exportSymbol('proto.request.Request', null, global);
goog.exportSymbol('proto.request.RequestItem', null, global);
goog.exportSymbol('proto.request.RequestSkill', null, global);
goog.exportSymbol('proto.request.SearchRequestsReply', null, global);
goog.exportSymbol('proto.request.SearchRequestsRequest', null, global);
goog.exportSymbol('proto.request.SubMasterCategory', null, global);
goog.exportSymbol('proto.request.UpdateRequestInformationRequest', null, global);
goog.exportSymbol('proto.request.UpdateRequestInformationResponse', null, global);
goog.exportSymbol('proto.request.User', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.GetMyRequestsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.GetMyRequestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.GetMyRequestsRequest.displayName = 'proto.request.GetMyRequestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.GetMyRequestsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.GetMyRequestsResponse.repeatedFields_, null);
};
goog.inherits(proto.request.GetMyRequestsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.GetMyRequestsResponse.displayName = 'proto.request.GetMyRequestsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.MyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.MyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.MyRequest.displayName = 'proto.request.MyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.SearchRequestsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.SearchRequestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.SearchRequestsRequest.displayName = 'proto.request.SearchRequestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.SearchRequestsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.SearchRequestsReply.repeatedFields_, null);
};
goog.inherits(proto.request.SearchRequestsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.SearchRequestsReply.displayName = 'proto.request.SearchRequestsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.Request.displayName = 'proto.request.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.GetSearchRequestsConditionReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.GetSearchRequestsConditionReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.GetSearchRequestsConditionReply.displayName = 'proto.request.GetSearchRequestsConditionReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.Condition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.Condition.repeatedFields_, null);
};
goog.inherits(proto.request.Condition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.Condition.displayName = 'proto.request.Condition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.MasterCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.MasterCategory.repeatedFields_, null);
};
goog.inherits(proto.request.MasterCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.MasterCategory.displayName = 'proto.request.MasterCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.SubMasterCategory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.SubMasterCategory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.SubMasterCategory.displayName = 'proto.request.SubMasterCategory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.PriceRange = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.PriceRange, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.PriceRange.displayName = 'proto.request.PriceRange';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.ExtraCondition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.ExtraCondition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.ExtraCondition.displayName = 'proto.request.ExtraCondition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.GetQuestionsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.GetQuestionsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.GetQuestionsRequest.displayName = 'proto.request.GetQuestionsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.GetQuestionsReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.GetQuestionsReply.repeatedFields_, null);
};
goog.inherits(proto.request.GetQuestionsReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.GetQuestionsReply.displayName = 'proto.request.GetQuestionsReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.User = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.User, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.User.displayName = 'proto.request.User';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.Answer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.Answer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.Answer.displayName = 'proto.request.Answer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.Question = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.Question, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.Question.displayName = 'proto.request.Question';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.AddQuestionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.AddQuestionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.AddQuestionRequest.displayName = 'proto.request.AddQuestionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.AddQuestionReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.AddQuestionReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.AddQuestionReply.displayName = 'proto.request.AddQuestionReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.AddAnswerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.AddAnswerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.AddAnswerRequest.displayName = 'proto.request.AddAnswerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.AddAnswerReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.AddAnswerReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.AddAnswerReply.displayName = 'proto.request.AddAnswerReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.AddRequestInformationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.AddRequestInformationRequest.repeatedFields_, null);
};
goog.inherits(proto.request.AddRequestInformationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.AddRequestInformationRequest.displayName = 'proto.request.AddRequestInformationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.RequestSkill = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.RequestSkill, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.RequestSkill.displayName = 'proto.request.RequestSkill';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.AttachmentFiles = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.AttachmentFiles, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.AttachmentFiles.displayName = 'proto.request.AttachmentFiles';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.AddRequestInformationReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.AddRequestInformationReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.AddRequestInformationReply.displayName = 'proto.request.AddRequestInformationReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.UpdateRequestInformationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.UpdateRequestInformationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.UpdateRequestInformationRequest.displayName = 'proto.request.UpdateRequestInformationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.UpdateRequestInformationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.UpdateRequestInformationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.UpdateRequestInformationResponse.displayName = 'proto.request.UpdateRequestInformationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.DeleteRequestInformationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.DeleteRequestInformationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.DeleteRequestInformationRequest.displayName = 'proto.request.DeleteRequestInformationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.DeleteRequestInformationReply = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.DeleteRequestInformationReply, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.DeleteRequestInformationReply.displayName = 'proto.request.DeleteRequestInformationReply';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.GetRequestCopyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.GetRequestCopyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.GetRequestCopyRequest.displayName = 'proto.request.GetRequestCopyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.GetRequestCopyResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.GetRequestCopyResponse.repeatedFields_, null);
};
goog.inherits(proto.request.GetRequestCopyResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.GetRequestCopyResponse.displayName = 'proto.request.GetRequestCopyResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.RequestItem = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.RequestItem.repeatedFields_, null);
};
goog.inherits(proto.request.RequestItem, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.RequestItem.displayName = 'proto.request.RequestItem';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.IsAuthorizedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.IsAuthorizedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.IsAuthorizedRequest.displayName = 'proto.request.IsAuthorizedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.IsAuthorizedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.IsAuthorizedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.IsAuthorizedResponse.displayName = 'proto.request.IsAuthorizedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.Reason = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.Reason, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.Reason.displayName = 'proto.request.Reason';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.ListBookmarksRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.ListBookmarksRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.ListBookmarksRequest.displayName = 'proto.request.ListBookmarksRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.ListBookmarksResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.ListBookmarksResponse.repeatedFields_, null);
};
goog.inherits(proto.request.ListBookmarksResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.ListBookmarksResponse.displayName = 'proto.request.ListBookmarksResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.AddBookmarkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.AddBookmarkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.AddBookmarkRequest.displayName = 'proto.request.AddBookmarkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.AddBookmarkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.AddBookmarkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.AddBookmarkResponse.displayName = 'proto.request.AddBookmarkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.DeleteBookmarkRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.DeleteBookmarkRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.DeleteBookmarkRequest.displayName = 'proto.request.DeleteBookmarkRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.DeleteBookmarkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.DeleteBookmarkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.DeleteBookmarkResponse.displayName = 'proto.request.DeleteBookmarkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.ListCopyTargetRequestsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.ListCopyTargetRequestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.ListCopyTargetRequestsRequest.displayName = 'proto.request.ListCopyTargetRequestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.ListCopyTargetRequestsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.ListCopyTargetRequestsResponse.repeatedFields_, null);
};
goog.inherits(proto.request.ListCopyTargetRequestsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.ListCopyTargetRequestsResponse.displayName = 'proto.request.ListCopyTargetRequestsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.CopyRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.CopyRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.CopyRequest.displayName = 'proto.request.CopyRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.ListProposedRequestsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.ListProposedRequestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.ListProposedRequestsRequest.displayName = 'proto.request.ListProposedRequestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.ListProposedRequestsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.ListProposedRequestsResponse.repeatedFields_, null);
};
goog.inherits(proto.request.ListProposedRequestsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.ListProposedRequestsResponse.displayName = 'proto.request.ListProposedRequestsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.ProposedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.ProposedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.ProposedRequest.displayName = 'proto.request.ProposedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.IsQuestionAuthorizedRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.IsQuestionAuthorizedRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.IsQuestionAuthorizedRequest.displayName = 'proto.request.IsQuestionAuthorizedRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.IsQuestionAuthorizedResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.IsQuestionAuthorizedResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.IsQuestionAuthorizedResponse.displayName = 'proto.request.IsQuestionAuthorizedResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.QuestionReason = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.QuestionReason, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.QuestionReason.displayName = 'proto.request.QuestionReason';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.GetRecommendRequestsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.GetRecommendRequestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.GetRecommendRequestsRequest.displayName = 'proto.request.GetRecommendRequestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.GetRecommendRequestsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.GetRecommendRequestsResponse.repeatedFields_, null);
};
goog.inherits(proto.request.GetRecommendRequestsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.GetRecommendRequestsResponse.displayName = 'proto.request.GetRecommendRequestsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.GetPopularRequestsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.request.GetPopularRequestsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.GetPopularRequestsRequest.displayName = 'proto.request.GetPopularRequestsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.request.GetPopularRequestsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.request.GetPopularRequestsResponse.repeatedFields_, null);
};
goog.inherits(proto.request.GetPopularRequestsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.request.GetPopularRequestsResponse.displayName = 'proto.request.GetPopularRequestsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.GetMyRequestsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.GetMyRequestsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.GetMyRequestsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetMyRequestsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: (f = msg.getPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    perPage: (f = msg.getPerPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.GetMyRequestsRequest}
 */
proto.request.GetMyRequestsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.GetMyRequestsRequest;
  return proto.request.GetMyRequestsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.GetMyRequestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.GetMyRequestsRequest}
 */
proto.request.GetMyRequestsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.GetMyRequestsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.GetMyRequestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.GetMyRequestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetMyRequestsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPerPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value page = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.request.GetMyRequestsRequest.prototype.getPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.request.GetMyRequestsRequest} returns this
*/
proto.request.GetMyRequestsRequest.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.GetMyRequestsRequest} returns this
 */
proto.request.GetMyRequestsRequest.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.GetMyRequestsRequest.prototype.hasPage = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Int32Value per_page = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.request.GetMyRequestsRequest.prototype.getPerPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.request.GetMyRequestsRequest} returns this
*/
proto.request.GetMyRequestsRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.GetMyRequestsRequest} returns this
 */
proto.request.GetMyRequestsRequest.prototype.clearPerPage = function() {
  return this.setPerPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.GetMyRequestsRequest.prototype.hasPerPage = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.GetMyRequestsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.GetMyRequestsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.request.GetMyRequestsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.GetMyRequestsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetMyRequestsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestsList: jspb.Message.toObjectList(msg.getRequestsList(),
    proto.request.MyRequest.toObject, includeInstance),
    page: (f = msg.getPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    perPage: (f = msg.getPerPage()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    totalCount: (f = msg.getTotalCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.GetMyRequestsResponse}
 */
proto.request.GetMyRequestsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.GetMyRequestsResponse;
  return proto.request.GetMyRequestsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.GetMyRequestsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.GetMyRequestsResponse}
 */
proto.request.GetMyRequestsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.request.MyRequest;
      reader.readMessage(value,proto.request.MyRequest.deserializeBinaryFromReader);
      msg.addRequests(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPage(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPerPage(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setTotalCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.GetMyRequestsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.GetMyRequestsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.GetMyRequestsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetMyRequestsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.request.MyRequest.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPerPage();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTotalCount();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MyRequest requests = 1;
 * @return {!Array<!proto.request.MyRequest>}
 */
proto.request.GetMyRequestsResponse.prototype.getRequestsList = function() {
  return /** @type{!Array<!proto.request.MyRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.request.MyRequest, 1));
};


/**
 * @param {!Array<!proto.request.MyRequest>} value
 * @return {!proto.request.GetMyRequestsResponse} returns this
*/
proto.request.GetMyRequestsResponse.prototype.setRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.request.MyRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.request.MyRequest}
 */
proto.request.GetMyRequestsResponse.prototype.addRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.request.MyRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.GetMyRequestsResponse} returns this
 */
proto.request.GetMyRequestsResponse.prototype.clearRequestsList = function() {
  return this.setRequestsList([]);
};


/**
 * optional google.protobuf.Int32Value page = 2;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.request.GetMyRequestsResponse.prototype.getPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 2));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.request.GetMyRequestsResponse} returns this
*/
proto.request.GetMyRequestsResponse.prototype.setPage = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.GetMyRequestsResponse} returns this
 */
proto.request.GetMyRequestsResponse.prototype.clearPage = function() {
  return this.setPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.GetMyRequestsResponse.prototype.hasPage = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Int32Value per_page = 3;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.request.GetMyRequestsResponse.prototype.getPerPage = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 3));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.request.GetMyRequestsResponse} returns this
*/
proto.request.GetMyRequestsResponse.prototype.setPerPage = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.GetMyRequestsResponse} returns this
 */
proto.request.GetMyRequestsResponse.prototype.clearPerPage = function() {
  return this.setPerPage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.GetMyRequestsResponse.prototype.hasPerPage = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Int32Value total_count = 4;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.request.GetMyRequestsResponse.prototype.getTotalCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 4));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.request.GetMyRequestsResponse} returns this
*/
proto.request.GetMyRequestsResponse.prototype.setTotalCount = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.GetMyRequestsResponse} returns this
 */
proto.request.GetMyRequestsResponse.prototype.clearTotalCount = function() {
  return this.setTotalCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.GetMyRequestsResponse.prototype.hasTotalCount = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.MyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.MyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.MyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.MyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: (f = msg.getRequestId()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    title: (f = msg.getTitle()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isDraft: (f = msg.getIsDraft()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    isWithholdingTax: (f = msg.getIsWithholdingTax()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    priceMin: (f = msg.getPriceMin()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    priceMax: (f = msg.getPriceMax()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    expireDate: (f = msg.getExpireDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    offerCount: (f = msg.getOfferCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f),
    orderedOfferCount: (f = msg.getOrderedOfferCount()) && google_protobuf_wrappers_pb.Int32Value.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.MyRequest}
 */
proto.request.MyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.MyRequest;
  return proto.request.MyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.MyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.MyRequest}
 */
proto.request.MyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setRequestId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setTitle(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsDraft(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsWithholdingTax(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPriceMin(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setPriceMax(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setExpireDate(value);
      break;
    case 8:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOfferCount(value);
      break;
    case 9:
      var value = new google_protobuf_wrappers_pb.Int32Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.Int32Value.deserializeBinaryFromReader);
      msg.setOrderedOfferCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.MyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.MyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.MyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.MyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getTitle();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsDraft();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getIsWithholdingTax();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPriceMin();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getPriceMax();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getExpireDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getOfferCount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
  f = message.getOrderedOfferCount();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_wrappers_pb.Int32Value.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Int32Value request_id = 1;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.request.MyRequest.prototype.getRequestId = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 1));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.request.MyRequest} returns this
*/
proto.request.MyRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.MyRequest} returns this
 */
proto.request.MyRequest.prototype.clearRequestId = function() {
  return this.setRequestId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.MyRequest.prototype.hasRequestId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue title = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.request.MyRequest.prototype.getTitle = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.request.MyRequest} returns this
*/
proto.request.MyRequest.prototype.setTitle = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.MyRequest} returns this
 */
proto.request.MyRequest.prototype.clearTitle = function() {
  return this.setTitle(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.MyRequest.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.BoolValue is_draft = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.request.MyRequest.prototype.getIsDraft = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.request.MyRequest} returns this
*/
proto.request.MyRequest.prototype.setIsDraft = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.MyRequest} returns this
 */
proto.request.MyRequest.prototype.clearIsDraft = function() {
  return this.setIsDraft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.MyRequest.prototype.hasIsDraft = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.BoolValue is_withholding_tax = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.request.MyRequest.prototype.getIsWithholdingTax = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.request.MyRequest} returns this
*/
proto.request.MyRequest.prototype.setIsWithholdingTax = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.MyRequest} returns this
 */
proto.request.MyRequest.prototype.clearIsWithholdingTax = function() {
  return this.setIsWithholdingTax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.MyRequest.prototype.hasIsWithholdingTax = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.Int32Value price_min = 5;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.request.MyRequest.prototype.getPriceMin = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 5));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.request.MyRequest} returns this
*/
proto.request.MyRequest.prototype.setPriceMin = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.MyRequest} returns this
 */
proto.request.MyRequest.prototype.clearPriceMin = function() {
  return this.setPriceMin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.MyRequest.prototype.hasPriceMin = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Int32Value price_max = 6;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.request.MyRequest.prototype.getPriceMax = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 6));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.request.MyRequest} returns this
*/
proto.request.MyRequest.prototype.setPriceMax = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.MyRequest} returns this
 */
proto.request.MyRequest.prototype.clearPriceMax = function() {
  return this.setPriceMax(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.MyRequest.prototype.hasPriceMax = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp expire_date = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.request.MyRequest.prototype.getExpireDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.request.MyRequest} returns this
*/
proto.request.MyRequest.prototype.setExpireDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.MyRequest} returns this
 */
proto.request.MyRequest.prototype.clearExpireDate = function() {
  return this.setExpireDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.MyRequest.prototype.hasExpireDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.protobuf.Int32Value offer_count = 8;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.request.MyRequest.prototype.getOfferCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 8));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.request.MyRequest} returns this
*/
proto.request.MyRequest.prototype.setOfferCount = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.MyRequest} returns this
 */
proto.request.MyRequest.prototype.clearOfferCount = function() {
  return this.setOfferCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.MyRequest.prototype.hasOfferCount = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Int32Value ordered_offer_count = 9;
 * @return {?proto.google.protobuf.Int32Value}
 */
proto.request.MyRequest.prototype.getOrderedOfferCount = function() {
  return /** @type{?proto.google.protobuf.Int32Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.Int32Value, 9));
};


/**
 * @param {?proto.google.protobuf.Int32Value|undefined} value
 * @return {!proto.request.MyRequest} returns this
*/
proto.request.MyRequest.prototype.setOrderedOfferCount = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.MyRequest} returns this
 */
proto.request.MyRequest.prototype.clearOrderedOfferCount = function() {
  return this.setOrderedOfferCount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.MyRequest.prototype.hasOrderedOfferCount = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.SearchRequestsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.SearchRequestsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.SearchRequestsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.SearchRequestsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    keyword: jspb.Message.getFieldWithDefault(msg, 3, ""),
    priceMin: jspb.Message.getFieldWithDefault(msg, 4, 0),
    priceMax: jspb.Message.getFieldWithDefault(msg, 5, 0),
    recruiting: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    budgetDefined: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    userIconSize: jspb.Message.getFieldWithDefault(msg, 9, ""),
    sortType: jspb.Message.getFieldWithDefault(msg, 10, ""),
    masterCategoryId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    businessFlag: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.SearchRequestsRequest}
 */
proto.request.SearchRequestsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.SearchRequestsRequest;
  return proto.request.SearchRequestsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.SearchRequestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.SearchRequestsRequest}
 */
proto.request.SearchRequestsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceMin(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceMax(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecruiting(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBudgetDefined(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserIconSize(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortType(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMasterCategoryId(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBusinessFlag(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.SearchRequestsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.SearchRequestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.SearchRequestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.SearchRequestsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getPriceMin();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPriceMax();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getRecruiting();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getBudgetDefined();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getUserIconSize();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSortType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMasterCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getBusinessFlag();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.request.SearchRequestsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.SearchRequestsRequest} returns this
 */
proto.request.SearchRequestsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 limit = 2;
 * @return {number}
 */
proto.request.SearchRequestsRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.SearchRequestsRequest} returns this
 */
proto.request.SearchRequestsRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string keyword = 3;
 * @return {string}
 */
proto.request.SearchRequestsRequest.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.SearchRequestsRequest} returns this
 */
proto.request.SearchRequestsRequest.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 price_min = 4;
 * @return {number}
 */
proto.request.SearchRequestsRequest.prototype.getPriceMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.SearchRequestsRequest} returns this
 */
proto.request.SearchRequestsRequest.prototype.setPriceMin = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 price_max = 5;
 * @return {number}
 */
proto.request.SearchRequestsRequest.prototype.getPriceMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.SearchRequestsRequest} returns this
 */
proto.request.SearchRequestsRequest.prototype.setPriceMax = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool recruiting = 7;
 * @return {boolean}
 */
proto.request.SearchRequestsRequest.prototype.getRecruiting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.SearchRequestsRequest} returns this
 */
proto.request.SearchRequestsRequest.prototype.setRecruiting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool budget_defined = 8;
 * @return {boolean}
 */
proto.request.SearchRequestsRequest.prototype.getBudgetDefined = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.SearchRequestsRequest} returns this
 */
proto.request.SearchRequestsRequest.prototype.setBudgetDefined = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string user_icon_size = 9;
 * @return {string}
 */
proto.request.SearchRequestsRequest.prototype.getUserIconSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.SearchRequestsRequest} returns this
 */
proto.request.SearchRequestsRequest.prototype.setUserIconSize = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string sort_type = 10;
 * @return {string}
 */
proto.request.SearchRequestsRequest.prototype.getSortType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.SearchRequestsRequest} returns this
 */
proto.request.SearchRequestsRequest.prototype.setSortType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 master_category_id = 11;
 * @return {number}
 */
proto.request.SearchRequestsRequest.prototype.getMasterCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.SearchRequestsRequest} returns this
 */
proto.request.SearchRequestsRequest.prototype.setMasterCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional bool business_flag = 12;
 * @return {boolean}
 */
proto.request.SearchRequestsRequest.prototype.getBusinessFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.SearchRequestsRequest} returns this
 */
proto.request.SearchRequestsRequest.prototype.setBusinessFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.SearchRequestsReply.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.SearchRequestsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.request.SearchRequestsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.SearchRequestsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.SearchRequestsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    total: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    limit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    requestsList: jspb.Message.toObjectList(msg.getRequestsList(),
    proto.request.Request.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.SearchRequestsReply}
 */
proto.request.SearchRequestsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.SearchRequestsReply;
  return proto.request.SearchRequestsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.SearchRequestsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.SearchRequestsReply}
 */
proto.request.SearchRequestsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 4:
      var value = new proto.request.Request;
      reader.readMessage(value,proto.request.Request.deserializeBinaryFromReader);
      msg.addRequests(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.SearchRequestsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.SearchRequestsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.SearchRequestsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.SearchRequestsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.request.Request.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 total = 1;
 * @return {number}
 */
proto.request.SearchRequestsReply.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.SearchRequestsReply} returns this
 */
proto.request.SearchRequestsReply.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 offset = 2;
 * @return {number}
 */
proto.request.SearchRequestsReply.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.SearchRequestsReply} returns this
 */
proto.request.SearchRequestsReply.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 limit = 3;
 * @return {number}
 */
proto.request.SearchRequestsReply.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.SearchRequestsReply} returns this
 */
proto.request.SearchRequestsReply.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Request requests = 4;
 * @return {!Array<!proto.request.Request>}
 */
proto.request.SearchRequestsReply.prototype.getRequestsList = function() {
  return /** @type{!Array<!proto.request.Request>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.request.Request, 4));
};


/**
 * @param {!Array<!proto.request.Request>} value
 * @return {!proto.request.SearchRequestsReply} returns this
*/
proto.request.SearchRequestsReply.prototype.setRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.request.Request=} opt_value
 * @param {number=} opt_index
 * @return {!proto.request.Request}
 */
proto.request.SearchRequestsReply.prototype.addRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.request.Request, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.SearchRequestsReply} returns this
 */
proto.request.SearchRequestsReply.prototype.clearRequestsList = function() {
  return this.setRequestsList([]);
};


/**
 * optional int32 page = 5;
 * @return {number}
 */
proto.request.SearchRequestsReply.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.SearchRequestsReply} returns this
 */
proto.request.SearchRequestsReply.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.request.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    requestContent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    recruitmentDeadline: jspb.Message.getFieldWithDefault(msg, 6, 0),
    isRecruitmentFinished: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    minBudget: jspb.Message.getFieldWithDefault(msg, 8, 0),
    maxBudget: jspb.Message.getFieldWithDefault(msg, 9, 0),
    budgetConsultation: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    proposalCount: jspb.Message.getFieldWithDefault(msg, 11, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    userName: jspb.Message.getFieldWithDefault(msg, 13, ""),
    requestStatus: jspb.Message.getFieldWithDefault(msg, 14, 0),
    created: jspb.Message.getFieldWithDefault(msg, 15, 0),
    uploaded: jspb.Message.getFieldWithDefault(msg, 16, 0),
    userIconPath: jspb.Message.getFieldWithDefault(msg, 17, ""),
    userGender: jspb.Message.getFieldWithDefault(msg, 18, ""),
    userLastLogin: jspb.Message.getFieldWithDefault(msg, 19, 0),
    parentMasterCategoryId: jspb.Message.getFieldWithDefault(msg, 20, 0),
    childMasterCategoryId: jspb.Message.getFieldWithDefault(msg, 21, 0),
    isBookmarked: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    isProposed: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 24, false),
    openedAt: jspb.Message.getFieldWithDefault(msg, 25, 0),
    resizableProfileIcon: jspb.Message.getFieldWithDefault(msg, 26, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.Request}
 */
proto.request.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.Request;
  return proto.request.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.Request}
 */
proto.request.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestContent(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecruitmentDeadline(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRecruitmentFinished(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMinBudget(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMaxBudget(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBudgetConsultation(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setProposalCount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequestStatus(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUploaded(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserIconPath(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGender(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUserLastLogin(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParentMasterCategoryId(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setChildMasterCategoryId(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBookmarked(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsProposed(value);
      break;
    case 24:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpenedAt(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setResizableProfileIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRequestContent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRecruitmentDeadline();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
  f = message.getIsRecruitmentFinished();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getMinBudget();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getMaxBudget();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getBudgetConsultation();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getProposalCount();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getRequestStatus();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getUploaded();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getUserIconPath();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getUserGender();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getUserLastLogin();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getParentMasterCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getChildMasterCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
  f = message.getIsBookmarked();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getIsProposed();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      24,
      f
    );
  }
  f = message.getOpenedAt();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
  f = message.getResizableProfileIcon();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
};


/**
 * optional int32 request_id = 1;
 * @return {number}
 */
proto.request.Request.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.request.Request.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string request_content = 3;
 * @return {string}
 */
proto.request.Request.prototype.getRequestContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setRequestContent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 recruitment_deadline = 6;
 * @return {number}
 */
proto.request.Request.prototype.getRecruitmentDeadline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setRecruitmentDeadline = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional bool is_recruitment_finished = 7;
 * @return {boolean}
 */
proto.request.Request.prototype.getIsRecruitmentFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setIsRecruitmentFinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional int32 min_budget = 8;
 * @return {number}
 */
proto.request.Request.prototype.getMinBudget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setMinBudget = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 max_budget = 9;
 * @return {number}
 */
proto.request.Request.prototype.getMaxBudget = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setMaxBudget = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool budget_consultation = 10;
 * @return {boolean}
 */
proto.request.Request.prototype.getBudgetConsultation = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setBudgetConsultation = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int32 proposal_count = 11;
 * @return {number}
 */
proto.request.Request.prototype.getProposalCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setProposalCount = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 user_id = 12;
 * @return {number}
 */
proto.request.Request.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string user_name = 13;
 * @return {string}
 */
proto.request.Request.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional int32 request_status = 14;
 * @return {number}
 */
proto.request.Request.prototype.getRequestStatus = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setRequestStatus = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int64 created = 15;
 * @return {number}
 */
proto.request.Request.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 uploaded = 16;
 * @return {number}
 */
proto.request.Request.prototype.getUploaded = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setUploaded = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string user_icon_path = 17;
 * @return {string}
 */
proto.request.Request.prototype.getUserIconPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setUserIconPath = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string user_gender = 18;
 * @return {string}
 */
proto.request.Request.prototype.getUserGender = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setUserGender = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional int64 user_last_login = 19;
 * @return {number}
 */
proto.request.Request.prototype.getUserLastLogin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setUserLastLogin = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int32 parent_master_category_id = 20;
 * @return {number}
 */
proto.request.Request.prototype.getParentMasterCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setParentMasterCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 child_master_category_id = 21;
 * @return {number}
 */
proto.request.Request.prototype.getChildMasterCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setChildMasterCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional bool is_bookmarked = 22;
 * @return {boolean}
 */
proto.request.Request.prototype.getIsBookmarked = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setIsBookmarked = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional bool is_proposed = 23;
 * @return {boolean}
 */
proto.request.Request.prototype.getIsProposed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setIsProposed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional bool is_deleted = 24;
 * @return {boolean}
 */
proto.request.Request.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 24, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 24, value);
};


/**
 * optional int64 opened_at = 25;
 * @return {number}
 */
proto.request.Request.prototype.getOpenedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setOpenedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional string resizable_profile_icon = 26;
 * @return {string}
 */
proto.request.Request.prototype.getResizableProfileIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.Request} returns this
 */
proto.request.Request.prototype.setResizableProfileIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.GetSearchRequestsConditionReply.prototype.toObject = function(opt_includeInstance) {
  return proto.request.GetSearchRequestsConditionReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.GetSearchRequestsConditionReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetSearchRequestsConditionReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    condition: (f = msg.getCondition()) && proto.request.Condition.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.GetSearchRequestsConditionReply}
 */
proto.request.GetSearchRequestsConditionReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.GetSearchRequestsConditionReply;
  return proto.request.GetSearchRequestsConditionReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.GetSearchRequestsConditionReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.GetSearchRequestsConditionReply}
 */
proto.request.GetSearchRequestsConditionReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.request.Condition;
      reader.readMessage(value,proto.request.Condition.deserializeBinaryFromReader);
      msg.setCondition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.GetSearchRequestsConditionReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.GetSearchRequestsConditionReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.GetSearchRequestsConditionReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetSearchRequestsConditionReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCondition();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.request.Condition.serializeBinaryToWriter
    );
  }
};


/**
 * optional Condition condition = 1;
 * @return {?proto.request.Condition}
 */
proto.request.GetSearchRequestsConditionReply.prototype.getCondition = function() {
  return /** @type{?proto.request.Condition} */ (
    jspb.Message.getWrapperField(this, proto.request.Condition, 1));
};


/**
 * @param {?proto.request.Condition|undefined} value
 * @return {!proto.request.GetSearchRequestsConditionReply} returns this
*/
proto.request.GetSearchRequestsConditionReply.prototype.setCondition = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.GetSearchRequestsConditionReply} returns this
 */
proto.request.GetSearchRequestsConditionReply.prototype.clearCondition = function() {
  return this.setCondition(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.GetSearchRequestsConditionReply.prototype.hasCondition = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.Condition.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.Condition.prototype.toObject = function(opt_includeInstance) {
  return proto.request.Condition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.Condition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.Condition.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyword: jspb.Message.getFieldWithDefault(msg, 1, ""),
    priceRange: (f = msg.getPriceRange()) && proto.request.PriceRange.toObject(includeInstance, f),
    extraConditions: (f = msg.getExtraConditions()) && proto.request.ExtraCondition.toObject(includeInstance, f),
    sortType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    userIconSize: jspb.Message.getFieldWithDefault(msg, 6, ""),
    masterCategoriesList: jspb.Message.toObjectList(msg.getMasterCategoriesList(),
    proto.request.MasterCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.Condition}
 */
proto.request.Condition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.Condition;
  return proto.request.Condition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.Condition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.Condition}
 */
proto.request.Condition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setKeyword(value);
      break;
    case 3:
      var value = new proto.request.PriceRange;
      reader.readMessage(value,proto.request.PriceRange.deserializeBinaryFromReader);
      msg.setPriceRange(value);
      break;
    case 4:
      var value = new proto.request.ExtraCondition;
      reader.readMessage(value,proto.request.ExtraCondition.deserializeBinaryFromReader);
      msg.setExtraConditions(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSortType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserIconSize(value);
      break;
    case 7:
      var value = new proto.request.MasterCategory;
      reader.readMessage(value,proto.request.MasterCategory.deserializeBinaryFromReader);
      msg.addMasterCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.Condition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.Condition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.Condition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.Condition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyword();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPriceRange();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.request.PriceRange.serializeBinaryToWriter
    );
  }
  f = message.getExtraConditions();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.request.ExtraCondition.serializeBinaryToWriter
    );
  }
  f = message.getSortType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUserIconSize();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMasterCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.request.MasterCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional string keyword = 1;
 * @return {string}
 */
proto.request.Condition.prototype.getKeyword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.Condition} returns this
 */
proto.request.Condition.prototype.setKeyword = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional PriceRange price_range = 3;
 * @return {?proto.request.PriceRange}
 */
proto.request.Condition.prototype.getPriceRange = function() {
  return /** @type{?proto.request.PriceRange} */ (
    jspb.Message.getWrapperField(this, proto.request.PriceRange, 3));
};


/**
 * @param {?proto.request.PriceRange|undefined} value
 * @return {!proto.request.Condition} returns this
*/
proto.request.Condition.prototype.setPriceRange = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.Condition} returns this
 */
proto.request.Condition.prototype.clearPriceRange = function() {
  return this.setPriceRange(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.Condition.prototype.hasPriceRange = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ExtraCondition extra_conditions = 4;
 * @return {?proto.request.ExtraCondition}
 */
proto.request.Condition.prototype.getExtraConditions = function() {
  return /** @type{?proto.request.ExtraCondition} */ (
    jspb.Message.getWrapperField(this, proto.request.ExtraCondition, 4));
};


/**
 * @param {?proto.request.ExtraCondition|undefined} value
 * @return {!proto.request.Condition} returns this
*/
proto.request.Condition.prototype.setExtraConditions = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.Condition} returns this
 */
proto.request.Condition.prototype.clearExtraConditions = function() {
  return this.setExtraConditions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.Condition.prototype.hasExtraConditions = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string sort_type = 5;
 * @return {string}
 */
proto.request.Condition.prototype.getSortType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.Condition} returns this
 */
proto.request.Condition.prototype.setSortType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string user_icon_size = 6;
 * @return {string}
 */
proto.request.Condition.prototype.getUserIconSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.Condition} returns this
 */
proto.request.Condition.prototype.setUserIconSize = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated MasterCategory master_categories = 7;
 * @return {!Array<!proto.request.MasterCategory>}
 */
proto.request.Condition.prototype.getMasterCategoriesList = function() {
  return /** @type{!Array<!proto.request.MasterCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.request.MasterCategory, 7));
};


/**
 * @param {!Array<!proto.request.MasterCategory>} value
 * @return {!proto.request.Condition} returns this
*/
proto.request.Condition.prototype.setMasterCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.request.MasterCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.request.MasterCategory}
 */
proto.request.Condition.prototype.addMasterCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.request.MasterCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.Condition} returns this
 */
proto.request.Condition.prototype.clearMasterCategoriesList = function() {
  return this.setMasterCategoriesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.MasterCategory.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.MasterCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.request.MasterCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.MasterCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.MasterCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isCurrent: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    subMasterCategoriesList: jspb.Message.toObjectList(msg.getSubMasterCategoriesList(),
    proto.request.SubMasterCategory.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.MasterCategory}
 */
proto.request.MasterCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.MasterCategory;
  return proto.request.MasterCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.MasterCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.MasterCategory}
 */
proto.request.MasterCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCurrent(value);
      break;
    case 4:
      var value = new proto.request.SubMasterCategory;
      reader.readMessage(value,proto.request.SubMasterCategory.deserializeBinaryFromReader);
      msg.addSubMasterCategories(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.MasterCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.MasterCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.MasterCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.MasterCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsCurrent();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSubMasterCategoriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.request.SubMasterCategory.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.request.MasterCategory.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.MasterCategory} returns this
 */
proto.request.MasterCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.request.MasterCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.MasterCategory} returns this
 */
proto.request.MasterCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_current = 3;
 * @return {boolean}
 */
proto.request.MasterCategory.prototype.getIsCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.MasterCategory} returns this
 */
proto.request.MasterCategory.prototype.setIsCurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * repeated SubMasterCategory sub_master_categories = 4;
 * @return {!Array<!proto.request.SubMasterCategory>}
 */
proto.request.MasterCategory.prototype.getSubMasterCategoriesList = function() {
  return /** @type{!Array<!proto.request.SubMasterCategory>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.request.SubMasterCategory, 4));
};


/**
 * @param {!Array<!proto.request.SubMasterCategory>} value
 * @return {!proto.request.MasterCategory} returns this
*/
proto.request.MasterCategory.prototype.setSubMasterCategoriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.request.SubMasterCategory=} opt_value
 * @param {number=} opt_index
 * @return {!proto.request.SubMasterCategory}
 */
proto.request.MasterCategory.prototype.addSubMasterCategories = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.request.SubMasterCategory, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.MasterCategory} returns this
 */
proto.request.MasterCategory.prototype.clearSubMasterCategoriesList = function() {
  return this.setSubMasterCategoriesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.SubMasterCategory.prototype.toObject = function(opt_includeInstance) {
  return proto.request.SubMasterCategory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.SubMasterCategory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.SubMasterCategory.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isCurrent: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.SubMasterCategory}
 */
proto.request.SubMasterCategory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.SubMasterCategory;
  return proto.request.SubMasterCategory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.SubMasterCategory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.SubMasterCategory}
 */
proto.request.SubMasterCategory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.SubMasterCategory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.SubMasterCategory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.SubMasterCategory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.SubMasterCategory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsCurrent();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.request.SubMasterCategory.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.SubMasterCategory} returns this
 */
proto.request.SubMasterCategory.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.request.SubMasterCategory.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.SubMasterCategory} returns this
 */
proto.request.SubMasterCategory.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_current = 3;
 * @return {boolean}
 */
proto.request.SubMasterCategory.prototype.getIsCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.SubMasterCategory} returns this
 */
proto.request.SubMasterCategory.prototype.setIsCurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.PriceRange.prototype.toObject = function(opt_includeInstance) {
  return proto.request.PriceRange.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.PriceRange} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.PriceRange.toObject = function(includeInstance, msg) {
  var f, obj = {
    priceMin: jspb.Message.getFieldWithDefault(msg, 1, 0),
    priceMax: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.PriceRange}
 */
proto.request.PriceRange.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.PriceRange;
  return proto.request.PriceRange.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.PriceRange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.PriceRange}
 */
proto.request.PriceRange.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceMin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.PriceRange.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.PriceRange.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.PriceRange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.PriceRange.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPriceMin();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPriceMax();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 price_min = 1;
 * @return {number}
 */
proto.request.PriceRange.prototype.getPriceMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.PriceRange} returns this
 */
proto.request.PriceRange.prototype.setPriceMin = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 price_max = 2;
 * @return {number}
 */
proto.request.PriceRange.prototype.getPriceMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.PriceRange} returns this
 */
proto.request.PriceRange.prototype.setPriceMax = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.ExtraCondition.prototype.toObject = function(opt_includeInstance) {
  return proto.request.ExtraCondition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.ExtraCondition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ExtraCondition.toObject = function(includeInstance, msg) {
  var f, obj = {
    budgetDefined: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    recruiting: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.ExtraCondition}
 */
proto.request.ExtraCondition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.ExtraCondition;
  return proto.request.ExtraCondition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.ExtraCondition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.ExtraCondition}
 */
proto.request.ExtraCondition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBudgetDefined(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRecruiting(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.ExtraCondition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.ExtraCondition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.ExtraCondition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ExtraCondition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBudgetDefined();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getRecruiting();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bool budget_defined = 1;
 * @return {boolean}
 */
proto.request.ExtraCondition.prototype.getBudgetDefined = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.ExtraCondition} returns this
 */
proto.request.ExtraCondition.prototype.setBudgetDefined = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional bool recruiting = 2;
 * @return {boolean}
 */
proto.request.ExtraCondition.prototype.getRecruiting = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.ExtraCondition} returns this
 */
proto.request.ExtraCondition.prototype.setRecruiting = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.GetQuestionsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.GetQuestionsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.GetQuestionsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetQuestionsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.GetQuestionsRequest}
 */
proto.request.GetQuestionsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.GetQuestionsRequest;
  return proto.request.GetQuestionsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.GetQuestionsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.GetQuestionsRequest}
 */
proto.request.GetQuestionsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.GetQuestionsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.GetQuestionsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.GetQuestionsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetQuestionsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 request_id = 1;
 * @return {number}
 */
proto.request.GetQuestionsRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.GetQuestionsRequest} returns this
 */
proto.request.GetQuestionsRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.GetQuestionsReply.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.GetQuestionsReply.prototype.toObject = function(opt_includeInstance) {
  return proto.request.GetQuestionsReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.GetQuestionsReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetQuestionsReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionsList: jspb.Message.toObjectList(msg.getQuestionsList(),
    proto.request.Question.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.GetQuestionsReply}
 */
proto.request.GetQuestionsReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.GetQuestionsReply;
  return proto.request.GetQuestionsReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.GetQuestionsReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.GetQuestionsReply}
 */
proto.request.GetQuestionsReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.request.Question;
      reader.readMessage(value,proto.request.Question.deserializeBinaryFromReader);
      msg.addQuestions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.GetQuestionsReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.GetQuestionsReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.GetQuestionsReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetQuestionsReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.request.Question.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Question questions = 1;
 * @return {!Array<!proto.request.Question>}
 */
proto.request.GetQuestionsReply.prototype.getQuestionsList = function() {
  return /** @type{!Array<!proto.request.Question>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.request.Question, 1));
};


/**
 * @param {!Array<!proto.request.Question>} value
 * @return {!proto.request.GetQuestionsReply} returns this
*/
proto.request.GetQuestionsReply.prototype.setQuestionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.request.Question=} opt_value
 * @param {number=} opt_index
 * @return {!proto.request.Question}
 */
proto.request.GetQuestionsReply.prototype.addQuestions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.request.Question, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.GetQuestionsReply} returns this
 */
proto.request.GetQuestionsReply.prototype.clearQuestionsList = function() {
  return this.setQuestionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.User.prototype.toObject = function(opt_includeInstance) {
  return proto.request.User.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.User} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.User.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    icon: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.User}
 */
proto.request.User.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.User;
  return proto.request.User.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.User} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.User}
 */
proto.request.User.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setIcon(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.User.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.User.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.User} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.User.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIcon();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.request.User.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.User} returns this
 */
proto.request.User.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.request.User.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.User} returns this
 */
proto.request.User.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string icon = 3;
 * @return {string}
 */
proto.request.User.prototype.getIcon = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.User} returns this
 */
proto.request.User.prototype.setIcon = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.Answer.prototype.toObject = function(opt_includeInstance) {
  return proto.request.Answer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.Answer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.Answer.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    user: (f = msg.getUser()) && proto.request.User.toObject(includeInstance, f),
    created: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.Answer}
 */
proto.request.Answer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.Answer;
  return proto.request.Answer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.Answer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.Answer}
 */
proto.request.Answer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = new proto.request.User;
      reader.readMessage(value,proto.request.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setCreated(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.Answer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.Answer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.Answer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.Answer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.request.User.serializeBinaryToWriter
    );
  }
  f = message.getCreated();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.request.Answer.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.Answer} returns this
 */
proto.request.Answer.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional User user = 2;
 * @return {?proto.request.User}
 */
proto.request.Answer.prototype.getUser = function() {
  return /** @type{?proto.request.User} */ (
    jspb.Message.getWrapperField(this, proto.request.User, 2));
};


/**
 * @param {?proto.request.User|undefined} value
 * @return {!proto.request.Answer} returns this
*/
proto.request.Answer.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.Answer} returns this
 */
proto.request.Answer.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.Answer.prototype.hasUser = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int64 created = 3;
 * @return {number}
 */
proto.request.Answer.prototype.getCreated = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Answer} returns this
 */
proto.request.Answer.prototype.setCreated = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.Question.prototype.toObject = function(opt_includeInstance) {
  return proto.request.Question.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.Question} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.Question.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, ""),
    user: (f = msg.getUser()) && proto.request.User.toObject(includeInstance, f),
    answer: (f = msg.getAnswer()) && proto.request.Answer.toObject(includeInstance, f),
    modified: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.Question}
 */
proto.request.Question.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.Question;
  return proto.request.Question.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.Question} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.Question}
 */
proto.request.Question.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 3:
      var value = new proto.request.User;
      reader.readMessage(value,proto.request.User.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 4:
      var value = new proto.request.Answer;
      reader.readMessage(value,proto.request.Answer.deserializeBinaryFromReader);
      msg.setAnswer(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setModified(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.Question.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.Question.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.Question} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.Question.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.request.User.serializeBinaryToWriter
    );
  }
  f = message.getAnswer();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.request.Answer.serializeBinaryToWriter
    );
  }
  f = message.getModified();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.request.Question.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Question} returns this
 */
proto.request.Question.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.request.Question.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.Question} returns this
 */
proto.request.Question.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional User user = 3;
 * @return {?proto.request.User}
 */
proto.request.Question.prototype.getUser = function() {
  return /** @type{?proto.request.User} */ (
    jspb.Message.getWrapperField(this, proto.request.User, 3));
};


/**
 * @param {?proto.request.User|undefined} value
 * @return {!proto.request.Question} returns this
*/
proto.request.Question.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.Question} returns this
 */
proto.request.Question.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.Question.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Answer answer = 4;
 * @return {?proto.request.Answer}
 */
proto.request.Question.prototype.getAnswer = function() {
  return /** @type{?proto.request.Answer} */ (
    jspb.Message.getWrapperField(this, proto.request.Answer, 4));
};


/**
 * @param {?proto.request.Answer|undefined} value
 * @return {!proto.request.Question} returns this
*/
proto.request.Question.prototype.setAnswer = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.Question} returns this
 */
proto.request.Question.prototype.clearAnswer = function() {
  return this.setAnswer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.Question.prototype.hasAnswer = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int64 modified = 5;
 * @return {number}
 */
proto.request.Question.prototype.getModified = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.Question} returns this
 */
proto.request.Question.prototype.setModified = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.AddQuestionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.AddQuestionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.AddQuestionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddQuestionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.AddQuestionRequest}
 */
proto.request.AddQuestionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.AddQuestionRequest;
  return proto.request.AddQuestionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.AddQuestionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.AddQuestionRequest}
 */
proto.request.AddQuestionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.AddQuestionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.AddQuestionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.AddQuestionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddQuestionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 request_id = 1;
 * @return {number}
 */
proto.request.AddQuestionRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddQuestionRequest} returns this
 */
proto.request.AddQuestionRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.request.AddQuestionRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddQuestionRequest} returns this
 */
proto.request.AddQuestionRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.AddQuestionReply.prototype.toObject = function(opt_includeInstance) {
  return proto.request.AddQuestionReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.AddQuestionReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddQuestionReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.AddQuestionReply}
 */
proto.request.AddQuestionReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.AddQuestionReply;
  return proto.request.AddQuestionReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.AddQuestionReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.AddQuestionReply}
 */
proto.request.AddQuestionReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.AddQuestionReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.AddQuestionReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.AddQuestionReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddQuestionReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.AddAnswerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.AddAnswerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.AddAnswerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddAnswerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    questionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    message: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.AddAnswerRequest}
 */
proto.request.AddAnswerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.AddAnswerRequest;
  return proto.request.AddAnswerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.AddAnswerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.AddAnswerRequest}
 */
proto.request.AddAnswerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuestionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.AddAnswerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.AddAnswerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.AddAnswerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddAnswerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuestionId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 question_id = 1;
 * @return {number}
 */
proto.request.AddAnswerRequest.prototype.getQuestionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddAnswerRequest} returns this
 */
proto.request.AddAnswerRequest.prototype.setQuestionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string message = 2;
 * @return {string}
 */
proto.request.AddAnswerRequest.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddAnswerRequest} returns this
 */
proto.request.AddAnswerRequest.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.AddAnswerReply.prototype.toObject = function(opt_includeInstance) {
  return proto.request.AddAnswerReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.AddAnswerReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddAnswerReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.AddAnswerReply}
 */
proto.request.AddAnswerReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.AddAnswerReply;
  return proto.request.AddAnswerReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.AddAnswerReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.AddAnswerReply}
 */
proto.request.AddAnswerReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.AddAnswerReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.AddAnswerReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.AddAnswerReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddAnswerReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.AddRequestInformationRequest.repeatedFields_ = [11,12,16,17,28,29,30,34,35,41,42,45,53,63,64,65,67,68,69,70,72,73];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.AddRequestInformationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.AddRequestInformationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.AddRequestInformationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddRequestInformationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    content: jspb.Message.getFieldWithDefault(msg, 2, ""),
    priceMin: jspb.Message.getFieldWithDefault(msg, 4, 0),
    priceMax: jspb.Message.getFieldWithDefault(msg, 5, 0),
    withholdingTaxFlag: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    expireDate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    deliveryDateYear: jspb.Message.getFieldWithDefault(msg, 8, 0),
    deliveryDateMonth: jspb.Message.getFieldWithDefault(msg, 9, 0),
    deliveryDateDay: jspb.Message.getFieldWithDefault(msg, 10, 0),
    attachmentFilesList: jspb.Message.toObjectList(msg.getAttachmentFilesList(),
    proto.request.AttachmentFiles.toObject, includeInstance),
    genreList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    genreOther: jspb.Message.getFieldWithDefault(msg, 13, ""),
    referenceUrl: jspb.Message.getFieldWithDefault(msg, 14, ""),
    requestSkill: jspb.Message.getFieldWithDefault(msg, 15, ""),
    specialNoteList: (f = jspb.Message.getRepeatedField(msg, 16)) == null ? undefined : f,
    coverageList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    workCount: jspb.Message.getFieldWithDefault(msg, 18, 0),
    workCountOther: jspb.Message.getFieldWithDefault(msg, 19, ""),
    size: jspb.Message.getFieldWithDefault(msg, 20, 0),
    sizeOther: jspb.Message.getFieldWithDefault(msg, 21, ""),
    desiredColorImage: jspb.Message.getFieldWithDefault(msg, 22, 0),
    desiredColorImageOther: jspb.Message.getFieldWithDefault(msg, 23, ""),
    desiredDirectionDirectivity: jspb.Message.getFieldWithDefault(msg, 24, 0),
    desiredDirectionColor: jspb.Message.getFieldWithDefault(msg, 25, 0),
    desiredDirectionDignity: jspb.Message.getFieldWithDefault(msg, 26, 0),
    desiredDirectionGender: jspb.Message.getFieldWithDefault(msg, 27, 0),
    fileTypeList: (f = jspb.Message.getRepeatedField(msg, 28)) == null ? undefined : f,
    purposeTypeList: (f = jspb.Message.getRepeatedField(msg, 29)) == null ? undefined : f,
    targetAgeList: (f = jspb.Message.getRepeatedField(msg, 30)) == null ? undefined : f,
    targetAgeOther: jspb.Message.getFieldWithDefault(msg, 31, ""),
    targetSex: jspb.Message.getFieldWithDefault(msg, 32, 0),
    targetSexOther: jspb.Message.getFieldWithDefault(msg, 33, ""),
    preparedSomethingList: (f = jspb.Message.getRepeatedField(msg, 34)) == null ? undefined : f,
    developLanguageList: (f = jspb.Message.getRepeatedField(msg, 35)) == null ? undefined : f,
    wordCount: jspb.Message.getFieldWithDefault(msg, 36, 0),
    businessType: jspb.Message.getFieldWithDefault(msg, 37, 0),
    characterCount: jspb.Message.getFieldWithDefault(msg, 38, 0),
    articlePrice: jspb.Message.getFieldWithDefault(msg, 39, 0),
    articleCount: jspb.Message.getFieldWithDefault(msg, 40, 0),
    pageTypeList: (f = jspb.Message.getRepeatedField(msg, 41)) == null ? undefined : f,
    preparedMaterialList: (f = jspb.Message.getRepeatedField(msg, 42)) == null ? undefined : f,
    targetJob: jspb.Message.getFieldWithDefault(msg, 43, 0),
    logoName: jspb.Message.getFieldWithDefault(msg, 44, ""),
    logoImageList: (f = jspb.Message.getRepeatedField(msg, 45)) == null ? undefined : f,
    registerTrademark: jspb.Message.getFieldWithDefault(msg, 46, 0),
    masterCategoryId: jspb.Message.getFieldWithDefault(msg, 47, 0),
    purposeTypeOther: jspb.Message.getFieldWithDefault(msg, 48, ""),
    coverageOther: jspb.Message.getFieldWithDefault(msg, 49, ""),
    developLanguageOther: jspb.Message.getFieldWithDefault(msg, 50, ""),
    preparedMaterialOther: jspb.Message.getFieldWithDefault(msg, 51, ""),
    isDraft: jspb.Message.getBooleanFieldWithDefault(msg, 52, false),
    requestSkillsList: jspb.Message.toObjectList(msg.getRequestSkillsList(),
    proto.request.RequestSkill.toObject, includeInstance),
    wordCountCheckOther: jspb.Message.getFieldWithDefault(msg, 54, ""),
    articlePriceCheckOther: jspb.Message.getFieldWithDefault(msg, 55, ""),
    articleCountCheckOther: jspb.Message.getFieldWithDefault(msg, 56, ""),
    timeCount: jspb.Message.getFieldWithDefault(msg, 57, 0),
    timeCountCheckOther: jspb.Message.getFieldWithDefault(msg, 58, ""),
    assignCopyright: jspb.Message.getFieldWithDefault(msg, 59, 0),
    secondaryUse: jspb.Message.getFieldWithDefault(msg, 60, 0),
    style: jspb.Message.getFieldWithDefault(msg, 61, 0),
    language: jspb.Message.getFieldWithDefault(msg, 62, 0),
    serviceTitleList: (f = jspb.Message.getRepeatedField(msg, 63)) == null ? undefined : f,
    mediaList: (f = jspb.Message.getRepeatedField(msg, 64)) == null ? undefined : f,
    voiceTypeList: (f = jspb.Message.getRepeatedField(msg, 65)) == null ? undefined : f,
    isBudgetSelected: jspb.Message.getBooleanFieldWithDefault(msg, 66, false),
    wordCountCheckList: (f = jspb.Message.getRepeatedField(msg, 67)) == null ? undefined : f,
    articlePriceCheckList: (f = jspb.Message.getRepeatedField(msg, 68)) == null ? undefined : f,
    articleCountCheckList: (f = jspb.Message.getRepeatedField(msg, 69)) == null ? undefined : f,
    timeCountCheckList: (f = jspb.Message.getRepeatedField(msg, 70)) == null ? undefined : f,
    freeWordText: jspb.Message.getFieldWithDefault(msg, 71, ""),
    targetSexesList: (f = jspb.Message.getRepeatedField(msg, 72)) == null ? undefined : f,
    targetJobsList: (f = jspb.Message.getRepeatedField(msg, 73)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.AddRequestInformationRequest}
 */
proto.request.AddRequestInformationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.AddRequestInformationRequest;
  return proto.request.AddRequestInformationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.AddRequestInformationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.AddRequestInformationRequest}
 */
proto.request.AddRequestInformationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceMin(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPriceMax(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setWithholdingTaxFlag(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExpireDate(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeliveryDateYear(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeliveryDateMonth(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDeliveryDateDay(value);
      break;
    case 11:
      var value = new proto.request.AttachmentFiles;
      reader.readMessage(value,proto.request.AttachmentFiles.deserializeBinaryFromReader);
      msg.addAttachmentFiles(value);
      break;
    case 12:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setGenreList(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setGenreOther(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceUrl(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestSkill(value);
      break;
    case 16:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setSpecialNoteList(value);
      break;
    case 17:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setCoverageList(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWorkCount(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setWorkCountOther(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setSizeOther(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDesiredColorImage(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesiredColorImageOther(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDesiredDirectionDirectivity(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDesiredDirectionColor(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDesiredDirectionDignity(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDesiredDirectionGender(value);
      break;
    case 28:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setFileTypeList(value);
      break;
    case 29:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setPurposeTypeList(value);
      break;
    case 30:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setTargetAgeList(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetAgeOther(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetSex(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetSexOther(value);
      break;
    case 34:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setPreparedSomethingList(value);
      break;
    case 35:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setDevelopLanguageList(value);
      break;
    case 36:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setWordCount(value);
      break;
    case 37:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBusinessType(value);
      break;
    case 38:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCharacterCount(value);
      break;
    case 39:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArticlePrice(value);
      break;
    case 40:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setArticleCount(value);
      break;
    case 41:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setPageTypeList(value);
      break;
    case 42:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setPreparedMaterialList(value);
      break;
    case 43:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTargetJob(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogoName(value);
      break;
    case 45:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setLogoImageList(value);
      break;
    case 46:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRegisterTrademark(value);
      break;
    case 47:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMasterCategoryId(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurposeTypeOther(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.setCoverageOther(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setDevelopLanguageOther(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreparedMaterialOther(value);
      break;
    case 52:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDraft(value);
      break;
    case 53:
      var value = new proto.request.RequestSkill;
      reader.readMessage(value,proto.request.RequestSkill.deserializeBinaryFromReader);
      msg.addRequestSkills(value);
      break;
    case 54:
      var value = /** @type {string} */ (reader.readString());
      msg.setWordCountCheckOther(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setArticlePriceCheckOther(value);
      break;
    case 56:
      var value = /** @type {string} */ (reader.readString());
      msg.setArticleCountCheckOther(value);
      break;
    case 57:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTimeCount(value);
      break;
    case 58:
      var value = /** @type {string} */ (reader.readString());
      msg.setTimeCountCheckOther(value);
      break;
    case 59:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAssignCopyright(value);
      break;
    case 60:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSecondaryUse(value);
      break;
    case 61:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setStyle(value);
      break;
    case 62:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLanguage(value);
      break;
    case 63:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setServiceTitleList(value);
      break;
    case 64:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setMediaList(value);
      break;
    case 65:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setVoiceTypeList(value);
      break;
    case 66:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBudgetSelected(value);
      break;
    case 67:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setWordCountCheckList(value);
      break;
    case 68:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setArticlePriceCheckList(value);
      break;
    case 69:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setArticleCountCheckList(value);
      break;
    case 70:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setTimeCountCheckList(value);
      break;
    case 71:
      var value = /** @type {string} */ (reader.readString());
      msg.setFreeWordText(value);
      break;
    case 72:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setTargetSexesList(value);
      break;
    case 73:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setTargetJobsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.AddRequestInformationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.AddRequestInformationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.AddRequestInformationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddRequestInformationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPriceMin();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getPriceMax();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getWithholdingTaxFlag();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getExpireDate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDeliveryDateYear();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getDeliveryDateMonth();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getDeliveryDateDay();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getAttachmentFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      11,
      f,
      proto.request.AttachmentFiles.serializeBinaryToWriter
    );
  }
  f = message.getGenreList();
  if (f.length > 0) {
    writer.writePackedInt32(
      12,
      f
    );
  }
  f = message.getGenreOther();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getReferenceUrl();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getRequestSkill();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getSpecialNoteList();
  if (f.length > 0) {
    writer.writePackedInt32(
      16,
      f
    );
  }
  f = message.getCoverageList();
  if (f.length > 0) {
    writer.writePackedInt32(
      17,
      f
    );
  }
  f = message.getWorkCount();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getWorkCountOther();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getSizeOther();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getDesiredColorImage();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getDesiredColorImageOther();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getDesiredDirectionDirectivity();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getDesiredDirectionColor();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getDesiredDirectionDignity();
  if (f !== 0) {
    writer.writeInt32(
      26,
      f
    );
  }
  f = message.getDesiredDirectionGender();
  if (f !== 0) {
    writer.writeInt32(
      27,
      f
    );
  }
  f = message.getFileTypeList();
  if (f.length > 0) {
    writer.writePackedInt32(
      28,
      f
    );
  }
  f = message.getPurposeTypeList();
  if (f.length > 0) {
    writer.writePackedInt32(
      29,
      f
    );
  }
  f = message.getTargetAgeList();
  if (f.length > 0) {
    writer.writePackedInt32(
      30,
      f
    );
  }
  f = message.getTargetAgeOther();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getTargetSex();
  if (f !== 0) {
    writer.writeInt32(
      32,
      f
    );
  }
  f = message.getTargetSexOther();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getPreparedSomethingList();
  if (f.length > 0) {
    writer.writePackedInt32(
      34,
      f
    );
  }
  f = message.getDevelopLanguageList();
  if (f.length > 0) {
    writer.writePackedInt32(
      35,
      f
    );
  }
  f = message.getWordCount();
  if (f !== 0) {
    writer.writeInt32(
      36,
      f
    );
  }
  f = message.getBusinessType();
  if (f !== 0) {
    writer.writeInt32(
      37,
      f
    );
  }
  f = message.getCharacterCount();
  if (f !== 0) {
    writer.writeInt32(
      38,
      f
    );
  }
  f = message.getArticlePrice();
  if (f !== 0) {
    writer.writeInt32(
      39,
      f
    );
  }
  f = message.getArticleCount();
  if (f !== 0) {
    writer.writeInt32(
      40,
      f
    );
  }
  f = message.getPageTypeList();
  if (f.length > 0) {
    writer.writePackedInt32(
      41,
      f
    );
  }
  f = message.getPreparedMaterialList();
  if (f.length > 0) {
    writer.writePackedInt32(
      42,
      f
    );
  }
  f = message.getTargetJob();
  if (f !== 0) {
    writer.writeInt32(
      43,
      f
    );
  }
  f = message.getLogoName();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getLogoImageList();
  if (f.length > 0) {
    writer.writePackedInt32(
      45,
      f
    );
  }
  f = message.getRegisterTrademark();
  if (f !== 0) {
    writer.writeInt32(
      46,
      f
    );
  }
  f = message.getMasterCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      47,
      f
    );
  }
  f = message.getPurposeTypeOther();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getCoverageOther();
  if (f.length > 0) {
    writer.writeString(
      49,
      f
    );
  }
  f = message.getDevelopLanguageOther();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getPreparedMaterialOther();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getIsDraft();
  if (f) {
    writer.writeBool(
      52,
      f
    );
  }
  f = message.getRequestSkillsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      53,
      f,
      proto.request.RequestSkill.serializeBinaryToWriter
    );
  }
  f = message.getWordCountCheckOther();
  if (f.length > 0) {
    writer.writeString(
      54,
      f
    );
  }
  f = message.getArticlePriceCheckOther();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getArticleCountCheckOther();
  if (f.length > 0) {
    writer.writeString(
      56,
      f
    );
  }
  f = message.getTimeCount();
  if (f !== 0) {
    writer.writeInt32(
      57,
      f
    );
  }
  f = message.getTimeCountCheckOther();
  if (f.length > 0) {
    writer.writeString(
      58,
      f
    );
  }
  f = message.getAssignCopyright();
  if (f !== 0) {
    writer.writeInt32(
      59,
      f
    );
  }
  f = message.getSecondaryUse();
  if (f !== 0) {
    writer.writeInt32(
      60,
      f
    );
  }
  f = message.getStyle();
  if (f !== 0) {
    writer.writeInt32(
      61,
      f
    );
  }
  f = message.getLanguage();
  if (f !== 0) {
    writer.writeInt32(
      62,
      f
    );
  }
  f = message.getServiceTitleList();
  if (f.length > 0) {
    writer.writePackedInt32(
      63,
      f
    );
  }
  f = message.getMediaList();
  if (f.length > 0) {
    writer.writePackedInt32(
      64,
      f
    );
  }
  f = message.getVoiceTypeList();
  if (f.length > 0) {
    writer.writePackedInt32(
      65,
      f
    );
  }
  f = message.getIsBudgetSelected();
  if (f) {
    writer.writeBool(
      66,
      f
    );
  }
  f = message.getWordCountCheckList();
  if (f.length > 0) {
    writer.writePackedInt32(
      67,
      f
    );
  }
  f = message.getArticlePriceCheckList();
  if (f.length > 0) {
    writer.writePackedInt32(
      68,
      f
    );
  }
  f = message.getArticleCountCheckList();
  if (f.length > 0) {
    writer.writePackedInt32(
      69,
      f
    );
  }
  f = message.getTimeCountCheckList();
  if (f.length > 0) {
    writer.writePackedInt32(
      70,
      f
    );
  }
  f = message.getFreeWordText();
  if (f.length > 0) {
    writer.writeString(
      71,
      f
    );
  }
  f = message.getTargetSexesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      72,
      f
    );
  }
  f = message.getTargetJobsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      73,
      f
    );
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string content = 2;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 price_min = 4;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getPriceMin = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setPriceMin = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 price_max = 5;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getPriceMax = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setPriceMax = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool withholding_tax_flag = 6;
 * @return {boolean}
 */
proto.request.AddRequestInformationRequest.prototype.getWithholdingTaxFlag = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setWithholdingTaxFlag = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string expire_date = 7;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getExpireDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setExpireDate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 delivery_date_year = 8;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getDeliveryDateYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setDeliveryDateYear = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 delivery_date_month = 9;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getDeliveryDateMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setDeliveryDateMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 delivery_date_day = 10;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getDeliveryDateDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setDeliveryDateDay = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * repeated AttachmentFiles attachment_files = 11;
 * @return {!Array<!proto.request.AttachmentFiles>}
 */
proto.request.AddRequestInformationRequest.prototype.getAttachmentFilesList = function() {
  return /** @type{!Array<!proto.request.AttachmentFiles>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.request.AttachmentFiles, 11));
};


/**
 * @param {!Array<!proto.request.AttachmentFiles>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
*/
proto.request.AddRequestInformationRequest.prototype.setAttachmentFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 11, value);
};


/**
 * @param {!proto.request.AttachmentFiles=} opt_value
 * @param {number=} opt_index
 * @return {!proto.request.AttachmentFiles}
 */
proto.request.AddRequestInformationRequest.prototype.addAttachmentFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 11, opt_value, proto.request.AttachmentFiles, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearAttachmentFilesList = function() {
  return this.setAttachmentFilesList([]);
};


/**
 * repeated int32 genre = 12;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getGenreList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setGenreList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addGenre = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearGenreList = function() {
  return this.setGenreList([]);
};


/**
 * optional string genre_other = 13;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getGenreOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setGenreOther = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string reference_url = 14;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getReferenceUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setReferenceUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string request_skill = 15;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getRequestSkill = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setRequestSkill = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * repeated int32 special_note = 16;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getSpecialNoteList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 16));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setSpecialNoteList = function(value) {
  return jspb.Message.setField(this, 16, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addSpecialNote = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 16, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearSpecialNoteList = function() {
  return this.setSpecialNoteList([]);
};


/**
 * repeated int32 coverage = 17;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getCoverageList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setCoverageList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addCoverage = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearCoverageList = function() {
  return this.setCoverageList([]);
};


/**
 * optional int32 work_count = 18;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getWorkCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setWorkCount = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string work_count_other = 19;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getWorkCountOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setWorkCountOther = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 size = 20;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string size_other = 21;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getSizeOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setSizeOther = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional int32 desired_color_image = 22;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getDesiredColorImage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setDesiredColorImage = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional string desired_color_image_other = 23;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getDesiredColorImageOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setDesiredColorImageOther = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional int32 desired_direction_directivity = 24;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getDesiredDirectionDirectivity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setDesiredDirectionDirectivity = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 desired_direction_color = 25;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getDesiredDirectionColor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setDesiredDirectionColor = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional int32 desired_direction_dignity = 26;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getDesiredDirectionDignity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setDesiredDirectionDignity = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional int32 desired_direction_gender = 27;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getDesiredDirectionGender = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setDesiredDirectionGender = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * repeated int32 file_type = 28;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getFileTypeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 28));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setFileTypeList = function(value) {
  return jspb.Message.setField(this, 28, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addFileType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 28, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearFileTypeList = function() {
  return this.setFileTypeList([]);
};


/**
 * repeated int32 purpose_type = 29;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getPurposeTypeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 29));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setPurposeTypeList = function(value) {
  return jspb.Message.setField(this, 29, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addPurposeType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 29, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearPurposeTypeList = function() {
  return this.setPurposeTypeList([]);
};


/**
 * repeated int32 target_age = 30;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getTargetAgeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 30));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setTargetAgeList = function(value) {
  return jspb.Message.setField(this, 30, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addTargetAge = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 30, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearTargetAgeList = function() {
  return this.setTargetAgeList([]);
};


/**
 * optional string target_age_other = 31;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getTargetAgeOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setTargetAgeOther = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional int32 target_sex = 32;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getTargetSex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setTargetSex = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional string target_sex_other = 33;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getTargetSexOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setTargetSexOther = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * repeated int32 prepared_something = 34;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getPreparedSomethingList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 34));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setPreparedSomethingList = function(value) {
  return jspb.Message.setField(this, 34, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addPreparedSomething = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 34, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearPreparedSomethingList = function() {
  return this.setPreparedSomethingList([]);
};


/**
 * repeated int32 develop_language = 35;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getDevelopLanguageList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 35));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setDevelopLanguageList = function(value) {
  return jspb.Message.setField(this, 35, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addDevelopLanguage = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 35, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearDevelopLanguageList = function() {
  return this.setDevelopLanguageList([]);
};


/**
 * optional int32 word_count = 36;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getWordCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 36, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setWordCount = function(value) {
  return jspb.Message.setProto3IntField(this, 36, value);
};


/**
 * optional int32 business_type = 37;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getBusinessType = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 37, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setBusinessType = function(value) {
  return jspb.Message.setProto3IntField(this, 37, value);
};


/**
 * optional int32 character_count = 38;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getCharacterCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 38, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setCharacterCount = function(value) {
  return jspb.Message.setProto3IntField(this, 38, value);
};


/**
 * optional int32 article_price = 39;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getArticlePrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 39, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setArticlePrice = function(value) {
  return jspb.Message.setProto3IntField(this, 39, value);
};


/**
 * optional int32 article_count = 40;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getArticleCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 40, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setArticleCount = function(value) {
  return jspb.Message.setProto3IntField(this, 40, value);
};


/**
 * repeated int32 page_type = 41;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getPageTypeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 41));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setPageTypeList = function(value) {
  return jspb.Message.setField(this, 41, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addPageType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 41, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearPageTypeList = function() {
  return this.setPageTypeList([]);
};


/**
 * repeated int32 prepared_material = 42;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getPreparedMaterialList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 42));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setPreparedMaterialList = function(value) {
  return jspb.Message.setField(this, 42, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addPreparedMaterial = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 42, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearPreparedMaterialList = function() {
  return this.setPreparedMaterialList([]);
};


/**
 * optional int32 target_job = 43;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getTargetJob = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 43, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setTargetJob = function(value) {
  return jspb.Message.setProto3IntField(this, 43, value);
};


/**
 * optional string logo_name = 44;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getLogoName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setLogoName = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * repeated int32 logo_image = 45;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getLogoImageList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 45));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setLogoImageList = function(value) {
  return jspb.Message.setField(this, 45, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addLogoImage = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 45, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearLogoImageList = function() {
  return this.setLogoImageList([]);
};


/**
 * optional int32 register_trademark = 46;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getRegisterTrademark = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 46, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setRegisterTrademark = function(value) {
  return jspb.Message.setProto3IntField(this, 46, value);
};


/**
 * optional int32 master_category_id = 47;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getMasterCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 47, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setMasterCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 47, value);
};


/**
 * optional string purpose_type_other = 48;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getPurposeTypeOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setPurposeTypeOther = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional string coverage_other = 49;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getCoverageOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 49, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setCoverageOther = function(value) {
  return jspb.Message.setProto3StringField(this, 49, value);
};


/**
 * optional string develop_language_other = 50;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getDevelopLanguageOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setDevelopLanguageOther = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string prepared_material_other = 51;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getPreparedMaterialOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setPreparedMaterialOther = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional bool is_draft = 52;
 * @return {boolean}
 */
proto.request.AddRequestInformationRequest.prototype.getIsDraft = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 52, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setIsDraft = function(value) {
  return jspb.Message.setProto3BooleanField(this, 52, value);
};


/**
 * repeated RequestSkill request_skills = 53;
 * @return {!Array<!proto.request.RequestSkill>}
 */
proto.request.AddRequestInformationRequest.prototype.getRequestSkillsList = function() {
  return /** @type{!Array<!proto.request.RequestSkill>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.request.RequestSkill, 53));
};


/**
 * @param {!Array<!proto.request.RequestSkill>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
*/
proto.request.AddRequestInformationRequest.prototype.setRequestSkillsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 53, value);
};


/**
 * @param {!proto.request.RequestSkill=} opt_value
 * @param {number=} opt_index
 * @return {!proto.request.RequestSkill}
 */
proto.request.AddRequestInformationRequest.prototype.addRequestSkills = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 53, opt_value, proto.request.RequestSkill, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearRequestSkillsList = function() {
  return this.setRequestSkillsList([]);
};


/**
 * optional string word_count_check_other = 54;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getWordCountCheckOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 54, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setWordCountCheckOther = function(value) {
  return jspb.Message.setProto3StringField(this, 54, value);
};


/**
 * optional string article_price_check_other = 55;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getArticlePriceCheckOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setArticlePriceCheckOther = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional string article_count_check_other = 56;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getArticleCountCheckOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 56, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setArticleCountCheckOther = function(value) {
  return jspb.Message.setProto3StringField(this, 56, value);
};


/**
 * optional int32 time_count = 57;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getTimeCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 57, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setTimeCount = function(value) {
  return jspb.Message.setProto3IntField(this, 57, value);
};


/**
 * optional string time_count_check_other = 58;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getTimeCountCheckOther = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 58, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setTimeCountCheckOther = function(value) {
  return jspb.Message.setProto3StringField(this, 58, value);
};


/**
 * optional int32 assign_copyright = 59;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getAssignCopyright = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 59, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setAssignCopyright = function(value) {
  return jspb.Message.setProto3IntField(this, 59, value);
};


/**
 * optional int32 secondary_use = 60;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getSecondaryUse = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 60, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setSecondaryUse = function(value) {
  return jspb.Message.setProto3IntField(this, 60, value);
};


/**
 * optional int32 style = 61;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getStyle = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 61, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setStyle = function(value) {
  return jspb.Message.setProto3IntField(this, 61, value);
};


/**
 * optional int32 language = 62;
 * @return {number}
 */
proto.request.AddRequestInformationRequest.prototype.getLanguage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 62, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3IntField(this, 62, value);
};


/**
 * repeated int32 service_title = 63;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getServiceTitleList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 63));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setServiceTitleList = function(value) {
  return jspb.Message.setField(this, 63, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addServiceTitle = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 63, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearServiceTitleList = function() {
  return this.setServiceTitleList([]);
};


/**
 * repeated int32 media = 64;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getMediaList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 64));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setMediaList = function(value) {
  return jspb.Message.setField(this, 64, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addMedia = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 64, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearMediaList = function() {
  return this.setMediaList([]);
};


/**
 * repeated int32 voice_type = 65;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getVoiceTypeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 65));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setVoiceTypeList = function(value) {
  return jspb.Message.setField(this, 65, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addVoiceType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 65, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearVoiceTypeList = function() {
  return this.setVoiceTypeList([]);
};


/**
 * optional bool is_budget_selected = 66;
 * @return {boolean}
 */
proto.request.AddRequestInformationRequest.prototype.getIsBudgetSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 66, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setIsBudgetSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 66, value);
};


/**
 * repeated int32 word_count_check = 67;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getWordCountCheckList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 67));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setWordCountCheckList = function(value) {
  return jspb.Message.setField(this, 67, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addWordCountCheck = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 67, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearWordCountCheckList = function() {
  return this.setWordCountCheckList([]);
};


/**
 * repeated int32 article_price_check = 68;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getArticlePriceCheckList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 68));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setArticlePriceCheckList = function(value) {
  return jspb.Message.setField(this, 68, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addArticlePriceCheck = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 68, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearArticlePriceCheckList = function() {
  return this.setArticlePriceCheckList([]);
};


/**
 * repeated int32 article_count_check = 69;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getArticleCountCheckList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 69));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setArticleCountCheckList = function(value) {
  return jspb.Message.setField(this, 69, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addArticleCountCheck = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 69, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearArticleCountCheckList = function() {
  return this.setArticleCountCheckList([]);
};


/**
 * repeated int32 time_count_check = 70;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getTimeCountCheckList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 70));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setTimeCountCheckList = function(value) {
  return jspb.Message.setField(this, 70, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addTimeCountCheck = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 70, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearTimeCountCheckList = function() {
  return this.setTimeCountCheckList([]);
};


/**
 * optional string free_word_text = 71;
 * @return {string}
 */
proto.request.AddRequestInformationRequest.prototype.getFreeWordText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 71, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setFreeWordText = function(value) {
  return jspb.Message.setProto3StringField(this, 71, value);
};


/**
 * repeated int32 target_sexes = 72;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getTargetSexesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 72));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setTargetSexesList = function(value) {
  return jspb.Message.setField(this, 72, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addTargetSexes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 72, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearTargetSexesList = function() {
  return this.setTargetSexesList([]);
};


/**
 * repeated int32 target_jobs = 73;
 * @return {!Array<number>}
 */
proto.request.AddRequestInformationRequest.prototype.getTargetJobsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 73));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.setTargetJobsList = function(value) {
  return jspb.Message.setField(this, 73, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.addTargetJobs = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 73, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.AddRequestInformationRequest} returns this
 */
proto.request.AddRequestInformationRequest.prototype.clearTargetJobsList = function() {
  return this.setTargetJobsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.RequestSkill.prototype.toObject = function(opt_includeInstance) {
  return proto.request.RequestSkill.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.RequestSkill} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.RequestSkill.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestSkill: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.RequestSkill}
 */
proto.request.RequestSkill.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.RequestSkill;
  return proto.request.RequestSkill.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.RequestSkill} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.RequestSkill}
 */
proto.request.RequestSkill.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRequestSkill(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.RequestSkill.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.RequestSkill.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.RequestSkill} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.RequestSkill.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestSkill();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string request_skill = 1;
 * @return {string}
 */
proto.request.RequestSkill.prototype.getRequestSkill = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.RequestSkill} returns this
 */
proto.request.RequestSkill.prototype.setRequestSkill = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.AttachmentFiles.prototype.toObject = function(opt_includeInstance) {
  return proto.request.AttachmentFiles.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.AttachmentFiles} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AttachmentFiles.toObject = function(includeInstance, msg) {
  var f, obj = {
    fileName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fileUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mime: jspb.Message.getFieldWithDefault(msg, 3, ""),
    size: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.AttachmentFiles}
 */
proto.request.AttachmentFiles.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.AttachmentFiles;
  return proto.request.AttachmentFiles.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.AttachmentFiles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.AttachmentFiles}
 */
proto.request.AttachmentFiles.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFileUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMime(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.AttachmentFiles.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.AttachmentFiles.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.AttachmentFiles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AttachmentFiles.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFileName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFileUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMime();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSize();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
};


/**
 * optional string file_name = 1;
 * @return {string}
 */
proto.request.AttachmentFiles.prototype.getFileName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AttachmentFiles} returns this
 */
proto.request.AttachmentFiles.prototype.setFileName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string file_url = 2;
 * @return {string}
 */
proto.request.AttachmentFiles.prototype.getFileUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AttachmentFiles} returns this
 */
proto.request.AttachmentFiles.prototype.setFileUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string mime = 3;
 * @return {string}
 */
proto.request.AttachmentFiles.prototype.getMime = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AttachmentFiles} returns this
 */
proto.request.AttachmentFiles.prototype.setMime = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 size = 4;
 * @return {number}
 */
proto.request.AttachmentFiles.prototype.getSize = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AttachmentFiles} returns this
 */
proto.request.AttachmentFiles.prototype.setSize = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.AddRequestInformationReply.prototype.toObject = function(opt_includeInstance) {
  return proto.request.AddRequestInformationReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.AddRequestInformationReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddRequestInformationReply.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, ""),
    requestId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.AddRequestInformationReply}
 */
proto.request.AddRequestInformationReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.AddRequestInformationReply;
  return proto.request.AddRequestInformationReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.AddRequestInformationReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.AddRequestInformationReply}
 */
proto.request.AddRequestInformationReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.AddRequestInformationReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.AddRequestInformationReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.AddRequestInformationReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddRequestInformationReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.request.AddRequestInformationReply.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.AddRequestInformationReply} returns this
 */
proto.request.AddRequestInformationReply.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 request_id = 2;
 * @return {number}
 */
proto.request.AddRequestInformationReply.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddRequestInformationReply} returns this
 */
proto.request.AddRequestInformationReply.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.UpdateRequestInformationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.UpdateRequestInformationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.UpdateRequestInformationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.UpdateRequestInformationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    requestInfo: (f = msg.getRequestInfo()) && proto.request.AddRequestInformationRequest.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.UpdateRequestInformationRequest}
 */
proto.request.UpdateRequestInformationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.UpdateRequestInformationRequest;
  return proto.request.UpdateRequestInformationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.UpdateRequestInformationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.UpdateRequestInformationRequest}
 */
proto.request.UpdateRequestInformationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequestId(value);
      break;
    case 2:
      var value = new proto.request.AddRequestInformationRequest;
      reader.readMessage(value,proto.request.AddRequestInformationRequest.deserializeBinaryFromReader);
      msg.setRequestInfo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.UpdateRequestInformationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.UpdateRequestInformationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.UpdateRequestInformationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.UpdateRequestInformationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getRequestInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.request.AddRequestInformationRequest.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 request_id = 1;
 * @return {number}
 */
proto.request.UpdateRequestInformationRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.UpdateRequestInformationRequest} returns this
 */
proto.request.UpdateRequestInformationRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional AddRequestInformationRequest request_info = 2;
 * @return {?proto.request.AddRequestInformationRequest}
 */
proto.request.UpdateRequestInformationRequest.prototype.getRequestInfo = function() {
  return /** @type{?proto.request.AddRequestInformationRequest} */ (
    jspb.Message.getWrapperField(this, proto.request.AddRequestInformationRequest, 2));
};


/**
 * @param {?proto.request.AddRequestInformationRequest|undefined} value
 * @return {!proto.request.UpdateRequestInformationRequest} returns this
*/
proto.request.UpdateRequestInformationRequest.prototype.setRequestInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.UpdateRequestInformationRequest} returns this
 */
proto.request.UpdateRequestInformationRequest.prototype.clearRequestInfo = function() {
  return this.setRequestInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.UpdateRequestInformationRequest.prototype.hasRequestInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.UpdateRequestInformationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.request.UpdateRequestInformationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.UpdateRequestInformationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.UpdateRequestInformationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    message: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.UpdateRequestInformationResponse}
 */
proto.request.UpdateRequestInformationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.UpdateRequestInformationResponse;
  return proto.request.UpdateRequestInformationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.UpdateRequestInformationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.UpdateRequestInformationResponse}
 */
proto.request.UpdateRequestInformationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.UpdateRequestInformationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.UpdateRequestInformationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.UpdateRequestInformationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.UpdateRequestInformationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string message = 1;
 * @return {string}
 */
proto.request.UpdateRequestInformationResponse.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.UpdateRequestInformationResponse} returns this
 */
proto.request.UpdateRequestInformationResponse.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.DeleteRequestInformationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.DeleteRequestInformationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.DeleteRequestInformationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.DeleteRequestInformationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.DeleteRequestInformationRequest}
 */
proto.request.DeleteRequestInformationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.DeleteRequestInformationRequest;
  return proto.request.DeleteRequestInformationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.DeleteRequestInformationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.DeleteRequestInformationRequest}
 */
proto.request.DeleteRequestInformationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.DeleteRequestInformationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.DeleteRequestInformationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.DeleteRequestInformationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.DeleteRequestInformationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.request.DeleteRequestInformationRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.DeleteRequestInformationRequest} returns this
 */
proto.request.DeleteRequestInformationRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.DeleteRequestInformationReply.prototype.toObject = function(opt_includeInstance) {
  return proto.request.DeleteRequestInformationReply.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.DeleteRequestInformationReply} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.DeleteRequestInformationReply.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.DeleteRequestInformationReply}
 */
proto.request.DeleteRequestInformationReply.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.DeleteRequestInformationReply;
  return proto.request.DeleteRequestInformationReply.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.DeleteRequestInformationReply} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.DeleteRequestInformationReply}
 */
proto.request.DeleteRequestInformationReply.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.DeleteRequestInformationReply.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.DeleteRequestInformationReply.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.DeleteRequestInformationReply} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.DeleteRequestInformationReply.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.GetRequestCopyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.GetRequestCopyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.GetRequestCopyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetRequestCopyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    isDraft: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.GetRequestCopyRequest}
 */
proto.request.GetRequestCopyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.GetRequestCopyRequest;
  return proto.request.GetRequestCopyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.GetRequestCopyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.GetRequestCopyRequest}
 */
proto.request.GetRequestCopyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDraft(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.GetRequestCopyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.GetRequestCopyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.GetRequestCopyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetRequestCopyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getIsDraft();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional int32 request_id = 1;
 * @return {number}
 */
proto.request.GetRequestCopyRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.GetRequestCopyRequest} returns this
 */
proto.request.GetRequestCopyRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool is_draft = 2;
 * @return {boolean}
 */
proto.request.GetRequestCopyRequest.prototype.getIsDraft = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.GetRequestCopyRequest} returns this
 */
proto.request.GetRequestCopyRequest.prototype.setIsDraft = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.GetRequestCopyResponse.repeatedFields_ = [3,4,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.GetRequestCopyResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.request.GetRequestCopyResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.GetRequestCopyResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetRequestCopyResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    parentMasterCategoryId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    masterCategoryId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    requestItemsList: jspb.Message.toObjectList(msg.getRequestItemsList(),
    proto.request.RequestItem.toObject, includeInstance),
    attachmentFilesList: jspb.Message.toObjectList(msg.getAttachmentFilesList(),
    proto.request.AttachmentFiles.toObject, includeInstance),
    masterCategoryTypeId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    lowestPrice: jspb.Message.getFieldWithDefault(msg, 6, 0),
    highestPrice: jspb.Message.getFieldWithDefault(msg, 7, 0),
    priceListList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    isBudgetSelected: jspb.Message.getBooleanFieldWithDefault(msg, 9, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.GetRequestCopyResponse}
 */
proto.request.GetRequestCopyResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.GetRequestCopyResponse;
  return proto.request.GetRequestCopyResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.GetRequestCopyResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.GetRequestCopyResponse}
 */
proto.request.GetRequestCopyResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setParentMasterCategoryId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMasterCategoryId(value);
      break;
    case 3:
      var value = new proto.request.RequestItem;
      reader.readMessage(value,proto.request.RequestItem.deserializeBinaryFromReader);
      msg.addRequestItems(value);
      break;
    case 4:
      var value = new proto.request.AttachmentFiles;
      reader.readMessage(value,proto.request.AttachmentFiles.deserializeBinaryFromReader);
      msg.addAttachmentFiles(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMasterCategoryTypeId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLowestPrice(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHighestPrice(value);
      break;
    case 8:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setPriceListList(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBudgetSelected(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.GetRequestCopyResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.GetRequestCopyResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.GetRequestCopyResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetRequestCopyResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getParentMasterCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMasterCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getRequestItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.request.RequestItem.serializeBinaryToWriter
    );
  }
  f = message.getAttachmentFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.request.AttachmentFiles.serializeBinaryToWriter
    );
  }
  f = message.getMasterCategoryTypeId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLowestPrice();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getHighestPrice();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getPriceListList();
  if (f.length > 0) {
    writer.writePackedInt32(
      8,
      f
    );
  }
  f = message.getIsBudgetSelected();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
};


/**
 * optional int32 parent_master_category_id = 1;
 * @return {number}
 */
proto.request.GetRequestCopyResponse.prototype.getParentMasterCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.GetRequestCopyResponse} returns this
 */
proto.request.GetRequestCopyResponse.prototype.setParentMasterCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 master_category_id = 2;
 * @return {number}
 */
proto.request.GetRequestCopyResponse.prototype.getMasterCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.GetRequestCopyResponse} returns this
 */
proto.request.GetRequestCopyResponse.prototype.setMasterCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated RequestItem request_items = 3;
 * @return {!Array<!proto.request.RequestItem>}
 */
proto.request.GetRequestCopyResponse.prototype.getRequestItemsList = function() {
  return /** @type{!Array<!proto.request.RequestItem>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.request.RequestItem, 3));
};


/**
 * @param {!Array<!proto.request.RequestItem>} value
 * @return {!proto.request.GetRequestCopyResponse} returns this
*/
proto.request.GetRequestCopyResponse.prototype.setRequestItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.request.RequestItem=} opt_value
 * @param {number=} opt_index
 * @return {!proto.request.RequestItem}
 */
proto.request.GetRequestCopyResponse.prototype.addRequestItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.request.RequestItem, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.GetRequestCopyResponse} returns this
 */
proto.request.GetRequestCopyResponse.prototype.clearRequestItemsList = function() {
  return this.setRequestItemsList([]);
};


/**
 * repeated AttachmentFiles attachment_files = 4;
 * @return {!Array<!proto.request.AttachmentFiles>}
 */
proto.request.GetRequestCopyResponse.prototype.getAttachmentFilesList = function() {
  return /** @type{!Array<!proto.request.AttachmentFiles>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.request.AttachmentFiles, 4));
};


/**
 * @param {!Array<!proto.request.AttachmentFiles>} value
 * @return {!proto.request.GetRequestCopyResponse} returns this
*/
proto.request.GetRequestCopyResponse.prototype.setAttachmentFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.request.AttachmentFiles=} opt_value
 * @param {number=} opt_index
 * @return {!proto.request.AttachmentFiles}
 */
proto.request.GetRequestCopyResponse.prototype.addAttachmentFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.request.AttachmentFiles, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.GetRequestCopyResponse} returns this
 */
proto.request.GetRequestCopyResponse.prototype.clearAttachmentFilesList = function() {
  return this.setAttachmentFilesList([]);
};


/**
 * optional int32 master_category_type_id = 5;
 * @return {number}
 */
proto.request.GetRequestCopyResponse.prototype.getMasterCategoryTypeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.GetRequestCopyResponse} returns this
 */
proto.request.GetRequestCopyResponse.prototype.setMasterCategoryTypeId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 lowest_price = 6;
 * @return {number}
 */
proto.request.GetRequestCopyResponse.prototype.getLowestPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.GetRequestCopyResponse} returns this
 */
proto.request.GetRequestCopyResponse.prototype.setLowestPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 highest_price = 7;
 * @return {number}
 */
proto.request.GetRequestCopyResponse.prototype.getHighestPrice = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.GetRequestCopyResponse} returns this
 */
proto.request.GetRequestCopyResponse.prototype.setHighestPrice = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * repeated int32 price_list = 8;
 * @return {!Array<number>}
 */
proto.request.GetRequestCopyResponse.prototype.getPriceListList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.GetRequestCopyResponse} returns this
 */
proto.request.GetRequestCopyResponse.prototype.setPriceListList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.GetRequestCopyResponse} returns this
 */
proto.request.GetRequestCopyResponse.prototype.addPriceList = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.GetRequestCopyResponse} returns this
 */
proto.request.GetRequestCopyResponse.prototype.clearPriceListList = function() {
  return this.setPriceListList([]);
};


/**
 * optional bool is_budget_selected = 9;
 * @return {boolean}
 */
proto.request.GetRequestCopyResponse.prototype.getIsBudgetSelected = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.GetRequestCopyResponse} returns this
 */
proto.request.GetRequestCopyResponse.prototype.setIsBudgetSelected = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.RequestItem.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.RequestItem.prototype.toObject = function(opt_includeInstance) {
  return proto.request.RequestItem.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.RequestItem} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.RequestItem.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    inputText: jspb.Message.getFieldWithDefault(msg, 2, ""),
    selectedValuesList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    inputValuesList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.RequestItem}
 */
proto.request.RequestItem.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.RequestItem;
  return proto.request.RequestItem.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.RequestItem} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.RequestItem}
 */
proto.request.RequestItem.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInputText(value);
      break;
    case 3:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setSelectedValuesList(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addInputValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.RequestItem.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.RequestItem.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.RequestItem} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.RequestItem.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInputText();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSelectedValuesList();
  if (f.length > 0) {
    writer.writePackedInt32(
      3,
      f
    );
  }
  f = message.getInputValuesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.request.RequestItem.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.RequestItem} returns this
 */
proto.request.RequestItem.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string input_text = 2;
 * @return {string}
 */
proto.request.RequestItem.prototype.getInputText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.RequestItem} returns this
 */
proto.request.RequestItem.prototype.setInputText = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated int32 selected_values = 3;
 * @return {!Array<number>}
 */
proto.request.RequestItem.prototype.getSelectedValuesList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.request.RequestItem} returns this
 */
proto.request.RequestItem.prototype.setSelectedValuesList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.request.RequestItem} returns this
 */
proto.request.RequestItem.prototype.addSelectedValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.RequestItem} returns this
 */
proto.request.RequestItem.prototype.clearSelectedValuesList = function() {
  return this.setSelectedValuesList([]);
};


/**
 * repeated string input_values = 4;
 * @return {!Array<string>}
 */
proto.request.RequestItem.prototype.getInputValuesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.request.RequestItem} returns this
 */
proto.request.RequestItem.prototype.setInputValuesList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.request.RequestItem} returns this
 */
proto.request.RequestItem.prototype.addInputValues = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.RequestItem} returns this
 */
proto.request.RequestItem.prototype.clearInputValuesList = function() {
  return this.setInputValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.IsAuthorizedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.IsAuthorizedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.IsAuthorizedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.IsAuthorizedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.IsAuthorizedRequest}
 */
proto.request.IsAuthorizedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.IsAuthorizedRequest;
  return proto.request.IsAuthorizedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.IsAuthorizedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.IsAuthorizedRequest}
 */
proto.request.IsAuthorizedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.IsAuthorizedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.IsAuthorizedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.IsAuthorizedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.IsAuthorizedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.IsAuthorizedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.request.IsAuthorizedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.IsAuthorizedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.IsAuthorizedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isAuthorized: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    reason: (f = msg.getReason()) && proto.request.Reason.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.IsAuthorizedResponse}
 */
proto.request.IsAuthorizedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.IsAuthorizedResponse;
  return proto.request.IsAuthorizedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.IsAuthorizedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.IsAuthorizedResponse}
 */
proto.request.IsAuthorizedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAuthorized(value);
      break;
    case 2:
      var value = new proto.request.Reason;
      reader.readMessage(value,proto.request.Reason.deserializeBinaryFromReader);
      msg.setReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.IsAuthorizedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.IsAuthorizedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.IsAuthorizedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.IsAuthorizedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsAuthorized();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.request.Reason.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_authorized = 1;
 * @return {boolean}
 */
proto.request.IsAuthorizedResponse.prototype.getIsAuthorized = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.IsAuthorizedResponse} returns this
 */
proto.request.IsAuthorizedResponse.prototype.setIsAuthorized = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional Reason reason = 2;
 * @return {?proto.request.Reason}
 */
proto.request.IsAuthorizedResponse.prototype.getReason = function() {
  return /** @type{?proto.request.Reason} */ (
    jspb.Message.getWrapperField(this, proto.request.Reason, 2));
};


/**
 * @param {?proto.request.Reason|undefined} value
 * @return {!proto.request.IsAuthorizedResponse} returns this
*/
proto.request.IsAuthorizedResponse.prototype.setReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.IsAuthorizedResponse} returns this
 */
proto.request.IsAuthorizedResponse.prototype.clearReason = function() {
  return this.setReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.IsAuthorizedResponse.prototype.hasReason = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.Reason.prototype.toObject = function(opt_includeInstance) {
  return proto.request.Reason.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.Reason} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.Reason.toObject = function(includeInstance, msg) {
  var f, obj = {
    isAuthenticatedSms: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.Reason}
 */
proto.request.Reason.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.Reason;
  return proto.request.Reason.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.Reason} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.Reason}
 */
proto.request.Reason.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAuthenticatedSms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.Reason.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.Reason.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.Reason} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.Reason.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsAuthenticatedSms();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_authenticated_sms = 1;
 * @return {boolean}
 */
proto.request.Reason.prototype.getIsAuthenticatedSms = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.Reason} returns this
 */
proto.request.Reason.prototype.setIsAuthenticatedSms = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.ListBookmarksRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.ListBookmarksRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.ListBookmarksRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ListBookmarksRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.ListBookmarksRequest}
 */
proto.request.ListBookmarksRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.ListBookmarksRequest;
  return proto.request.ListBookmarksRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.ListBookmarksRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.ListBookmarksRequest}
 */
proto.request.ListBookmarksRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.ListBookmarksRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.ListBookmarksRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.ListBookmarksRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ListBookmarksRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.request.ListBookmarksRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ListBookmarksRequest} returns this
 */
proto.request.ListBookmarksRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 per_page = 2;
 * @return {number}
 */
proto.request.ListBookmarksRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ListBookmarksRequest} returns this
 */
proto.request.ListBookmarksRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.ListBookmarksResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.ListBookmarksResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.request.ListBookmarksResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.ListBookmarksResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ListBookmarksResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    bookmarksList: jspb.Message.toObjectList(msg.getBookmarksList(),
    proto.request.Request.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isLast: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    total: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.ListBookmarksResponse}
 */
proto.request.ListBookmarksResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.ListBookmarksResponse;
  return proto.request.ListBookmarksResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.ListBookmarksResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.ListBookmarksResponse}
 */
proto.request.ListBookmarksResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.request.Request;
      reader.readMessage(value,proto.request.Request.deserializeBinaryFromReader);
      msg.addBookmarks(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLast(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.ListBookmarksResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.ListBookmarksResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.ListBookmarksResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ListBookmarksResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBookmarksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.request.Request.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIsLast();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * repeated Request bookmarks = 1;
 * @return {!Array<!proto.request.Request>}
 */
proto.request.ListBookmarksResponse.prototype.getBookmarksList = function() {
  return /** @type{!Array<!proto.request.Request>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.request.Request, 1));
};


/**
 * @param {!Array<!proto.request.Request>} value
 * @return {!proto.request.ListBookmarksResponse} returns this
*/
proto.request.ListBookmarksResponse.prototype.setBookmarksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.request.Request=} opt_value
 * @param {number=} opt_index
 * @return {!proto.request.Request}
 */
proto.request.ListBookmarksResponse.prototype.addBookmarks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.request.Request, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.ListBookmarksResponse} returns this
 */
proto.request.ListBookmarksResponse.prototype.clearBookmarksList = function() {
  return this.setBookmarksList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.request.ListBookmarksResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ListBookmarksResponse} returns this
 */
proto.request.ListBookmarksResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 per_page = 3;
 * @return {number}
 */
proto.request.ListBookmarksResponse.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ListBookmarksResponse} returns this
 */
proto.request.ListBookmarksResponse.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_last = 4;
 * @return {boolean}
 */
proto.request.ListBookmarksResponse.prototype.getIsLast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.ListBookmarksResponse} returns this
 */
proto.request.ListBookmarksResponse.prototype.setIsLast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 total = 5;
 * @return {number}
 */
proto.request.ListBookmarksResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ListBookmarksResponse} returns this
 */
proto.request.ListBookmarksResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.AddBookmarkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.AddBookmarkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.AddBookmarkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddBookmarkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.AddBookmarkRequest}
 */
proto.request.AddBookmarkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.AddBookmarkRequest;
  return proto.request.AddBookmarkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.AddBookmarkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.AddBookmarkRequest}
 */
proto.request.AddBookmarkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.AddBookmarkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.AddBookmarkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.AddBookmarkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddBookmarkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 request_id = 1;
 * @return {number}
 */
proto.request.AddBookmarkRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.AddBookmarkRequest} returns this
 */
proto.request.AddBookmarkRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.AddBookmarkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.request.AddBookmarkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.AddBookmarkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddBookmarkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isFirstBookmark: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.AddBookmarkResponse}
 */
proto.request.AddBookmarkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.AddBookmarkResponse;
  return proto.request.AddBookmarkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.AddBookmarkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.AddBookmarkResponse}
 */
proto.request.AddBookmarkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFirstBookmark(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.AddBookmarkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.AddBookmarkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.AddBookmarkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.AddBookmarkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsFirstBookmark();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_first_bookmark = 1;
 * @return {boolean}
 */
proto.request.AddBookmarkResponse.prototype.getIsFirstBookmark = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.AddBookmarkResponse} returns this
 */
proto.request.AddBookmarkResponse.prototype.setIsFirstBookmark = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.DeleteBookmarkRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.DeleteBookmarkRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.DeleteBookmarkRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.DeleteBookmarkRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.DeleteBookmarkRequest}
 */
proto.request.DeleteBookmarkRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.DeleteBookmarkRequest;
  return proto.request.DeleteBookmarkRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.DeleteBookmarkRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.DeleteBookmarkRequest}
 */
proto.request.DeleteBookmarkRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequestId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.DeleteBookmarkRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.DeleteBookmarkRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.DeleteBookmarkRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.DeleteBookmarkRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
};


/**
 * optional int32 request_id = 1;
 * @return {number}
 */
proto.request.DeleteBookmarkRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.DeleteBookmarkRequest} returns this
 */
proto.request.DeleteBookmarkRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.DeleteBookmarkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.request.DeleteBookmarkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.DeleteBookmarkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.DeleteBookmarkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.DeleteBookmarkResponse}
 */
proto.request.DeleteBookmarkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.DeleteBookmarkResponse;
  return proto.request.DeleteBookmarkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.DeleteBookmarkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.DeleteBookmarkResponse}
 */
proto.request.DeleteBookmarkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.DeleteBookmarkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.DeleteBookmarkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.DeleteBookmarkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.DeleteBookmarkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.ListCopyTargetRequestsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.ListCopyTargetRequestsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.ListCopyTargetRequestsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ListCopyTargetRequestsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.ListCopyTargetRequestsRequest}
 */
proto.request.ListCopyTargetRequestsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.ListCopyTargetRequestsRequest;
  return proto.request.ListCopyTargetRequestsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.ListCopyTargetRequestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.ListCopyTargetRequestsRequest}
 */
proto.request.ListCopyTargetRequestsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.ListCopyTargetRequestsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.ListCopyTargetRequestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.ListCopyTargetRequestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ListCopyTargetRequestsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.ListCopyTargetRequestsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.ListCopyTargetRequestsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.request.ListCopyTargetRequestsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.ListCopyTargetRequestsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ListCopyTargetRequestsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestsList: jspb.Message.toObjectList(msg.getRequestsList(),
    proto.request.CopyRequest.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.ListCopyTargetRequestsResponse}
 */
proto.request.ListCopyTargetRequestsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.ListCopyTargetRequestsResponse;
  return proto.request.ListCopyTargetRequestsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.ListCopyTargetRequestsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.ListCopyTargetRequestsResponse}
 */
proto.request.ListCopyTargetRequestsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.request.CopyRequest;
      reader.readMessage(value,proto.request.CopyRequest.deserializeBinaryFromReader);
      msg.addRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.ListCopyTargetRequestsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.ListCopyTargetRequestsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.ListCopyTargetRequestsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ListCopyTargetRequestsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.request.CopyRequest.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CopyRequest requests = 1;
 * @return {!Array<!proto.request.CopyRequest>}
 */
proto.request.ListCopyTargetRequestsResponse.prototype.getRequestsList = function() {
  return /** @type{!Array<!proto.request.CopyRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.request.CopyRequest, 1));
};


/**
 * @param {!Array<!proto.request.CopyRequest>} value
 * @return {!proto.request.ListCopyTargetRequestsResponse} returns this
*/
proto.request.ListCopyTargetRequestsResponse.prototype.setRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.request.CopyRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.request.CopyRequest}
 */
proto.request.ListCopyTargetRequestsResponse.prototype.addRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.request.CopyRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.ListCopyTargetRequestsResponse} returns this
 */
proto.request.ListCopyTargetRequestsResponse.prototype.clearRequestsList = function() {
  return this.setRequestsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.CopyRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.CopyRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.CopyRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.CopyRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    masterCategoryId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    title: jspb.Message.getFieldWithDefault(msg, 3, ""),
    content: jspb.Message.getFieldWithDefault(msg, 4, ""),
    opened: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.CopyRequest}
 */
proto.request.CopyRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.CopyRequest;
  return proto.request.CopyRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.CopyRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.CopyRequest}
 */
proto.request.CopyRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMasterCategoryId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setOpened(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.CopyRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.CopyRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.CopyRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.CopyRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getMasterCategoryId();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOpened();
  if (f !== 0) {
    writer.writeInt64(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.request.CopyRequest.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.CopyRequest} returns this
 */
proto.request.CopyRequest.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 master_category_id = 2;
 * @return {number}
 */
proto.request.CopyRequest.prototype.getMasterCategoryId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.CopyRequest} returns this
 */
proto.request.CopyRequest.prototype.setMasterCategoryId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string title = 3;
 * @return {string}
 */
proto.request.CopyRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.CopyRequest} returns this
 */
proto.request.CopyRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string content = 4;
 * @return {string}
 */
proto.request.CopyRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.CopyRequest} returns this
 */
proto.request.CopyRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int64 opened = 5;
 * @return {number}
 */
proto.request.CopyRequest.prototype.getOpened = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.CopyRequest} returns this
 */
proto.request.CopyRequest.prototype.setOpened = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.ListProposedRequestsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.ListProposedRequestsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.ListProposedRequestsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ListProposedRequestsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    page: jspb.Message.getFieldWithDefault(msg, 1, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.ListProposedRequestsRequest}
 */
proto.request.ListProposedRequestsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.ListProposedRequestsRequest;
  return proto.request.ListProposedRequestsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.ListProposedRequestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.ListProposedRequestsRequest}
 */
proto.request.ListProposedRequestsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.ListProposedRequestsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.ListProposedRequestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.ListProposedRequestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ListProposedRequestsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 page = 1;
 * @return {number}
 */
proto.request.ListProposedRequestsRequest.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ListProposedRequestsRequest} returns this
 */
proto.request.ListProposedRequestsRequest.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 per_page = 2;
 * @return {number}
 */
proto.request.ListProposedRequestsRequest.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ListProposedRequestsRequest} returns this
 */
proto.request.ListProposedRequestsRequest.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.ListProposedRequestsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.ListProposedRequestsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.request.ListProposedRequestsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.ListProposedRequestsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ListProposedRequestsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    proposedRequestsList: jspb.Message.toObjectList(msg.getProposedRequestsList(),
    proto.request.ProposedRequest.toObject, includeInstance),
    page: jspb.Message.getFieldWithDefault(msg, 2, 0),
    perPage: jspb.Message.getFieldWithDefault(msg, 3, 0),
    isLast: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    total: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.ListProposedRequestsResponse}
 */
proto.request.ListProposedRequestsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.ListProposedRequestsResponse;
  return proto.request.ListProposedRequestsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.ListProposedRequestsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.ListProposedRequestsResponse}
 */
proto.request.ListProposedRequestsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.request.ProposedRequest;
      reader.readMessage(value,proto.request.ProposedRequest.deserializeBinaryFromReader);
      msg.addProposedRequests(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPerPage(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLast(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTotal(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.ListProposedRequestsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.ListProposedRequestsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.ListProposedRequestsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ListProposedRequestsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProposedRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.request.ProposedRequest.serializeBinaryToWriter
    );
  }
  f = message.getPage();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPerPage();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIsLast();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getTotal();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * repeated ProposedRequest proposed_requests = 1;
 * @return {!Array<!proto.request.ProposedRequest>}
 */
proto.request.ListProposedRequestsResponse.prototype.getProposedRequestsList = function() {
  return /** @type{!Array<!proto.request.ProposedRequest>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.request.ProposedRequest, 1));
};


/**
 * @param {!Array<!proto.request.ProposedRequest>} value
 * @return {!proto.request.ListProposedRequestsResponse} returns this
*/
proto.request.ListProposedRequestsResponse.prototype.setProposedRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.request.ProposedRequest=} opt_value
 * @param {number=} opt_index
 * @return {!proto.request.ProposedRequest}
 */
proto.request.ListProposedRequestsResponse.prototype.addProposedRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.request.ProposedRequest, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.ListProposedRequestsResponse} returns this
 */
proto.request.ListProposedRequestsResponse.prototype.clearProposedRequestsList = function() {
  return this.setProposedRequestsList([]);
};


/**
 * optional int32 page = 2;
 * @return {number}
 */
proto.request.ListProposedRequestsResponse.prototype.getPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ListProposedRequestsResponse} returns this
 */
proto.request.ListProposedRequestsResponse.prototype.setPage = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 per_page = 3;
 * @return {number}
 */
proto.request.ListProposedRequestsResponse.prototype.getPerPage = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ListProposedRequestsResponse} returns this
 */
proto.request.ListProposedRequestsResponse.prototype.setPerPage = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bool is_last = 4;
 * @return {boolean}
 */
proto.request.ListProposedRequestsResponse.prototype.getIsLast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.ListProposedRequestsResponse} returns this
 */
proto.request.ListProposedRequestsResponse.prototype.setIsLast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 total = 5;
 * @return {number}
 */
proto.request.ListProposedRequestsResponse.prototype.getTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ListProposedRequestsResponse} returns this
 */
proto.request.ListProposedRequestsResponse.prototype.setTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.ProposedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.ProposedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.ProposedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ProposedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    content: jspb.Message.getFieldWithDefault(msg, 3, ""),
    recruitmentDeadline: jspb.Message.getFieldWithDefault(msg, 4, 0),
    offerId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    offerAmount: jspb.Message.getFieldWithDefault(msg, 6, 0),
    scheduleCompletionAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isDeleted: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    userId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    userName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    userIconPath: jspb.Message.getFieldWithDefault(msg, 11, ""),
    isRecruitmentFinished: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.ProposedRequest}
 */
proto.request.ProposedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.ProposedRequest;
  return proto.request.ProposedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.ProposedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.ProposedRequest}
 */
proto.request.ProposedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRequestId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRecruitmentDeadline(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOfferId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOfferAmount(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setScheduleCompletionAt(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsDeleted(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserIconPath(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsRecruitmentFinished(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.ProposedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.ProposedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.ProposedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.ProposedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRecruitmentDeadline();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
  f = message.getOfferId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getOfferAmount();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getScheduleCompletionAt();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getIsDeleted();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getUserName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getUserIconPath();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getIsRecruitmentFinished();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional int32 request_id = 1;
 * @return {number}
 */
proto.request.ProposedRequest.prototype.getRequestId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ProposedRequest} returns this
 */
proto.request.ProposedRequest.prototype.setRequestId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.request.ProposedRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.ProposedRequest} returns this
 */
proto.request.ProposedRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content = 3;
 * @return {string}
 */
proto.request.ProposedRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.ProposedRequest} returns this
 */
proto.request.ProposedRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int64 recruitment_deadline = 4;
 * @return {number}
 */
proto.request.ProposedRequest.prototype.getRecruitmentDeadline = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ProposedRequest} returns this
 */
proto.request.ProposedRequest.prototype.setRecruitmentDeadline = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 offer_id = 5;
 * @return {number}
 */
proto.request.ProposedRequest.prototype.getOfferId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ProposedRequest} returns this
 */
proto.request.ProposedRequest.prototype.setOfferId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 offer_amount = 6;
 * @return {number}
 */
proto.request.ProposedRequest.prototype.getOfferAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ProposedRequest} returns this
 */
proto.request.ProposedRequest.prototype.setOfferAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int64 schedule_completion_at = 7;
 * @return {number}
 */
proto.request.ProposedRequest.prototype.getScheduleCompletionAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ProposedRequest} returns this
 */
proto.request.ProposedRequest.prototype.setScheduleCompletionAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool is_deleted = 8;
 * @return {boolean}
 */
proto.request.ProposedRequest.prototype.getIsDeleted = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.ProposedRequest} returns this
 */
proto.request.ProposedRequest.prototype.setIsDeleted = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 user_id = 9;
 * @return {number}
 */
proto.request.ProposedRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.request.ProposedRequest} returns this
 */
proto.request.ProposedRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string user_name = 10;
 * @return {string}
 */
proto.request.ProposedRequest.prototype.getUserName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.ProposedRequest} returns this
 */
proto.request.ProposedRequest.prototype.setUserName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string user_icon_path = 11;
 * @return {string}
 */
proto.request.ProposedRequest.prototype.getUserIconPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.request.ProposedRequest} returns this
 */
proto.request.ProposedRequest.prototype.setUserIconPath = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool is_recruitment_finished = 12;
 * @return {boolean}
 */
proto.request.ProposedRequest.prototype.getIsRecruitmentFinished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.ProposedRequest} returns this
 */
proto.request.ProposedRequest.prototype.setIsRecruitmentFinished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.IsQuestionAuthorizedRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.IsQuestionAuthorizedRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.IsQuestionAuthorizedRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.IsQuestionAuthorizedRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.IsQuestionAuthorizedRequest}
 */
proto.request.IsQuestionAuthorizedRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.IsQuestionAuthorizedRequest;
  return proto.request.IsQuestionAuthorizedRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.IsQuestionAuthorizedRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.IsQuestionAuthorizedRequest}
 */
proto.request.IsQuestionAuthorizedRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.IsQuestionAuthorizedRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.IsQuestionAuthorizedRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.IsQuestionAuthorizedRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.IsQuestionAuthorizedRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.IsQuestionAuthorizedResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.request.IsQuestionAuthorizedResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.IsQuestionAuthorizedResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.IsQuestionAuthorizedResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isAuthorized: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    questionReason: (f = msg.getQuestionReason()) && proto.request.QuestionReason.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.IsQuestionAuthorizedResponse}
 */
proto.request.IsQuestionAuthorizedResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.IsQuestionAuthorizedResponse;
  return proto.request.IsQuestionAuthorizedResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.IsQuestionAuthorizedResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.IsQuestionAuthorizedResponse}
 */
proto.request.IsQuestionAuthorizedResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAuthorized(value);
      break;
    case 2:
      var value = new proto.request.QuestionReason;
      reader.readMessage(value,proto.request.QuestionReason.deserializeBinaryFromReader);
      msg.setQuestionReason(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.IsQuestionAuthorizedResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.IsQuestionAuthorizedResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.IsQuestionAuthorizedResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.IsQuestionAuthorizedResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsAuthorized();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getQuestionReason();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.request.QuestionReason.serializeBinaryToWriter
    );
  }
};


/**
 * optional bool is_authorized = 1;
 * @return {boolean}
 */
proto.request.IsQuestionAuthorizedResponse.prototype.getIsAuthorized = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.IsQuestionAuthorizedResponse} returns this
 */
proto.request.IsQuestionAuthorizedResponse.prototype.setIsAuthorized = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional QuestionReason question_reason = 2;
 * @return {?proto.request.QuestionReason}
 */
proto.request.IsQuestionAuthorizedResponse.prototype.getQuestionReason = function() {
  return /** @type{?proto.request.QuestionReason} */ (
    jspb.Message.getWrapperField(this, proto.request.QuestionReason, 2));
};


/**
 * @param {?proto.request.QuestionReason|undefined} value
 * @return {!proto.request.IsQuestionAuthorizedResponse} returns this
*/
proto.request.IsQuestionAuthorizedResponse.prototype.setQuestionReason = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.IsQuestionAuthorizedResponse} returns this
 */
proto.request.IsQuestionAuthorizedResponse.prototype.clearQuestionReason = function() {
  return this.setQuestionReason(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.IsQuestionAuthorizedResponse.prototype.hasQuestionReason = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.QuestionReason.prototype.toObject = function(opt_includeInstance) {
  return proto.request.QuestionReason.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.QuestionReason} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.QuestionReason.toObject = function(includeInstance, msg) {
  var f, obj = {
    isAuthenticatedSms: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.QuestionReason}
 */
proto.request.QuestionReason.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.QuestionReason;
  return proto.request.QuestionReason.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.QuestionReason} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.QuestionReason}
 */
proto.request.QuestionReason.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAuthenticatedSms(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.QuestionReason.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.QuestionReason.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.QuestionReason} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.QuestionReason.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsAuthenticatedSms();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_authenticated_sms = 1;
 * @return {boolean}
 */
proto.request.QuestionReason.prototype.getIsAuthenticatedSms = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.request.QuestionReason} returns this
 */
proto.request.QuestionReason.prototype.setIsAuthenticatedSms = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.GetRecommendRequestsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.GetRecommendRequestsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.GetRecommendRequestsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetRecommendRequestsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    algorithm: (f = msg.getAlgorithm()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.GetRecommendRequestsRequest}
 */
proto.request.GetRecommendRequestsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.GetRecommendRequestsRequest;
  return proto.request.GetRecommendRequestsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.GetRecommendRequestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.GetRecommendRequestsRequest}
 */
proto.request.GetRecommendRequestsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAlgorithm(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.GetRecommendRequestsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.GetRecommendRequestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.GetRecommendRequestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetRecommendRequestsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlgorithm();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue algorithm = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.request.GetRecommendRequestsRequest.prototype.getAlgorithm = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.request.GetRecommendRequestsRequest} returns this
*/
proto.request.GetRecommendRequestsRequest.prototype.setAlgorithm = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.GetRecommendRequestsRequest} returns this
 */
proto.request.GetRecommendRequestsRequest.prototype.clearAlgorithm = function() {
  return this.setAlgorithm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.GetRecommendRequestsRequest.prototype.hasAlgorithm = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.GetRecommendRequestsResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.GetRecommendRequestsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.request.GetRecommendRequestsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.GetRecommendRequestsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetRecommendRequestsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    algorithm: (f = msg.getAlgorithm()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    impressionParamsJson: (f = msg.getImpressionParamsJson()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    recommendRequestsList: jspb.Message.toObjectList(msg.getRecommendRequestsList(),
    proto.request.Request.toObject, includeInstance),
    currentDate: (f = msg.getCurrentDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.GetRecommendRequestsResponse}
 */
proto.request.GetRecommendRequestsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.GetRecommendRequestsResponse;
  return proto.request.GetRecommendRequestsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.GetRecommendRequestsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.GetRecommendRequestsResponse}
 */
proto.request.GetRecommendRequestsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setAlgorithm(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setImpressionParamsJson(value);
      break;
    case 3:
      var value = new proto.request.Request;
      reader.readMessage(value,proto.request.Request.deserializeBinaryFromReader);
      msg.addRecommendRequests(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCurrentDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.GetRecommendRequestsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.GetRecommendRequestsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.GetRecommendRequestsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetRecommendRequestsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAlgorithm();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getImpressionParamsJson();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getRecommendRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.request.Request.serializeBinaryToWriter
    );
  }
  f = message.getCurrentDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.StringValue algorithm = 1;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.request.GetRecommendRequestsResponse.prototype.getAlgorithm = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 1));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.request.GetRecommendRequestsResponse} returns this
*/
proto.request.GetRecommendRequestsResponse.prototype.setAlgorithm = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.GetRecommendRequestsResponse} returns this
 */
proto.request.GetRecommendRequestsResponse.prototype.clearAlgorithm = function() {
  return this.setAlgorithm(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.GetRecommendRequestsResponse.prototype.hasAlgorithm = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.StringValue impression_params_json = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.request.GetRecommendRequestsResponse.prototype.getImpressionParamsJson = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.request.GetRecommendRequestsResponse} returns this
*/
proto.request.GetRecommendRequestsResponse.prototype.setImpressionParamsJson = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.GetRecommendRequestsResponse} returns this
 */
proto.request.GetRecommendRequestsResponse.prototype.clearImpressionParamsJson = function() {
  return this.setImpressionParamsJson(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.GetRecommendRequestsResponse.prototype.hasImpressionParamsJson = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Request recommend_requests = 3;
 * @return {!Array<!proto.request.Request>}
 */
proto.request.GetRecommendRequestsResponse.prototype.getRecommendRequestsList = function() {
  return /** @type{!Array<!proto.request.Request>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.request.Request, 3));
};


/**
 * @param {!Array<!proto.request.Request>} value
 * @return {!proto.request.GetRecommendRequestsResponse} returns this
*/
proto.request.GetRecommendRequestsResponse.prototype.setRecommendRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.request.Request=} opt_value
 * @param {number=} opt_index
 * @return {!proto.request.Request}
 */
proto.request.GetRecommendRequestsResponse.prototype.addRecommendRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.request.Request, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.GetRecommendRequestsResponse} returns this
 */
proto.request.GetRecommendRequestsResponse.prototype.clearRecommendRequestsList = function() {
  return this.setRecommendRequestsList([]);
};


/**
 * optional google.protobuf.Timestamp current_date = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.request.GetRecommendRequestsResponse.prototype.getCurrentDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.request.GetRecommendRequestsResponse} returns this
*/
proto.request.GetRecommendRequestsResponse.prototype.setCurrentDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.request.GetRecommendRequestsResponse} returns this
 */
proto.request.GetRecommendRequestsResponse.prototype.clearCurrentDate = function() {
  return this.setCurrentDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.request.GetRecommendRequestsResponse.prototype.hasCurrentDate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.GetPopularRequestsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.request.GetPopularRequestsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.GetPopularRequestsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetPopularRequestsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.GetPopularRequestsRequest}
 */
proto.request.GetPopularRequestsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.GetPopularRequestsRequest;
  return proto.request.GetPopularRequestsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.GetPopularRequestsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.GetPopularRequestsRequest}
 */
proto.request.GetPopularRequestsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.GetPopularRequestsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.GetPopularRequestsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.GetPopularRequestsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetPopularRequestsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.request.GetPopularRequestsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.request.GetPopularRequestsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.request.GetPopularRequestsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.request.GetPopularRequestsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetPopularRequestsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestsList: jspb.Message.toObjectList(msg.getRequestsList(),
    proto.request.Request.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.request.GetPopularRequestsResponse}
 */
proto.request.GetPopularRequestsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.request.GetPopularRequestsResponse;
  return proto.request.GetPopularRequestsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.request.GetPopularRequestsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.request.GetPopularRequestsResponse}
 */
proto.request.GetPopularRequestsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.request.Request;
      reader.readMessage(value,proto.request.Request.deserializeBinaryFromReader);
      msg.addRequests(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.request.GetPopularRequestsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.request.GetPopularRequestsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.request.GetPopularRequestsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.request.GetPopularRequestsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.request.Request.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Request requests = 1;
 * @return {!Array<!proto.request.Request>}
 */
proto.request.GetPopularRequestsResponse.prototype.getRequestsList = function() {
  return /** @type{!Array<!proto.request.Request>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.request.Request, 1));
};


/**
 * @param {!Array<!proto.request.Request>} value
 * @return {!proto.request.GetPopularRequestsResponse} returns this
*/
proto.request.GetPopularRequestsResponse.prototype.setRequestsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.request.Request=} opt_value
 * @param {number=} opt_index
 * @return {!proto.request.Request}
 */
proto.request.GetPopularRequestsResponse.prototype.addRequests = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.request.Request, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.request.GetPopularRequestsResponse} returns this
 */
proto.request.GetPopularRequestsResponse.prototype.clearRequestsList = function() {
  return this.setRequestsList([]);
};


goog.object.extend(exports, proto.request);
