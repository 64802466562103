import util from '~/assets/javascripts/util'
import { commonMetaData } from '~/store/_helpers/common-helper'
import grpcClient from '@/grpc/grpc-client'
import { checkMzhcIdentityInProgress } from '~/components/logics/mzhc'
import { MEASURING_APP_INSTALL, ONELINK_URL_ID } from '~/constants/cookie'
import { pickOnelinkUtmParamsFromQuery } from '~/components/logics/appsflyer'
import { COOKIE_KEY_INVITATION_KEY } from '~/constants/business'

export const state = () => ({
  route: null // NOTE: vuex-router-sync用に初期化
})

export const getters = {
  entered: state => state.entered
}

export const actions = {
  async nuxtServerInit(
    { state, getters, dispatch, commit },
    { error, req, redirect, route, query }
  ) {
    await dispatch('auth/setAppId')

    // cookieにccuidがなかったら発行する
    // 開発環境で確認する場合は'secure: true'を外す
    let ccuid = this.$cookies.get(process.env.config['ccuid'])
    if (!ccuid) {
      ccuid =
        new Date().getTime().toString(16) +
        ':' +
        Math.floor(Math.random() * 0xffffffff).toString(16)

      this.$cookies.set('ccuid', ccuid, {
        path: '/',
        maxAge: 60 * 60 * 24 * 365 * 2,
        secure: true
      })
    }

    // パラメータ付きリンク経由キャンペーン用のパラメータの値をCookieにセット
    // 有効期間は7日間で設定
    const campaign = state.route.query.campaign
    if (campaign) {
      this.$cookies.set('via_link_with_param', campaign, {
        path: '/',
        maxAge: 60 * 60 * 24 * 7
      })
    }

    // x-user-agent用のuser-agent取得
    const ua = this.$ua._ua
    // x-forwaded-for用のip取得
    let ip = ''
    if (req.headers && req.headers['x-real-ip']) {
      ip = req.headers['x-real-ip']
    } else if (req.headers && req.headers['x-forwarded-for']) {
      ip = req.headers['x-forwarded-for']
    } else if (req.connection && req.connection.remoteAddress) {
      ip = req.connection.remoteAddress
    } else if (req.connection && req.connection.socket && req.connection.socket.remoteAddress) {
      ip = req.connection.socket.remoteAddress
    } else if (req.socket && req.socket.remoteAddress) {
      ip = req.socket.remoteAddress
    }
    // x-referer用のreferer取得
    let referer = ''
    if (req.headers) {
      if (req.headers.referrer) {
        referer = req.headers.referrer
      } else if (req.headers.referer) {
        referer = req.headers.referer
      }
    }

    // cookieのCAKEPHP取得
    let cookieCakePhp = this.$cookies.get('CAKEPHP')

    // ccuid、user-agent、ip、refererをstoreにセット
    commit('auth/SET_CCUID', ccuid)
    commit('auth/SET_USER_AGENT', ua)
    commit('auth/SET_CLIENT_IP', ip)
    commit('auth/SET_INITIAL_REFERER', referer)
    // Webアクセス時にすべての経路でログに出力し、リクエストの導線を追えるようにする
    commit('auth/SET_AMZN_TRACE_ID', req.headers['x-amzn-trace-id'])
    if (cookieCakePhp) {
      commit('auth/SET_CAKEPHP', cookieCakePhp)
    }

    const sessionKey = this.$cookies.get(process.env.config['session-key'])
    const businessSessionKey = this.$cookies.get(process.env.config['session-key-business'])
    const privateSessionKey = this.$cookies.get(process.env.config['session-key-private'])
    const isLocal = !!req.headers.host.match(/^localhost/)
    const sessionMaxAge = 60 * 60 * 24 * 365 * 10 // 10年
    // NOTE: ビジネスアカウントからの個人アカウント新規作成時、CAKEでの登録ページ遷移からブラウザバックをしたかどうか
    // または、ビジネスアカウントへの個人アカウント連携時、CAKEでのログインページ遷移からブラウザバックをしたかどうか
    const isBrowserBackWhenPrivateAccountLinking =
      !!businessSessionKey &&
      !privateSessionKey &&
      businessSessionKey !== sessionKey &&
      (!!query.is_private_creation || !!this.$cookies.get('is_account_link_in_progress'))
    const promises = [
      new Promise(async resolve => {
        if (this.$ua.isFromPc()) return resolve()

        const onelinkUrlIdCookie = this.$cookies.get(ONELINK_URL_ID)
        const utmParams = pickOnelinkUtmParamsFromQuery(query)
        const appsFlyerKey =
          this.$cookies.get(MEASURING_APP_INSTALL) || this.$util.genAppsFlyerKeyCookieVal(query)
        if (!onelinkUrlIdCookie && !utmParams && !appsFlyerKey) return resolve()

        const { AppsFlyerUrlAccessor } = await import(
          '~/api/accessors/apps_flyer/apps-flyer-url-accessor'
        )
        const appsFlyerUrlAccessor = new AppsFlyerUrlAccessor(state)

        const cookieOptions = {
          path: '/',
          maxAge: 60 * 60 * 24 * 30 // 30日
        }

        if (onelinkUrlIdCookie || utmParams) {
          const { onelinkUrlId, appsFlyerUrl } = await appsFlyerUrlAccessor
            .getAppsFlyerUrlWithUtm({
              onelinkUrlId: onelinkUrlIdCookie,
              // nullの可能性があるのでケアする
              ...(utmParams || {})
            })
            .catch(_ => ({ onelinkUrlId: null, appsFlyerUrl: null }))

          // onelinkUrlId、 appsFlyerUrlがあったらcookieにonelinkUrlIdを入れる
          // appsFlyerUrlが変わる可能性があるためonelinkUrlIdを元に都度取得する
          if (onelinkUrlId && appsFlyerUrl) {
            this.$cookies.set(ONELINK_URL_ID, onelinkUrlId, cookieOptions)
            commit('auth/SET_APP_STORE_URL', appsFlyerUrl)
          }
          resolve()
          return
        }

        if (appsFlyerKey) {
          const { appsFlyerUrl } = await appsFlyerUrlAccessor
            .getAppsFlyerUrl(appsFlyerKey)
            .catch(_ => ({ appsFlyerUrl: null }))
          // getAppsFlyerUrlの戻り値があった場合, 有効期限を更新する
          if (appsFlyerUrl) {
            this.$cookies.set(MEASURING_APP_INSTALL, appsFlyerKey, cookieOptions)
          }
          commit('auth/SET_APP_STORE_URL', appsFlyerUrl)
        }
        resolve()
      }),
      new Promise((resolve, reject) => {
        const shouldDeleteSession = [
          '/business/register/invitation',
          '/business/register/email_verified',
          '/business/register/enter_information'
        ].includes(state.route.path)
        const token = (() => {
          if (shouldDeleteSession) {
            return null
          }
          // sessionキーのずれが発生した場合、本来のログインセッションをtokenに渡す
          if (isBrowserBackWhenPrivateAccountLinking) {
            return businessSessionKey
          }
          return sessionKey
        })()
        dispatch('auth/tryAuth', { token }, { root: true })
          .then(async isOk => {
            if (isOk) {
              await Promise.all([
                dispatch('auth/user/fetchAll'),
                dispatch('my/header/fetchAccountInfo')
              ])

              if (state.auth.user.hasFinishedEntryForm) {
                if (checkMzhcIdentityInProgress(state, route)) {
                  redirect('/register/identity_in_progress_mzhc')
                  return
                }

                resolve()
                return
              }

              // 会員登録途中離脱からログインして復帰した場合
              try {
                const [
                  { GetStatusRequest },
                  { EntryFormService },
                  { EntryFormStatus },
                  { toStringValue },
                  { getRedirectPath }
                ] = await Promise.all([
                  import('~/stub/gw/user/entry_form_service_pb'),
                  import('~/stub/gw/user/entry_form_service_pb_service'),
                  import('~/stub/domain/enumeration/entry_form_status_pb'),
                  import('~/grpc/grpc-util'),
                  import('~/components/logics/register')
                ])

                const getStatusRequest = new GetStatusRequest()

                // エントリーフォーム内での遷移時には、会員登録完了後のリダイレクト先URLを更新しない
                if (!route.fullPath.startsWith('/register/')) {
                  const absoluteUrl = this.$coconala.absoluteUrl(route.fullPath)
                  getStatusRequest.setRedirectUrl(toStringValue(absoluteUrl))
                }

                // 招待キーが存在する場合
                const enterpriseInvitationKey = this.$cookies.get(COOKIE_KEY_INVITATION_KEY)
                if (enterpriseInvitationKey) {
                  getStatusRequest.setEnterpriseInvitationKey(
                    toStringValue(enterpriseInvitationKey)
                  )
                }

                const entryForm = await grpcClient({
                  method: EntryFormService.GetStatus,
                  request: getStatusRequest,
                  metadata: commonMetaData(state),
                  strip: true
                })

                const { ccuid, type, status } = entryForm

                // 適切な画面にリダイレクトする
                let path = getRedirectPath(status)
                if (path.length && route.path !== path) {
                  redirect({ path, replace: true })
                  return
                }

                commit('register/updateCcuid', ccuid)
                commit('register/updateType', type)
              } catch {
                error({ statusCode: 403 })
                return
              }
            } else {
              // 未ログイン時の場合のみOpenID用の外部連携サービス利用状況を取得する。
              // (フェイルセーフのために例外発生時は握りつぶす)
              await dispatch('external_system/fetchExternalSystemStatuses', null, {
                root: true
              }).catch(() => {})
            }
            resolve()
          })
          .catch(reject)
      }),
      new Promise((resolve, reject) => {
        dispatch('master/fetchBasic', null, { root: true }).then(resolve).catch(reject)
      }),
      new Promise((resolve, reject) => {
        dispatch('pages/blogs/master/fetchBlogCategories', null, { root: true })
          .then(resolve)
          .catch(reject)
      })
    ]

    try {
      // NOTE: ビジネスアカウントからの個人アカウント新規作成時、CAKEでの登録ページ遷移からブラウザバックをした場合
      // sessionキーのずれが発生するため本来のログインセッションに書き換える。
      // business:○,private:×,coconala_session:△(ログインページ生成cookie) -> coconala_session = business
      if (isBrowserBackWhenPrivateAccountLinking) {
        this.$cookies.set(process.env.config['session-key'], businessSessionKey, {
          path: '/',
          domain: isLocal ? 'localhost' : '.coconala.com',
          httpOnly: true,
          secure: true,
          maxAge: sessionMaxAge
        })
        this.$cookies.set('CAKEPHP', businessSessionKey, {
          path: '/',
          domain: '',
          httpOnly: true,
          secure: true,
          maxAge: sessionMaxAge
        })
      }
      await Promise.all(promises)
      if (getters['auth/isLoggedIn']) {
        // SentryにユーザーIDを送る(SSR対応)
        this.$sentry.setUser({
          id: state.auth.user.id,
          ip_address: ip
        })

        // 種別に対応する個人/ビジネスのセッションキーがCookieに存在していない場合、メインのセッションキーの値をコピーして作成する
        if (state.auth.user.isBusiness && !businessSessionKey) {
          this.$cookies.set(process.env.config['session-key-business'], sessionKey, {
            path: '/',
            domain: isLocal ? 'localhost' : '.coconala.com',
            httpOnly: true,
            secure: true,
            maxAge: sessionMaxAge
          })
        }
        if (!state.auth.user.isBusiness && !privateSessionKey) {
          this.$cookies.set(process.env.config['session-key-private'], sessionKey, {
            path: '/',
            domain: isLocal ? 'localhost' : '.coconala.com',
            httpOnly: true,
            secure: true,
            maxAge: sessionMaxAge
          })
        }
      }
    } catch (grpcCode) {
      const statusCode = util.grpcCodeToHttpStatusCode(grpcCode)
      return error({
        statusCode,
        message: '',
        grpcCode
      })
    }

    /**
     * "uid" query に応じた処理（主にメール内リンク押下時、uidでメール送信対象アカウントが指定されるため）
     * 参考設計 https://welself.atlassian.net/wiki/spaces/CD/pages/2702049684
     * @returns nuxtServerInit内で後続処理をするかどうか
     */
    const redirectByUid = async () => {
      const requestedUserId = Number(query.uid)
      if (typeof requestedUserId !== 'number' || isNaN(requestedUserId)) {
        return true
      }
      if (state.auth.user?.id === requestedUserId) {
        return true
      }
      try {
        const [{ GetUserRequest }, { UserService }] = await Promise.all([
          import('~/stub/apigateway/profile/user_pb'),
          import('~/stub/apigateway/profile/user_pb_service')
        ])
        // 指定されたIDのアカウントが存在するかを確認するためにリクエストしている
        await grpcClient({
          method: UserService.GetUser,
          request: new GetUserRequest().setUserId(requestedUserId),
          metadata: commonMetaData(state)
        })
      } catch {
        error({ statusCode: 403 })
        return false
      }
      if (!getters['auth/isLoggedIn']) {
        return true
      }
      const accounts = [state.my.header.businessAccountInfo, state.my.header.privateAccountInfo]
      const indexOfAnotherAccount = accounts.findIndex(account => account?.isCurrent === false)
      const anotherAccount = accounts[indexOfAnotherAccount]
      const isAnotherAccountForPrivate = indexOfAnotherAccount === 1
      if (anotherAccount?.userId !== requestedUserId) {
        error({ statusCode: 403 })
        return false
      }
      if (!anotherAccount.isLogin) {
        return true
      }
      const queryKeysToBeKept = Object.keys(query).filter(key => key !== 'uid')
      const queryStringToBeKept = queryKeysToBeKept.length
        ? '?' + queryKeysToBeKept.map(key => `${key}=${query[key]}`).join('&')
        : ''
      redirect({
        name: 'switch_account',
        query: {
          switch_for: isAnotherAccountForPrivate ? '1' : '2',
          redirect_to: (route.path === '/switch_account' ? '/' : route.path) + queryStringToBeKept
        }
      })
      return false
    }

    const continuesNuxtServerInit = await redirectByUid()
    if (!continuesNuxtServerInit) {
      return
    }
  }
}

export const mutations = {}
