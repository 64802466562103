import grpcClient from '~/grpc/grpc-client'
import { commonMetaData } from '~/store/_helpers/common-helper'

// initial state
export const state = () => ({})

// getters
export const getters = {}

const setRequestInfo = async params => {
  const { AddRequestInformationRequest, AttachmentFiles, RequestSkill } = await import(
    '~/stub/apigateway/request/request_pb'
  )
  const requestInfo = new AddRequestInformationRequest()

  requestInfo.setTitle(params.requestTitle)
  requestInfo.setContent(params.content)
  requestInfo.setMasterCategoryId(params.masterCategoryId)
  requestInfo.setPriceMin(params.priceMin)
  requestInfo.setPriceMax(params.priceMax)
  requestInfo.setWithholdingTaxFlag(params.withholdingTaxFlag)
  requestInfo.setExpireDate(params.expireDate)
  requestInfo.setDeliveryDateYear(params.deliveryDateYear)
  requestInfo.setDeliveryDateMonth(params.deliveryDateMonth)
  requestInfo.setDeliveryDateDay(params.deliveryDateDay)

  const attachmentFiles = []
  if (params.fileAttachment) {
    Object.values(params.fileAttachment).forEach(file => {
      const wrapper = new AttachmentFiles()
      wrapper.setFileName(file.fileName)
      wrapper.setFileUrl(file.fileUrl)
      wrapper.setMime(file.mime)
      wrapper.setSize(file.size)
      attachmentFiles.push(wrapper)
    })
  }
  requestInfo.setAttachmentFilesList(attachmentFiles)

  requestInfo.setGenreList(params.genre)
  requestInfo.setGenreOther(params.genreOther)
  requestInfo.setReferenceUrl(params.referenceUrl)

  const requestSkills = params.requestSkills
    ? params.requestSkills.map(skill => {
        const requestSkill = new RequestSkill()
        requestSkill.setRequestSkill(skill)
        return requestSkill
      })
    : []
  requestInfo.setRequestSkillsList(requestSkills)
  requestInfo.setSpecialNoteList(params.specialNote)
  requestInfo.setCoverageList(params.coverage)
  requestInfo.setCoverageOther(params.coverageOther)
  requestInfo.setWorkCount(params.workCount)
  requestInfo.setWorkCountOther(params.workCountOther)
  requestInfo.setSize(params.size)
  requestInfo.setSizeOther(params.sizeOther)
  requestInfo.setDesiredColorImage(params.desiredColorImage)
  requestInfo.setDesiredColorImageOther(params.desiredColorImageOther)
  requestInfo.setDesiredDirectionDirectivity(params.desiredDirectionDirectivity)
  requestInfo.setDesiredDirectionColor(params.desiredDirectionColor)
  requestInfo.setDesiredDirectionDignity(params.desiredDirectionDignity)
  requestInfo.setDesiredDirectionGender(params.desiredDirectionGender)
  requestInfo.setFileTypeList(params.fileType)
  requestInfo.setPurposeTypeList(params.purposeType)
  requestInfo.setPurposeTypeOther(params.purposeTypeOther)
  requestInfo.setTargetAgeList(params.targetAge)
  requestInfo.setTargetAgeOther(params.targetAgeOther)
  requestInfo.setTargetSex(params.targetSex)
  requestInfo.setTargetSexOther(params.targetSexOther)
  requestInfo.setPreparedSomethingList(params.preparedSomething)
  requestInfo.setDevelopLanguageList(params.developLanguage)
  requestInfo.setDevelopLanguageOther(params.developLanguageOther)
  requestInfo.setWordCount(params.wordCount)
  requestInfo.setWordCountCheckList(params.wordCountCheck)
  requestInfo.setWordCountCheckOther(params.wordCountCheckOther)
  requestInfo.setBusinessType(params.businessType)
  requestInfo.setCharacterCount(params.characterCount)
  requestInfo.setArticlePrice(params.articlePrice)
  requestInfo.setArticlePriceCheckList(params.articlePriceCheck)
  requestInfo.setArticlePriceCheckOther(params.articlePriceCheckOther)
  requestInfo.setArticleCount(params.articleCount)
  requestInfo.setArticleCountCheckList(params.articleCountCheck)
  requestInfo.setArticleCountCheckOther(params.articleCountCheckOther)
  requestInfo.setPageTypeList(params.pageType)
  requestInfo.setPreparedMaterialList(params.preparedMaterial)
  requestInfo.setPreparedMaterialOther(params.preparedMaterialOther)
  requestInfo.setTargetJob(params.targetJob)
  requestInfo.setLogoName(params.logoName)
  requestInfo.setLogoImageList(params.logoImage)
  requestInfo.setRegisterTrademark(params.registerTrademark)
  requestInfo.setTimeCount(params.timeCount)
  requestInfo.setTimeCountCheckList(params.timeCountCheck)
  requestInfo.setTimeCountCheckOther(params.timeCountCheckOther)
  requestInfo.setAssignCopyright(params.assignCopyright)
  requestInfo.setSecondaryUse(params.secondaryUse)
  requestInfo.setStyle(params.style)
  requestInfo.setLanguage(params.language)
  requestInfo.setServiceTitleList(params.serviceTitle)
  requestInfo.setMediaList(params.media)
  requestInfo.setVoiceTypeList(params.voiceType)
  requestInfo.setIsBudgetSelected(params.isBudgetSelected)
  requestInfo.setFreeWordText(params.freeWordText)
  requestInfo.setTargetSexesList(params.targetSexes)
  requestInfo.setTargetJobsList(params.targetJobs)

  if (params.isDraft) requestInfo.setIsDraft(params.isDraft)

  return requestInfo
}

// actions
export const actions = {
  async addRequestInformation({ commit, rootState }, data) {
    const { RequestService } = await import('~/stub/apigateway/request/request_pb_service')
    const metadata = commonMetaData(rootState)
    const request = await setRequestInfo(data.params)

    return await grpcClient({
      method: RequestService.AddRequestInformation,
      request,
      metadata
    })
  },
  async updateRequestInformation({ commit, rootState }, data) {
    const [{ UpdateRequestInformationRequest }, { RequestService }] = await Promise.all([
      import('~/stub/apigateway/request/request_pb'),
      import('~/stub/apigateway/request/request_pb_service')
    ])
    const metadata = commonMetaData(rootState)
    const requestInfo = await setRequestInfo(data.params)

    const request = new UpdateRequestInformationRequest()
    request.setRequestId(data.requestId)
    request.setRequestInfo(requestInfo)

    return await grpcClient({
      method: RequestService.UpdateRequestInformation,
      request,
      metadata
    })
  }
}

// mutations
export const mutations = {}
