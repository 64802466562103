// 今後ここにパラメータを追加する場合は、インフラ側にallow_headersの追加をお願いする必要あり
export const commonMetaData = rootState => {
  return {
    'x-auth-token': rootState.auth.sToken ? [rootState.auth.sToken] : [],
    'x-ccuid': [rootState.auth.ccuid], // ログ出力に使う
    'x-user-id': [rootState.auth.user.id], // ログ出力に使う
    'x-user-agent': [rootState.auth.ua], // ログ出力に使う,
    'x-forwarded-for': [rootState.auth.ip], // ログ出力に使う
    'x-amzn-trace-id':
      rootState.auth.amznTraceId && process.server ? [rootState.auth.amznTraceId] : [], // ログ出力に使う
    'x-referer': [rootState.auth.initialReferer], // ログ出力に使う
    'x-is-business': [rootState.auth.user.isBusiness], // ログ出力に使う
    'x-lang-code': [rootState.translate.language] // APIレスポンスの言語指定
  }
}
