import util from '~/assets/javascripts/util'

export default function (to, from, savedPosition) {
  if (savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(savedPosition)
      }, 100)
    })
  } else if (to.query.y) {
    return { x: 0, y: util.castToNumberQueryString(to.query.y) }
  }
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({ x: 0, y: 0 })
    }, 100)
  })
}
