import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _54e9e886 = () => interopDefault(import('../pages/blogs/index.vue' /* webpackChunkName: "pages/blogs/index" */))
const _264b386a = () => interopDefault(import('../pages/business/index.vue' /* webpackChunkName: "pages/business/index" */))
const _57a45406 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _883c91f4 = () => interopDefault(import('../pages/healthy.vue' /* webpackChunkName: "pages/healthy" */))
const _29349af3 = () => interopDefault(import('../pages/message/index.vue' /* webpackChunkName: "pages/message/index" */))
const _0f646fad = () => interopDefault(import('../pages/mizuho_coconala/index.vue' /* webpackChunkName: "pages/mizuho_coconala/index" */))
const _1932a636 = () => interopDefault(import('../pages/mypage/index.vue' /* webpackChunkName: "pages/mypage/index" */))
const _0bfeb924 = () => interopDefault(import('../pages/requests/index.vue' /* webpackChunkName: "pages/requests/index" */))
const _7249b412 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _25dddb88 = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _06b48749 = () => interopDefault(import('../pages/switch_account.vue' /* webpackChunkName: "pages/switch_account" */))
const _9d2f6818 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _3bf34f04 = () => interopDefault(import('../pages/blogs/categories/index.vue' /* webpackChunkName: "pages/blogs/categories/index" */))
const _624c43e0 = () => interopDefault(import('../pages/blogs/search/index.vue' /* webpackChunkName: "pages/blogs/search/index" */))
const _0dc55dbf = () => interopDefault(import('../pages/business/continue.vue' /* webpackChunkName: "pages/business/continue" */))
const _db61de96 = () => interopDefault(import('../pages/business/introduction/index.vue' /* webpackChunkName: "pages/business/introduction/index" */))
const _91884dd6 = () => interopDefault(import('../pages/business/projects/index.vue' /* webpackChunkName: "pages/business/projects/index" */))
const _2c47e004 = () => interopDefault(import('../pages/business/register/index.vue' /* webpackChunkName: "pages/business/register/index" */))
const _39cfbd7e = () => interopDefault(import('../pages/business/settings/index.vue' /* webpackChunkName: "pages/business/settings/index" */))
const _543fd830 = () => interopDefault(import('../pages/business/top/index.vue' /* webpackChunkName: "pages/business/top/index" */))
const _7c861d1a = () => interopDefault(import('../pages/enterprise/invitations.vue' /* webpackChunkName: "pages/enterprise/invitations" */))
const _5ce705ca = () => interopDefault(import('../pages/enterprise/lp.vue' /* webpackChunkName: "pages/enterprise/lp" */))
const _04ffaf92 = () => interopDefault(import('../pages/enterprise/members.vue' /* webpackChunkName: "pages/enterprise/members" */))
const _75daa27c = () => interopDefault(import('../pages/enterprise/orders.vue' /* webpackChunkName: "pages/enterprise/orders" */))
const _16c667da = () => interopDefault(import('../pages/error/404.vue' /* webpackChunkName: "pages/error/404" */))
const _1aa8b64e = () => interopDefault(import('../pages/fortunetelling/horoscopes/index.vue' /* webpackChunkName: "pages/fortunetelling/horoscopes/index" */))
const _48807ba6 = () => interopDefault(import('../pages/job_matching/outsources/index.vue' /* webpackChunkName: "pages/job_matching/outsources/index" */))
const _6750e33b = () => interopDefault(import('../pages/job_matching/type_select/index.vue' /* webpackChunkName: "pages/job_matching/type_select/index" */))
const _a0c1afdc = () => interopDefault(import('../pages/lp/buyer/index.vue' /* webpackChunkName: "pages/lp/buyer/index" */))
const _c0313b70 = () => interopDefault(import('../pages/lp/provider/index.vue' /* webpackChunkName: "pages/lp/provider/index" */))
const _378fdc82 = () => interopDefault(import('../pages/mizuho_coconala/company_profile.vue' /* webpackChunkName: "pages/mizuho_coconala/company_profile" */))
const _53663fdc = () => interopDefault(import('../pages/mypage/analytics/index.vue' /* webpackChunkName: "pages/mypage/analytics/index" */))
const _c52ef690 = () => interopDefault(import('../pages/mypage/blog_orders/index.vue' /* webpackChunkName: "pages/mypage/blog_orders/index" */))
const _db7265b2 = () => interopDefault(import('../pages/mypage/blogs/index.vue' /* webpackChunkName: "pages/mypage/blogs/index" */))
const _0dd9cdf4 = () => interopDefault(import('../pages/mypage/bookmarks_requests/index.vue' /* webpackChunkName: "pages/mypage/bookmarks_requests/index" */))
const _4e9ea548 = () => interopDefault(import('../pages/mypage/code_apply/index.vue' /* webpackChunkName: "pages/mypage/code_apply/index" */))
const _24cc0827 = () => interopDefault(import('../pages/mypage/dashboard.vue' /* webpackChunkName: "pages/mypage/dashboard" */))
const _cfd7858e = () => interopDefault(import('../pages/mypage/dashboard_provider.vue' /* webpackChunkName: "pages/mypage/dashboard_provider" */))
const _439a30be = () => interopDefault(import('../pages/mypage/email_optout/index.vue' /* webpackChunkName: "pages/mypage/email_optout/index" */))
const _e28337ee = () => interopDefault(import('../pages/mypage/invite/index.vue' /* webpackChunkName: "pages/mypage/invite/index" */))
const _1a241598 = () => interopDefault(import('../pages/mypage/invoice_number.vue' /* webpackChunkName: "pages/mypage/invoice_number" */))
const _270d1fff = () => interopDefault(import('../pages/mypage/job_matching/index.vue' /* webpackChunkName: "pages/mypage/job_matching/index" */))
const _4bf9f995 = () => interopDefault(import('../pages/mypage/language.vue' /* webpackChunkName: "pages/mypage/language" */))
const _46840d17 = () => interopDefault(import('../pages/mypage/offers/index.vue' /* webpackChunkName: "pages/mypage/offers/index" */))
const _7da7dfe2 = () => interopDefault(import('../pages/mypage/orders.vue' /* webpackChunkName: "pages/mypage/orders" */))
const _37039a56 = () => interopDefault(import('../pages/mypage/orders/canceled.vue' /* webpackChunkName: "pages/mypage/orders/canceled" */))
const _76e80a2e = () => interopDefault(import('../pages/mypage/orders/closed.vue' /* webpackChunkName: "pages/mypage/orders/closed" */))
const _de1b4fcc = () => interopDefault(import('../pages/mypage/orders/flags.vue' /* webpackChunkName: "pages/mypage/orders/flags" */))
const _9056d3f2 = () => interopDefault(import('../pages/mypage/orders/open.vue' /* webpackChunkName: "pages/mypage/orders/open" */))
const _573a2ffe = () => interopDefault(import('../pages/mypage/orders/requests.vue' /* webpackChunkName: "pages/mypage/orders/requests" */))
const _24954e5c = () => interopDefault(import('../pages/mypage/orders/required.vue' /* webpackChunkName: "pages/mypage/orders/required" */))
const _2fd69c4b = () => interopDefault(import('../pages/mypage/orders/trash.vue' /* webpackChunkName: "pages/mypage/orders/trash" */))
const _a260be8e = () => interopDefault(import('../pages/mypage/promotion/index.vue' /* webpackChunkName: "pages/mypage/promotion/index" */))
const _72fa1002 = () => interopDefault(import('../pages/mypage/provider_coupons/index.vue' /* webpackChunkName: "pages/mypage/provider_coupons/index" */))
const _cd9efa0e = () => interopDefault(import('../pages/mypage/received_favorites/index.vue' /* webpackChunkName: "pages/mypage/received_favorites/index" */))
const _3fa343ea = () => interopDefault(import('../pages/mypage/received_favorites_blogs/index.vue' /* webpackChunkName: "pages/mypage/received_favorites_blogs/index" */))
const _87e8c37c = () => interopDefault(import('../pages/mypage/received_favorites_users/index.vue' /* webpackChunkName: "pages/mypage/received_favorites_users/index" */))
const _40ffa654 = () => interopDefault(import('../pages/mypage/received_orders.vue' /* webpackChunkName: "pages/mypage/received_orders" */))
const _0bc09fe2 = () => interopDefault(import('../pages/mypage/received_orders/canceled.vue' /* webpackChunkName: "pages/mypage/received_orders/canceled" */))
const _08b61916 = () => interopDefault(import('../pages/mypage/received_orders/closed.vue' /* webpackChunkName: "pages/mypage/received_orders/closed" */))
const _145bc3e4 = () => interopDefault(import('../pages/mypage/received_orders/flags.vue' /* webpackChunkName: "pages/mypage/received_orders/flags" */))
const _0daa4193 = () => interopDefault(import('../pages/mypage/received_orders/open.vue' /* webpackChunkName: "pages/mypage/received_orders/open" */))
const _adc024e6 = () => interopDefault(import('../pages/mypage/received_orders/requests.vue' /* webpackChunkName: "pages/mypage/received_orders/requests" */))
const _0d5b5830 = () => interopDefault(import('../pages/mypage/received_orders/required.vue' /* webpackChunkName: "pages/mypage/received_orders/required" */))
const _d6933b82 = () => interopDefault(import('../pages/mypage/received_orders/trash.vue' /* webpackChunkName: "pages/mypage/received_orders/trash" */))
const _2798efea = () => interopDefault(import('../pages/mypage/received_orders/user/_userId.vue' /* webpackChunkName: "pages/mypage/received_orders/user/_userId" */))
const _460e5e2f = () => interopDefault(import('../pages/mypage/sms/index.vue' /* webpackChunkName: "pages/mypage/sms/index" */))
const _3bbec8ea = () => interopDefault(import('../pages/mypage/user/index.vue' /* webpackChunkName: "pages/mypage/user/index" */))
const _65e7e8f8 = () => interopDefault(import('../pages/mypage/user_identification/index.vue' /* webpackChunkName: "pages/mypage/user_identification/index" */))
const _0025e4cc = () => interopDefault(import('../pages/mypage/user_interest_categories.vue' /* webpackChunkName: "pages/mypage/user_interest_categories" */))
const _c1b406a2 = () => interopDefault(import('../pages/optout/email_blmr.vue' /* webpackChunkName: "pages/optout/email_blmr" */))
const _2f62c9dc = () => interopDefault(import('../pages/orders/post_pay.vue' /* webpackChunkName: "pages/orders/post_pay" */))
const _67cfd7eb = () => interopDefault(import('../pages/pages/about.vue' /* webpackChunkName: "pages/pages/about" */))
const _07e9d208 = () => interopDefault(import('../pages/pages/consent_form_mzhc.vue' /* webpackChunkName: "pages/pages/consent_form_mzhc" */))
const _dc207fe4 = () => interopDefault(import('../pages/pages/cookie_policy/index.vue' /* webpackChunkName: "pages/pages/cookie_policy/index" */))
const _413f79c9 = () => interopDefault(import('../pages/pages/terms_promotion.vue' /* webpackChunkName: "pages/pages/terms_promotion" */))
const _e645639c = () => interopDefault(import('../pages/pages/tokushou.vue' /* webpackChunkName: "pages/pages/tokushou" */))
const _31ebe805 = () => interopDefault(import('../pages/pages/tokushou_mzhc.vue' /* webpackChunkName: "pages/pages/tokushou_mzhc" */))
const _84faacae = () => interopDefault(import('../pages/points/record/index.vue' /* webpackChunkName: "pages/points/record/index" */))
const _4a8f73d3 = () => interopDefault(import('../pages/points/top/index.vue' /* webpackChunkName: "pages/points/top/index" */))
const _732d2d20 = () => interopDefault(import('../pages/questionnaire/user_interest_categories.vue' /* webpackChunkName: "pages/questionnaire/user_interest_categories" */))
const _d352255c = () => interopDefault(import('../pages/register/buyer_provider.vue' /* webpackChunkName: "pages/register/buyer_provider" */))
const _434c4dbc = () => interopDefault(import('../pages/register/identity_in_progress_mzhc.vue' /* webpackChunkName: "pages/register/identity_in_progress_mzhc" */))
const _44f6b458 = () => interopDefault(import('../pages/register/individual_corporate.vue' /* webpackChunkName: "pages/register/individual_corporate" */))
const _7ba13350 = () => interopDefault(import('../pages/register/top.vue' /* webpackChunkName: "pages/register/top" */))
const _03fef4b2 = () => interopDefault(import('../pages/register/top_mzhc.vue' /* webpackChunkName: "pages/register/top_mzhc" */))
const _5fe0e4bd = () => interopDefault(import('../pages/requests/add.vue' /* webpackChunkName: "pages/requests/add" */))
const _b8116e4a = () => interopDefault(import('../pages/requests/recommend/index.vue' /* webpackChunkName: "pages/requests/recommend/index" */))
const _26157c0e = () => interopDefault(import('../pages/users/search/index.vue' /* webpackChunkName: "pages/users/search/index" */))
const _43d7361e = () => interopDefault(import('../pages/blogs/orders/redirect.vue' /* webpackChunkName: "pages/blogs/orders/redirect" */))
const _f3ef2824 = () => interopDefault(import('../pages/business/questionnaire/completed.ts' /* webpackChunkName: "pages/business/questionnaire/completed" */))
const _204b0211 = () => interopDefault(import('../pages/business/questionnaire/corporation.ts' /* webpackChunkName: "pages/business/questionnaire/corporation" */))
const _19ff43b6 = () => interopDefault(import('../pages/business/questionnaire/genre.ts' /* webpackChunkName: "pages/business/questionnaire/genre" */))
const _ed9575d4 = () => interopDefault(import('../pages/business/questionnaire/user.ts' /* webpackChunkName: "pages/business/questionnaire/user" */))
const _277650bf = () => interopDefault(import('../pages/business/register/account_expired.vue' /* webpackChunkName: "pages/business/register/account_expired" */))
const _fbb8e212 = () => interopDefault(import('../pages/business/register/completed.vue' /* webpackChunkName: "pages/business/register/completed" */))
const _413b78ae = () => interopDefault(import('../pages/business/register/email_expired.vue' /* webpackChunkName: "pages/business/register/email_expired" */))
const _90a1fe54 = () => interopDefault(import('../pages/business/register/email_verified.ts' /* webpackChunkName: "pages/business/register/email_verified" */))
const _f9dbaec6 = () => interopDefault(import('../pages/business/register/enter_business_email.ts' /* webpackChunkName: "pages/business/register/enter_business_email" */))
const _7686de98 = () => interopDefault(import('../pages/business/register/enter_information.ts' /* webpackChunkName: "pages/business/register/enter_information" */))
const _65d43bb3 = () => interopDefault(import('../pages/business/register/enter_open_id_email.ts' /* webpackChunkName: "pages/business/register/enter_open_id_email" */))
const _602cb2c0 = () => interopDefault(import('../pages/business/register/enter_private_email.ts' /* webpackChunkName: "pages/business/register/enter_private_email" */))
const _e60db6c6 = () => interopDefault(import('../pages/business/register/invitation.vue' /* webpackChunkName: "pages/business/register/invitation" */))
const _25185566 = () => interopDefault(import('../pages/business/register/invitation_error.vue' /* webpackChunkName: "pages/business/register/invitation_error" */))
const _549d2974 = () => interopDefault(import('../pages/business/register/migration_completed.vue' /* webpackChunkName: "pages/business/register/migration_completed" */))
const _beb9fba4 = () => interopDefault(import('../pages/business/register/migration_description.ts' /* webpackChunkName: "pages/business/register/migration_description" */))
const _5d632831 = () => interopDefault(import('../pages/business/register/select_account_existence.vue' /* webpackChunkName: "pages/business/register/select_account_existence" */))
const _13953c3f = () => interopDefault(import('../pages/business/register/select_account_pattern.vue' /* webpackChunkName: "pages/business/register/select_account_pattern" */))
const _612b589d = () => interopDefault(import('../pages/business/register/select_email.vue' /* webpackChunkName: "pages/business/register/select_email" */))
const _222fd174 = () => interopDefault(import('../pages/business/register/select_id_type.ts' /* webpackChunkName: "pages/business/register/select_id_type" */))
const _30819bb3 = () => interopDefault(import('../pages/business/register/sending_completed.ts' /* webpackChunkName: "pages/business/register/sending_completed" */))
const _13a59428 = () => interopDefault(import('../pages/business/register/welcome_back.vue' /* webpackChunkName: "pages/business/register/welcome_back" */))
const _27578459 = () => interopDefault(import('../pages/business/settings/account.vue' /* webpackChunkName: "pages/business/settings/account" */))
const _39460ab4 = () => interopDefault(import('../pages/business/settings/corporation.vue' /* webpackChunkName: "pages/business/settings/corporation" */))
const _017e95c6 = () => interopDefault(import('../pages/business/settings/delete_account.vue' /* webpackChunkName: "pages/business/settings/delete_account" */))
const _7f54854e = () => interopDefault(import('../pages/business/settings/invoice.vue' /* webpackChunkName: "pages/business/settings/invoice" */))
const _1480734f = () => interopDefault(import('../pages/business/settings/user.vue' /* webpackChunkName: "pages/business/settings/user" */))
const _28821687 = () => interopDefault(import('../pages/callBack/blog/mobileCarriers.vue' /* webpackChunkName: "pages/callBack/blog/mobileCarriers" */))
const _670a4769 = () => interopDefault(import('../pages/campaign/participations/15.vue' /* webpackChunkName: "pages/campaign/participations/15" */))
const _67185eea = () => interopDefault(import('../pages/campaign/participations/16.vue' /* webpackChunkName: "pages/campaign/participations/16" */))
const _4139a3e4 = () => interopDefault(import('../pages/fortunetelling/horoscopes/constants.js' /* webpackChunkName: "pages/fortunetelling/horoscopes/constants" */))
const _045525b8 = () => interopDefault(import('../pages/job_matching/outsources/add/index.vue' /* webpackChunkName: "pages/job_matching/outsources/add/index" */))
const _12d3916b = () => interopDefault(import('../pages/lp/buyer/onboarding-complete.vue' /* webpackChunkName: "pages/lp/buyer/onboarding-complete" */))
const _9b361208 = () => interopDefault(import('../pages/lp/buyer/recommend.vue' /* webpackChunkName: "pages/lp/buyer/recommend" */))
const _e11295be = () => interopDefault(import('../pages/lp/provider/onboarding-complete.vue' /* webpackChunkName: "pages/lp/provider/onboarding-complete" */))
const _1fe843e4 = () => interopDefault(import('../pages/lp/provider/onboarding-complete-other.vue' /* webpackChunkName: "pages/lp/provider/onboarding-complete-other" */))
const _72f92632 = () => interopDefault(import('../pages/lp/provider/recommend.vue' /* webpackChunkName: "pages/lp/provider/recommend" */))
const _830e47ba = () => interopDefault(import('../pages/mypage/activities/notification/index.vue' /* webpackChunkName: "pages/mypage/activities/notification/index" */))
const _cae39208 = () => interopDefault(import('../pages/mypage/activities/transaction/index.vue' /* webpackChunkName: "pages/mypage/activities/transaction/index" */))
const _1ce828b6 = () => interopDefault(import('../pages/mypage/blogs/add.vue' /* webpackChunkName: "pages/mypage/blogs/add" */))
const _d671d0a2 = () => interopDefault(import('../pages/mypage/favorites/blogs/index.vue' /* webpackChunkName: "pages/mypage/favorites/blogs/index" */))
const _08ba7690 = () => interopDefault(import('../pages/mypage/job_matching/outsources/index.vue' /* webpackChunkName: "pages/mypage/job_matching/outsources/index" */))
const _6bb1dbea = () => interopDefault(import('../pages/mypage/job_matching/requests/index.vue' /* webpackChunkName: "pages/mypage/job_matching/requests/index" */))
const _2c5a0c02 = () => interopDefault(import('../pages/mypage/promotion/payment_history.vue' /* webpackChunkName: "pages/mypage/promotion/payment_history" */))
const _c9813cbe = () => interopDefault(import('../pages/mypage/promotion/refund.vue' /* webpackChunkName: "pages/mypage/promotion/refund" */))
const _270277da = () => interopDefault(import('../pages/mypage/promotion/start_setting.vue' /* webpackChunkName: "pages/mypage/promotion/start_setting" */))
const _0b61298e = () => interopDefault(import('../pages/mypage/provider_coupons/add.vue' /* webpackChunkName: "pages/mypage/provider_coupons/add" */))
const _36bb285e = () => interopDefault(import('../pages/mypage/reservation/calendar.vue' /* webpackChunkName: "pages/mypage/reservation/calendar" */))
const _505f6e75 = () => interopDefault(import('../pages/mypage/reservation/reservationMockData.ts' /* webpackChunkName: "pages/mypage/reservation/reservationMockData" */))
const _772528b4 = () => interopDefault(import('../pages/mypage/user_identification/completed/index.vue' /* webpackChunkName: "pages/mypage/user_identification/completed/index" */))
const _4fa7644d = () => interopDefault(import('../pages/mypage/user/awards.vue' /* webpackChunkName: "pages/mypage/user/awards" */))
const _619af9b1 = () => interopDefault(import('../pages/mypage/user/careers/index.vue' /* webpackChunkName: "pages/mypage/user/careers/index" */))
const _05f0e0cf = () => interopDefault(import('../pages/mypage/user/conditions.vue' /* webpackChunkName: "pages/mypage/user/conditions" */))
const _151e4592 = () => interopDefault(import('../pages/mypage/user/educational_backgrounds.vue' /* webpackChunkName: "pages/mypage/user/educational_backgrounds" */))
const _ef1d2e28 = () => interopDefault(import('../pages/mypage/user/licenses/index.vue' /* webpackChunkName: "pages/mypage/user/licenses/index" */))
const _25065cc2 = () => interopDefault(import('../pages/mypage/user/portfolios.vue' /* webpackChunkName: "pages/mypage/user/portfolios" */))
const _9fd26c76 = () => interopDefault(import('../pages/mypage/user/portfolios/index.vue' /* webpackChunkName: "pages/mypage/user/portfolios/index" */))
const _70c37b42 = () => interopDefault(import('../pages/mypage/user/resumes.vue' /* webpackChunkName: "pages/mypage/user/resumes" */))
const _ce32264e = () => interopDefault(import('../pages/mypage/user/skills.vue' /* webpackChunkName: "pages/mypage/user/skills" */))
const _481fb797 = () => interopDefault(import('../pages/mypage/user/tools/index.vue' /* webpackChunkName: "pages/mypage/user/tools/index" */))
const _4e250212 = () => interopDefault(import('../pages/pages/cookie_policy/list.vue' /* webpackChunkName: "pages/pages/cookie_policy/list" */))
const _a1e27e42 = () => interopDefault(import('../pages/update_registration_info/buyer/corporate.vue' /* webpackChunkName: "pages/update_registration_info/buyer/corporate" */))
const _490f2989 = () => interopDefault(import('../pages/update_registration_info/provider/working_condition.vue' /* webpackChunkName: "pages/update_registration_info/provider/working_condition" */))
const _20b07bc5 = () => interopDefault(import('../pages/business/register/invitation_link/error.vue' /* webpackChunkName: "pages/business/register/invitation_link/error" */))
const _2eeb5ee3 = () => interopDefault(import('../pages/business/register/invitation_link/sending_completed.vue' /* webpackChunkName: "pages/business/register/invitation_link/sending_completed" */))
const _5d01c90f = () => interopDefault(import('../pages/mypage/job_matching/applied/offers/index.vue' /* webpackChunkName: "pages/mypage/job_matching/applied/offers/index" */))
const _030a309e = () => interopDefault(import('../pages/mypage/job_matching/applied/outsource_applications/index.vue' /* webpackChunkName: "pages/mypage/job_matching/applied/outsource_applications/index" */))
const _14d0b5ad = () => interopDefault(import('../pages/mypage/job_matching/bookmarks/request/index.vue' /* webpackChunkName: "pages/mypage/job_matching/bookmarks/request/index" */))
const _1361f32e = () => interopDefault(import('../pages/mypage/job_matching/outsources/types.ts' /* webpackChunkName: "pages/mypage/job_matching/outsources/types" */))
const _3c601b74 = () => interopDefault(import('../pages/mypage/user/careers/old.vue' /* webpackChunkName: "pages/mypage/user/careers/old" */))
const _705ef441 = () => interopDefault(import('../pages/mypage/user/licenses/old.vue' /* webpackChunkName: "pages/mypage/user/licenses/old" */))
const _83d21ca8 = () => interopDefault(import('../pages/mypage/user/tools/old.vue' /* webpackChunkName: "pages/mypage/user/tools/old" */))
const _52dd2660 = () => interopDefault(import('../pages/register/buyer/corporate/buyer_plan.vue' /* webpackChunkName: "pages/register/buyer/corporate/buyer_plan" */))
const _3ee10061 = () => interopDefault(import('../pages/register/buyer/corporate/buyer_type.vue' /* webpackChunkName: "pages/register/buyer/corporate/buyer_type" */))
const _1918a19a = () => interopDefault(import('../pages/register/buyer/corporate/corporate.vue' /* webpackChunkName: "pages/register/buyer/corporate/corporate" */))
const _62a619b2 = () => interopDefault(import('../pages/register/provider/corporate/corporate.vue' /* webpackChunkName: "pages/register/provider/corporate/corporate" */))
const _7dcbccd7 = () => interopDefault(import('../pages/register/provider/individual/address.vue' /* webpackChunkName: "pages/register/provider/individual/address" */))
const _8584a38c = () => interopDefault(import('../pages/mypage/job_matching/applied/outsource_applications/_outsourceApplicationUlid/index.vue' /* webpackChunkName: "pages/mypage/job_matching/applied/outsource_applications/_outsourceApplicationUlid/index" */))
const _4ba1a812 = () => interopDefault(import('../pages/mypage/job_matching/applied/outsource_applications/_outsourceApplicationUlid/talkroom/index.vue' /* webpackChunkName: "pages/mypage/job_matching/applied/outsource_applications/_outsourceApplicationUlid/talkroom/index" */))
const _c867635e = () => interopDefault(import('../pages/business/register/invitation_link/_linkId.vue' /* webpackChunkName: "pages/business/register/invitation_link/_linkId" */))
const _549adc3c = () => interopDefault(import('../pages/customize/requests/add/_userId/index.vue' /* webpackChunkName: "pages/customize/requests/add/_userId/index" */))
const _06ee3606 = () => interopDefault(import('../pages/job_matching/outsources/jobs/_parentJobCategoryId/index.vue' /* webpackChunkName: "pages/job_matching/outsources/jobs/_parentJobCategoryId/index" */))
const _4c9db870 = () => interopDefault(import('../pages/mypage/blogs/edit/_id.vue' /* webpackChunkName: "pages/mypage/blogs/edit/_id" */))
const _76c091a2 = () => interopDefault(import('../pages/mypage/coupons/provider_coupons/_couponId.vue' /* webpackChunkName: "pages/mypage/coupons/provider_coupons/_couponId" */))
const _1955c97b = () => interopDefault(import('../pages/mypage/job_matching/outsources/_id/index.vue' /* webpackChunkName: "pages/mypage/job_matching/outsources/_id/index" */))
const _a6468990 = () => interopDefault(import('../pages/mypage/customize/requests/_id/detail.vue' /* webpackChunkName: "pages/mypage/customize/requests/_id/detail" */))
const _123fec34 = () => interopDefault(import('../pages/mypage/job_matching/outsources/_id/edit/index.vue' /* webpackChunkName: "pages/mypage/job_matching/outsources/_id/edit/index" */))
const _3f610458 = () => interopDefault(import('../pages/mypage/ordermade/requests/_id/detail.vue' /* webpackChunkName: "pages/mypage/ordermade/requests/_id/detail" */))
const _26b88214 = () => interopDefault(import('../pages/mypage/job_matching/outsources/_outsourceUlid/outsource_applications/_outsourceApplicationUlid/index.vue' /* webpackChunkName: "pages/mypage/job_matching/outsources/_outsourceUlid/outsource_applications/_outsourceApplicationUlid/index" */))
const _0bfcaad6 = () => interopDefault(import('../pages/mypage/job_matching/outsources/_outsourceUlid/outsource_applications/_outsourceApplicationUlid/talkroom/index.vue' /* webpackChunkName: "pages/mypage/job_matching/outsources/_outsourceUlid/outsource_applications/_outsourceApplicationUlid/talkroom/index" */))
const _5c041ed1 = () => interopDefault(import('../pages/customize/requests/add/_userId/_serviceId.vue' /* webpackChunkName: "pages/customize/requests/add/_userId/_serviceId" */))
const _f76c5198 = () => interopDefault(import('../pages/mypage/promotion/receipt/_year/_month.vue' /* webpackChunkName: "pages/mypage/promotion/receipt/_year/_month" */))
const _75d5b982 = () => interopDefault(import('../pages/mypage/user_identification/completed/_id/_device.vue' /* webpackChunkName: "pages/mypage/user_identification/completed/_id/_device" */))
const _741b1fcc = () => interopDefault(import('../pages/blogs/categories/_blogCategoryId.vue' /* webpackChunkName: "pages/blogs/categories/_blogCategoryId" */))
const _ea77f044 = () => interopDefault(import('../pages/blogs/ss/_hashId.vue' /* webpackChunkName: "pages/blogs/ss/_hashId" */))
const _14a71cbd = () => interopDefault(import('../pages/business/projects/_id.vue' /* webpackChunkName: "pages/business/projects/_id" */))
const _68792f4e = () => interopDefault(import('../pages/campaign/coupons/_acquisitionCouponId.vue' /* webpackChunkName: "pages/campaign/coupons/_acquisitionCouponId" */))
const _5b43356b = () => interopDefault(import('../pages/campaign/participations/_campaignEventId.vue' /* webpackChunkName: "pages/campaign/participations/_campaignEventId" */))
const _0455bf8d = () => interopDefault(import('../pages/forms/delivery/_talkroomId.vue' /* webpackChunkName: "pages/forms/delivery/_talkroomId" */))
const _6bdb98e8 = () => interopDefault(import('../pages/forms/estimate/_offerId.vue' /* webpackChunkName: "pages/forms/estimate/_offerId" */))
const _656f092e = () => interopDefault(import('../pages/forms/order/_talkroomId.vue' /* webpackChunkName: "pages/forms/order/_talkroomId" */))
const _d2239462 = () => interopDefault(import('../pages/fortunetelling/horoscopes/_zodiacSignId.vue' /* webpackChunkName: "pages/fortunetelling/horoscopes/_zodiacSignId" */))
const _640763db = () => interopDefault(import('../pages/job_matching/outsources/_outsourceUlid/index.vue' /* webpackChunkName: "pages/job_matching/outsources/_outsourceUlid/index" */))
const _37fa8944 = () => interopDefault(import('../pages/mypage/analytics/_id.vue' /* webpackChunkName: "pages/mypage/analytics/_id" */))
const _325219d2 = () => interopDefault(import('../pages/mypage/blog_orders/_blogOrderId.vue' /* webpackChunkName: "pages/mypage/blog_orders/_blogOrderId" */))
const _1c02d0a0 = () => interopDefault(import('../pages/mypage/provider_coupons/_couponId/index.vue' /* webpackChunkName: "pages/mypage/provider_coupons/_couponId/index" */))
const _6080681f = () => interopDefault(import('../pages/register/auth_mzhc/_preAuthHash.vue' /* webpackChunkName: "pages/register/auth_mzhc/_preAuthHash" */))
const _4b54aec8 = () => interopDefault(import('../pages/register/auth/_preAuthHash.vue' /* webpackChunkName: "pages/register/auth/_preAuthHash" */))
const _2c6fe70e = () => interopDefault(import('../pages/requests/categories/_categoryId.vue' /* webpackChunkName: "pages/requests/categories/_categoryId" */))
const _015223d2 = () => interopDefault(import('../pages/videos/message/_directMessagesRoomId.vue' /* webpackChunkName: "pages/videos/message/_directMessagesRoomId" */))
const _26ba7f64 = () => interopDefault(import('../pages/customize/requests/_id/detail.vue' /* webpackChunkName: "pages/customize/requests/_id/detail" */))
const _4e3721ba = () => interopDefault(import('../pages/job_matching/outsources/_outsourceUlid/apply/index.vue' /* webpackChunkName: "pages/job_matching/outsources/_outsourceUlid/apply/index" */))
const _214c2809 = () => interopDefault(import('../pages/mypage/blog_charges/_blogChargeId/receipt.vue' /* webpackChunkName: "pages/mypage/blog_charges/_blogChargeId/receipt" */))
const _0908ebf2 = () => interopDefault(import('../pages/mypage/services/_serviceId/body_suggestion.vue' /* webpackChunkName: "pages/mypage/services/_serviceId/body_suggestion" */))
const _7f27096e = () => interopDefault(import('../pages/ordermade/requests/_id/detail.vue' /* webpackChunkName: "pages/ordermade/requests/_id/detail" */))
const _3d76780a = () => interopDefault(import('../pages/register/buyer/_entity/user_interest_categories.vue' /* webpackChunkName: "pages/register/buyer/_entity/user_interest_categories" */))
const _664311dc = () => interopDefault(import('../pages/register/provider/_entity/jobs.vue' /* webpackChunkName: "pages/register/provider/_entity/jobs" */))
const _3156e59c = () => interopDefault(import('../pages/register/provider/_entity/provider_type.vue' /* webpackChunkName: "pages/register/provider/_entity/provider_type" */))
const _344e0028 = () => interopDefault(import('../pages/blogs/_id/index.vue' /* webpackChunkName: "pages/blogs/_id/index" */))
const _0940d50f = () => interopDefault(import('../pages/categories/_categoryId/index.vue' /* webpackChunkName: "pages/categories/_categoryId/index" */))
const _02c6ad54 = () => interopDefault(import('../pages/requests/_id.vue' /* webpackChunkName: "pages/requests/_id" */))
const _7bcdf273 = () => interopDefault(import('../pages/services/_id/index.vue' /* webpackChunkName: "pages/services/_id/index" */))
const _232d4ff2 = () => interopDefault(import('../pages/talkrooms/_talkroomId.vue' /* webpackChunkName: "pages/talkrooms/_talkroomId" */))
const _661f7d4a = () => interopDefault(import('../pages/talkrooms/_talkroomId/index.vue' /* webpackChunkName: "pages/talkrooms/_talkroomId/index" */))
const _ce9d36b8 = () => interopDefault(import('../pages/talkrooms/_talkroomId/information.vue' /* webpackChunkName: "pages/talkrooms/_talkroomId/information" */))
const _d7e37154 = () => interopDefault(import('../pages/talkrooms/_talkroomId/app/reservation.vue' /* webpackChunkName: "pages/talkrooms/_talkroomId/app/reservation" */))
const _62924cdf = () => interopDefault(import('../pages/users/_id/index.vue' /* webpackChunkName: "pages/users/_id/index" */))
const _5dc6fc11 = () => interopDefault(import('../pages/videos/_talkroomId.vue' /* webpackChunkName: "pages/videos/_talkroomId" */))
const _c532382a = () => interopDefault(import('../pages/message/_directMessagesRoomId/report/index.vue' /* webpackChunkName: "pages/message/_directMessagesRoomId/report/index" */))
const _133b4160 = () => interopDefault(import('../pages/message/_directMessagesRoomId/videos_application/index.vue' /* webpackChunkName: "pages/message/_directMessagesRoomId/videos_application/index" */))
const _f7ebd4a6 = () => interopDefault(import('../pages/services/_id/reservation.vue' /* webpackChunkName: "pages/services/_id/reservation" */))
const _76facbfc = () => interopDefault(import('../pages/users/_id/careers.vue' /* webpackChunkName: "pages/users/_id/careers" */))
const _475b4024 = () => interopDefault(import('../pages/users/_id/portfolios.vue' /* webpackChunkName: "pages/users/_id/portfolios" */))
const _eeb2881e = () => interopDefault(import('../pages/users/_id/portfolios/index.vue' /* webpackChunkName: "pages/users/_id/portfolios/index" */))
const _2ce7dbf5 = () => interopDefault(import('../pages/users/_id/portfolios/_portfolioId.vue' /* webpackChunkName: "pages/users/_id/portfolios/_portfolioId" */))
const _482d8268 = () => interopDefault(import('../pages/users/_id/reviews.vue' /* webpackChunkName: "pages/users/_id/reviews" */))
const _364fad3e = () => interopDefault(import('../pages/users/_id/services.vue' /* webpackChunkName: "pages/users/_id/services" */))
const _26ab6605 = () => interopDefault(import('../pages/users/_id/skills.vue' /* webpackChunkName: "pages/users/_id/skills" */))
const _6f08d052 = () => interopDefault(import('../pages/blogs/_id/_blogId.vue' /* webpackChunkName: "pages/blogs/_id/_blogId" */))
const _7bf0f6f4 = () => interopDefault(import('../pages/blogs/_id/_blogId/complete.vue' /* webpackChunkName: "pages/blogs/_id/_blogId/complete" */))
const _55515983 = () => interopDefault(import('../pages/blogs/_id/_blogId/confirm.vue' /* webpackChunkName: "pages/blogs/_id/_blogId/confirm" */))
const _96cf7766 = () => interopDefault(import('../pages/blogs/_id/_blogId/input.vue' /* webpackChunkName: "pages/blogs/_id/_blogId/input" */))
const _0d600bf2 = () => interopDefault(import('../pages/categories/_categoryId/_categoryTypeId/index.vue' /* webpackChunkName: "pages/categories/_categoryId/_categoryTypeId/index" */))
const _7694feac = () => interopDefault(import('../pages/register/_purpose/_entity/index.vue' /* webpackChunkName: "pages/register/_purpose/_entity/index" */))
const _3c92f052 = () => interopDefault(import('../pages/register/_purpose/_entity/user_name.vue' /* webpackChunkName: "pages/register/_purpose/_entity/user_name" */))
const _140b13fb = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blogs",
    component: _54e9e886,
    name: "blogs"
  }, {
    path: "/business",
    component: _264b386a,
    name: "business"
  }, {
    path: "/categories",
    component: _57a45406,
    name: "categories"
  }, {
    path: "/healthy",
    component: _883c91f4,
    name: "healthy"
  }, {
    path: "/message",
    component: _29349af3,
    name: "message"
  }, {
    path: "/mizuho_coconala",
    component: _0f646fad,
    name: "mizuho_coconala"
  }, {
    path: "/mypage",
    component: _1932a636,
    name: "mypage"
  }, {
    path: "/requests",
    component: _0bfeb924,
    name: "requests"
  }, {
    path: "/search",
    component: _7249b412,
    name: "search"
  }, {
    path: "/services",
    component: _25dddb88,
    name: "services"
  }, {
    path: "/switch_account",
    component: _06b48749,
    name: "switch_account"
  }, {
    path: "/users",
    component: _9d2f6818,
    name: "users"
  }, {
    path: "/blogs/categories",
    component: _3bf34f04,
    name: "blogs-categories"
  }, {
    path: "/blogs/search",
    component: _624c43e0,
    name: "blogs-search"
  }, {
    path: "/business/continue",
    component: _0dc55dbf,
    name: "business-continue"
  }, {
    path: "/business/introduction",
    component: _db61de96,
    name: "business-introduction"
  }, {
    path: "/business/projects",
    component: _91884dd6,
    name: "business-projects"
  }, {
    path: "/business/register",
    component: _2c47e004,
    name: "business-register"
  }, {
    path: "/business/settings",
    component: _39cfbd7e,
    name: "business-settings"
  }, {
    path: "/business/top",
    component: _543fd830,
    name: "business-top"
  }, {
    path: "/enterprise/invitations",
    component: _7c861d1a,
    name: "enterprise-invitations"
  }, {
    path: "/enterprise/lp",
    component: _5ce705ca,
    name: "enterprise-lp"
  }, {
    path: "/enterprise/members",
    component: _04ffaf92,
    name: "enterprise-members"
  }, {
    path: "/enterprise/orders",
    component: _75daa27c,
    name: "enterprise-orders"
  }, {
    path: "/error/404",
    component: _16c667da,
    name: "error-404"
  }, {
    path: "/fortunetelling/horoscopes",
    component: _1aa8b64e,
    name: "fortunetelling-horoscopes"
  }, {
    path: "/job_matching/outsources",
    component: _48807ba6,
    name: "job_matching-outsources"
  }, {
    path: "/job_matching/type_select",
    component: _6750e33b,
    name: "job_matching-type_select"
  }, {
    path: "/lp/buyer",
    component: _a0c1afdc,
    name: "lp-buyer"
  }, {
    path: "/lp/provider",
    component: _c0313b70,
    name: "lp-provider"
  }, {
    path: "/mizuho_coconala/company_profile",
    component: _378fdc82,
    name: "mizuho_coconala-company_profile"
  }, {
    path: "/mypage/analytics",
    component: _53663fdc,
    name: "mypage-analytics"
  }, {
    path: "/mypage/blog_orders",
    component: _c52ef690,
    name: "mypage-blog_orders"
  }, {
    path: "/mypage/blogs",
    component: _db7265b2,
    name: "mypage-blogs"
  }, {
    path: "/mypage/bookmarks_requests",
    component: _0dd9cdf4,
    name: "mypage-bookmarks_requests"
  }, {
    path: "/mypage/code_apply",
    component: _4e9ea548,
    name: "mypage-code_apply"
  }, {
    path: "/mypage/dashboard",
    component: _24cc0827,
    name: "mypage-dashboard"
  }, {
    path: "/mypage/dashboard_provider",
    component: _cfd7858e,
    name: "mypage-dashboard_provider"
  }, {
    path: "/mypage/email_optout",
    component: _439a30be,
    name: "mypage-email_optout"
  }, {
    path: "/mypage/invite",
    component: _e28337ee,
    name: "mypage-invite"
  }, {
    path: "/mypage/invoice_number",
    component: _1a241598,
    name: "mypage-invoice_number"
  }, {
    path: "/mypage/job_matching",
    component: _270d1fff,
    name: "mypage-job_matching"
  }, {
    path: "/mypage/language",
    component: _4bf9f995,
    name: "mypage-language"
  }, {
    path: "/mypage/offers",
    component: _46840d17,
    name: "mypage-offers"
  }, {
    path: "/mypage/orders",
    component: _7da7dfe2,
    name: "mypage-orders",
    children: [{
      path: "canceled",
      component: _37039a56,
      name: "mypage-orders-canceled"
    }, {
      path: "closed",
      component: _76e80a2e,
      name: "mypage-orders-closed"
    }, {
      path: "flags",
      component: _de1b4fcc,
      name: "mypage-orders-flags"
    }, {
      path: "open",
      component: _9056d3f2,
      name: "mypage-orders-open"
    }, {
      path: "requests",
      component: _573a2ffe,
      name: "mypage-orders-requests"
    }, {
      path: "required",
      component: _24954e5c,
      name: "mypage-orders-required"
    }, {
      path: "trash",
      component: _2fd69c4b,
      name: "mypage-orders-trash"
    }]
  }, {
    path: "/mypage/promotion",
    component: _a260be8e,
    name: "mypage-promotion"
  }, {
    path: "/mypage/provider_coupons",
    component: _72fa1002,
    name: "mypage-provider_coupons"
  }, {
    path: "/mypage/received_favorites",
    component: _cd9efa0e,
    name: "mypage-received_favorites"
  }, {
    path: "/mypage/received_favorites_blogs",
    component: _3fa343ea,
    name: "mypage-received_favorites_blogs"
  }, {
    path: "/mypage/received_favorites_users",
    component: _87e8c37c,
    name: "mypage-received_favorites_users"
  }, {
    path: "/mypage/received_orders",
    component: _40ffa654,
    name: "mypage-received_orders",
    children: [{
      path: "canceled",
      component: _0bc09fe2,
      name: "mypage-received_orders-canceled"
    }, {
      path: "closed",
      component: _08b61916,
      name: "mypage-received_orders-closed"
    }, {
      path: "flags",
      component: _145bc3e4,
      name: "mypage-received_orders-flags"
    }, {
      path: "open",
      component: _0daa4193,
      name: "mypage-received_orders-open"
    }, {
      path: "requests",
      component: _adc024e6,
      name: "mypage-received_orders-requests"
    }, {
      path: "required",
      component: _0d5b5830,
      name: "mypage-received_orders-required"
    }, {
      path: "trash",
      component: _d6933b82,
      name: "mypage-received_orders-trash"
    }, {
      path: "user/:userId?",
      component: _2798efea,
      name: "mypage-received_orders-user-userId"
    }]
  }, {
    path: "/mypage/sms",
    component: _460e5e2f,
    name: "mypage-sms"
  }, {
    path: "/mypage/user",
    component: _3bbec8ea,
    name: "mypage-user"
  }, {
    path: "/mypage/user_identification",
    component: _65e7e8f8,
    name: "mypage-user_identification"
  }, {
    path: "/mypage/user_interest_categories",
    component: _0025e4cc,
    name: "mypage-user_interest_categories"
  }, {
    path: "/optout/email_blmr",
    component: _c1b406a2,
    name: "optout-email_blmr"
  }, {
    path: "/orders/post_pay",
    component: _2f62c9dc,
    name: "orders-post_pay"
  }, {
    path: "/pages/about",
    component: _67cfd7eb,
    name: "pages-about"
  }, {
    path: "/pages/consent_form_mzhc",
    component: _07e9d208,
    name: "pages-consent_form_mzhc"
  }, {
    path: "/pages/cookie_policy",
    component: _dc207fe4,
    name: "pages-cookie_policy"
  }, {
    path: "/pages/terms_promotion",
    component: _413f79c9,
    name: "pages-terms_promotion"
  }, {
    path: "/pages/tokushou",
    component: _e645639c,
    name: "pages-tokushou"
  }, {
    path: "/pages/tokushou_mzhc",
    component: _31ebe805,
    name: "pages-tokushou_mzhc"
  }, {
    path: "/points/record",
    component: _84faacae,
    name: "points-record"
  }, {
    path: "/points/top",
    component: _4a8f73d3,
    name: "points-top"
  }, {
    path: "/questionnaire/user_interest_categories",
    component: _732d2d20,
    name: "questionnaire-user_interest_categories"
  }, {
    path: "/register/buyer_provider",
    component: _d352255c,
    name: "register-buyer_provider"
  }, {
    path: "/register/identity_in_progress_mzhc",
    component: _434c4dbc,
    name: "register-identity_in_progress_mzhc"
  }, {
    path: "/register/individual_corporate",
    component: _44f6b458,
    name: "register-individual_corporate"
  }, {
    path: "/register/top",
    component: _7ba13350,
    name: "register-top"
  }, {
    path: "/register/top_mzhc",
    component: _03fef4b2,
    name: "register-top_mzhc"
  }, {
    path: "/requests/add",
    component: _5fe0e4bd,
    name: "requests-add"
  }, {
    path: "/requests/recommend",
    component: _b8116e4a,
    name: "requests-recommend"
  }, {
    path: "/users/search",
    component: _26157c0e,
    name: "users-search"
  }, {
    path: "/blogs/orders/redirect",
    component: _43d7361e,
    name: "blogs-orders-redirect"
  }, {
    path: "/business/questionnaire/completed",
    component: _f3ef2824,
    name: "business-questionnaire-completed"
  }, {
    path: "/business/questionnaire/corporation",
    component: _204b0211,
    name: "business-questionnaire-corporation"
  }, {
    path: "/business/questionnaire/genre",
    component: _19ff43b6,
    name: "business-questionnaire-genre"
  }, {
    path: "/business/questionnaire/user",
    component: _ed9575d4,
    name: "business-questionnaire-user"
  }, {
    path: "/business/register/account_expired",
    component: _277650bf,
    name: "business-register-account_expired"
  }, {
    path: "/business/register/completed",
    component: _fbb8e212,
    name: "business-register-completed"
  }, {
    path: "/business/register/email_expired",
    component: _413b78ae,
    name: "business-register-email_expired"
  }, {
    path: "/business/register/email_verified",
    component: _90a1fe54,
    name: "business-register-email_verified"
  }, {
    path: "/business/register/enter_business_email",
    component: _f9dbaec6,
    name: "business-register-enter_business_email"
  }, {
    path: "/business/register/enter_information",
    component: _7686de98,
    name: "business-register-enter_information"
  }, {
    path: "/business/register/enter_open_id_email",
    component: _65d43bb3,
    name: "business-register-enter_open_id_email"
  }, {
    path: "/business/register/enter_private_email",
    component: _602cb2c0,
    name: "business-register-enter_private_email"
  }, {
    path: "/business/register/invitation",
    component: _e60db6c6,
    name: "business-register-invitation"
  }, {
    path: "/business/register/invitation_error",
    component: _25185566,
    name: "business-register-invitation_error"
  }, {
    path: "/business/register/migration_completed",
    component: _549d2974,
    name: "business-register-migration_completed"
  }, {
    path: "/business/register/migration_description",
    component: _beb9fba4,
    name: "business-register-migration_description"
  }, {
    path: "/business/register/select_account_existence",
    component: _5d632831,
    name: "business-register-select_account_existence"
  }, {
    path: "/business/register/select_account_pattern",
    component: _13953c3f,
    name: "business-register-select_account_pattern"
  }, {
    path: "/business/register/select_email",
    component: _612b589d,
    name: "business-register-select_email"
  }, {
    path: "/business/register/select_id_type",
    component: _222fd174,
    name: "business-register-select_id_type"
  }, {
    path: "/business/register/sending_completed",
    component: _30819bb3,
    name: "business-register-sending_completed"
  }, {
    path: "/business/register/welcome_back",
    component: _13a59428,
    name: "business-register-welcome_back"
  }, {
    path: "/business/settings/account",
    component: _27578459,
    name: "business-settings-account"
  }, {
    path: "/business/settings/corporation",
    component: _39460ab4,
    name: "business-settings-corporation"
  }, {
    path: "/business/settings/delete_account",
    component: _017e95c6,
    name: "business-settings-delete_account"
  }, {
    path: "/business/settings/invoice",
    component: _7f54854e,
    name: "business-settings-invoice"
  }, {
    path: "/business/settings/user",
    component: _1480734f,
    name: "business-settings-user"
  }, {
    path: "/callBack/blog/mobileCarriers",
    component: _28821687,
    name: "callBack-blog-mobileCarriers"
  }, {
    path: "/campaign/participations/15",
    component: _670a4769,
    name: "campaign-participations-15"
  }, {
    path: "/campaign/participations/16",
    component: _67185eea,
    name: "campaign-participations-16"
  }, {
    path: "/fortunetelling/horoscopes/constants",
    component: _4139a3e4,
    name: "fortunetelling-horoscopes-constants"
  }, {
    path: "/job_matching/outsources/add",
    component: _045525b8,
    name: "job_matching-outsources-add"
  }, {
    path: "/lp/buyer/onboarding-complete",
    component: _12d3916b,
    name: "lp-buyer-onboarding-complete"
  }, {
    path: "/lp/buyer/recommend",
    component: _9b361208,
    name: "lp-buyer-recommend"
  }, {
    path: "/lp/provider/onboarding-complete",
    component: _e11295be,
    name: "lp-provider-onboarding-complete"
  }, {
    path: "/lp/provider/onboarding-complete-other",
    component: _1fe843e4,
    name: "lp-provider-onboarding-complete-other"
  }, {
    path: "/lp/provider/recommend",
    component: _72f92632,
    name: "lp-provider-recommend"
  }, {
    path: "/mypage/activities/notification",
    component: _830e47ba,
    name: "mypage-activities-notification"
  }, {
    path: "/mypage/activities/transaction",
    component: _cae39208,
    name: "mypage-activities-transaction"
  }, {
    path: "/mypage/blogs/add",
    component: _1ce828b6,
    name: "mypage-blogs-add"
  }, {
    path: "/mypage/favorites/blogs",
    component: _d671d0a2,
    name: "mypage-favorites-blogs"
  }, {
    path: "/mypage/job_matching/outsources",
    component: _08ba7690,
    name: "mypage-job_matching-outsources"
  }, {
    path: "/mypage/job_matching/requests",
    component: _6bb1dbea,
    name: "mypage-job_matching-requests"
  }, {
    path: "/mypage/promotion/payment_history",
    component: _2c5a0c02,
    name: "mypage-promotion-payment_history"
  }, {
    path: "/mypage/promotion/refund",
    component: _c9813cbe,
    name: "mypage-promotion-refund"
  }, {
    path: "/mypage/promotion/start_setting",
    component: _270277da,
    name: "mypage-promotion-start_setting"
  }, {
    path: "/mypage/provider_coupons/add",
    component: _0b61298e,
    name: "mypage-provider_coupons-add"
  }, {
    path: "/mypage/reservation/calendar",
    component: _36bb285e,
    name: "mypage-reservation-calendar"
  }, {
    path: "/mypage/reservation/reservationMockData",
    component: _505f6e75,
    name: "mypage-reservation-reservationMockData"
  }, {
    path: "/mypage/user_identification/completed",
    component: _772528b4,
    name: "mypage-user_identification-completed"
  }, {
    path: "/mypage/user/awards",
    component: _4fa7644d,
    name: "mypage-user-awards"
  }, {
    path: "/mypage/user/careers",
    component: _619af9b1,
    name: "mypage-user-careers"
  }, {
    path: "/mypage/user/conditions",
    component: _05f0e0cf,
    name: "mypage-user-conditions"
  }, {
    path: "/mypage/user/educational_backgrounds",
    component: _151e4592,
    name: "mypage-user-educational_backgrounds"
  }, {
    path: "/mypage/user/licenses",
    component: _ef1d2e28,
    name: "mypage-user-licenses"
  }, {
    path: "/mypage/user/portfolios",
    component: _25065cc2,
    children: [{
      path: "",
      component: _9fd26c76,
      name: "mypage-user-portfolios"
    }]
  }, {
    path: "/mypage/user/resumes",
    component: _70c37b42,
    name: "mypage-user-resumes"
  }, {
    path: "/mypage/user/skills",
    component: _ce32264e,
    name: "mypage-user-skills"
  }, {
    path: "/mypage/user/tools",
    component: _481fb797,
    name: "mypage-user-tools"
  }, {
    path: "/pages/cookie_policy/list",
    component: _4e250212,
    name: "pages-cookie_policy-list"
  }, {
    path: "/update_registration_info/buyer/corporate",
    component: _a1e27e42,
    name: "update_registration_info-buyer-corporate"
  }, {
    path: "/update_registration_info/provider/working_condition",
    component: _490f2989,
    name: "update_registration_info-provider-working_condition"
  }, {
    path: "/business/register/invitation_link/error",
    component: _20b07bc5,
    name: "business-register-invitation_link-error"
  }, {
    path: "/business/register/invitation_link/sending_completed",
    component: _2eeb5ee3,
    name: "business-register-invitation_link-sending_completed"
  }, {
    path: "/mypage/job_matching/applied/offers",
    component: _5d01c90f,
    name: "mypage-job_matching-applied-offers"
  }, {
    path: "/mypage/job_matching/applied/outsource_applications",
    component: _030a309e,
    name: "mypage-job_matching-applied-outsource_applications"
  }, {
    path: "/mypage/job_matching/bookmarks/request",
    component: _14d0b5ad,
    name: "mypage-job_matching-bookmarks-request"
  }, {
    path: "/mypage/job_matching/outsources/types",
    component: _1361f32e,
    name: "mypage-job_matching-outsources-types"
  }, {
    path: "/mypage/user/careers/old",
    component: _3c601b74,
    name: "mypage-user-careers-old"
  }, {
    path: "/mypage/user/licenses/old",
    component: _705ef441,
    name: "mypage-user-licenses-old"
  }, {
    path: "/mypage/user/tools/old",
    component: _83d21ca8,
    name: "mypage-user-tools-old"
  }, {
    path: "/register/buyer/corporate/buyer_plan",
    component: _52dd2660,
    name: "register-buyer-corporate-buyer_plan"
  }, {
    path: "/register/buyer/corporate/buyer_type",
    component: _3ee10061,
    name: "register-buyer-corporate-buyer_type"
  }, {
    path: "/register/buyer/corporate/corporate",
    component: _1918a19a,
    name: "register-buyer-corporate-corporate"
  }, {
    path: "/register/provider/corporate/corporate",
    component: _62a619b2,
    name: "register-provider-corporate-corporate"
  }, {
    path: "/register/provider/individual/address",
    component: _7dcbccd7,
    name: "register-provider-individual-address"
  }, {
    path: "/mypage/job_matching/applied/outsource_applications/:outsourceApplicationUlid",
    component: _8584a38c,
    name: "mypage-job_matching-applied-outsource_applications-outsourceApplicationUlid"
  }, {
    path: "/mypage/job_matching/applied/outsource_applications/:outsourceApplicationUlid/talkroom",
    component: _4ba1a812,
    name: "mypage-job_matching-applied-outsource_applications-outsourceApplicationUlid-talkroom"
  }, {
    path: "/business/register/invitation_link/:linkId?",
    component: _c867635e,
    name: "business-register-invitation_link-linkId"
  }, {
    path: "/customize/requests/add/:userId",
    component: _549adc3c,
    name: "customize-requests-add-userId"
  }, {
    path: "/job_matching/outsources/jobs/:parentJobCategoryId",
    component: _06ee3606,
    name: "job_matching-outsources-jobs-parentJobCategoryId"
  }, {
    path: "/mypage/blogs/edit/:id?",
    component: _4c9db870,
    name: "mypage-blogs-edit-id"
  }, {
    path: "/mypage/coupons/provider_coupons/:couponId?",
    component: _76c091a2,
    name: "mypage-coupons-provider_coupons-couponId"
  }, {
    path: "/mypage/job_matching/outsources/:id",
    component: _1955c97b,
    name: "mypage-job_matching-outsources-id"
  }, {
    path: "/mypage/customize/requests/:id?/detail",
    component: _a6468990,
    name: "mypage-customize-requests-id-detail"
  }, {
    path: "/mypage/job_matching/outsources/:id/edit",
    component: _123fec34,
    name: "mypage-job_matching-outsources-id-edit"
  }, {
    path: "/mypage/ordermade/requests/:id?/detail",
    component: _3f610458,
    name: "mypage-ordermade-requests-id-detail"
  }, {
    path: "/mypage/job_matching/outsources/:outsourceUlid/outsource_applications/:outsourceApplicationUlid",
    component: _26b88214,
    name: "mypage-job_matching-outsources-outsourceUlid-outsource_applications-outsourceApplicationUlid"
  }, {
    path: "/mypage/job_matching/outsources/:outsourceUlid/outsource_applications/:outsourceApplicationUlid?/talkroom",
    component: _0bfcaad6,
    name: "mypage-job_matching-outsources-outsourceUlid-outsource_applications-outsourceApplicationUlid-talkroom"
  }, {
    path: "/customize/requests/add/:userId?/:serviceId",
    component: _5c041ed1,
    name: "customize-requests-add-userId-serviceId"
  }, {
    path: "/mypage/promotion/receipt/:year?/:month?",
    component: _f76c5198,
    name: "mypage-promotion-receipt-year-month"
  }, {
    path: "/mypage/user_identification/completed/:id/:device?",
    component: _75d5b982,
    name: "mypage-user_identification-completed-id-device"
  }, {
    path: "/blogs/categories/:blogCategoryId",
    component: _741b1fcc,
    name: "blogs-categories-blogCategoryId"
  }, {
    path: "/blogs/ss/:hashId?",
    component: _ea77f044,
    name: "blogs-ss-hashId"
  }, {
    path: "/business/projects/:id",
    component: _14a71cbd,
    name: "business-projects-id"
  }, {
    path: "/campaign/coupons/:acquisitionCouponId?",
    component: _68792f4e,
    name: "campaign-coupons-acquisitionCouponId"
  }, {
    path: "/campaign/participations/:campaignEventId?",
    component: _5b43356b,
    name: "campaign-participations-campaignEventId"
  }, {
    path: "/forms/delivery/:talkroomId?",
    component: _0455bf8d,
    name: "forms-delivery-talkroomId"
  }, {
    path: "/forms/estimate/:offerId?",
    component: _6bdb98e8,
    name: "forms-estimate-offerId"
  }, {
    path: "/forms/order/:talkroomId?",
    component: _656f092e,
    name: "forms-order-talkroomId"
  }, {
    path: "/fortunetelling/horoscopes/:zodiacSignId",
    component: _d2239462,
    name: "fortunetelling-horoscopes-zodiacSignId"
  }, {
    path: "/job_matching/outsources/:outsourceUlid",
    component: _640763db,
    name: "job_matching-outsources-outsourceUlid"
  }, {
    path: "/mypage/analytics/:id",
    component: _37fa8944,
    name: "mypage-analytics-id"
  }, {
    path: "/mypage/blog_orders/:blogOrderId",
    component: _325219d2,
    name: "mypage-blog_orders-blogOrderId"
  }, {
    path: "/mypage/provider_coupons/:couponId",
    component: _1c02d0a0,
    name: "mypage-provider_coupons-couponId"
  }, {
    path: "/register/auth_mzhc/:preAuthHash?",
    component: _6080681f,
    name: "register-auth_mzhc-preAuthHash"
  }, {
    path: "/register/auth/:preAuthHash?",
    component: _4b54aec8,
    name: "register-auth-preAuthHash"
  }, {
    path: "/requests/categories/:categoryId?",
    component: _2c6fe70e,
    name: "requests-categories-categoryId"
  }, {
    path: "/videos/message/:directMessagesRoomId?",
    component: _015223d2,
    name: "videos-message-directMessagesRoomId"
  }, {
    path: "/customize/requests/:id?/detail",
    component: _26ba7f64,
    name: "customize-requests-id-detail"
  }, {
    path: "/job_matching/outsources/:outsourceUlid/apply",
    component: _4e3721ba,
    name: "job_matching-outsources-outsourceUlid-apply"
  }, {
    path: "/mypage/blog_charges/:blogChargeId?/receipt",
    component: _214c2809,
    name: "mypage-blog_charges-blogChargeId-receipt"
  }, {
    path: "/mypage/services/:serviceId?/body_suggestion",
    component: _0908ebf2,
    name: "mypage-services-serviceId-body_suggestion"
  }, {
    path: "/ordermade/requests/:id?/detail",
    component: _7f27096e,
    name: "ordermade-requests-id-detail"
  }, {
    path: "/register/buyer/:entity?/user_interest_categories",
    component: _3d76780a,
    name: "register-buyer-entity-user_interest_categories"
  }, {
    path: "/register/provider/:entity?/jobs",
    component: _664311dc,
    name: "register-provider-entity-jobs"
  }, {
    path: "/register/provider/:entity?/provider_type",
    component: _3156e59c,
    name: "register-provider-entity-provider_type"
  }, {
    path: "/blogs/:id",
    component: _344e0028,
    name: "blogs-id"
  }, {
    path: "/categories/:categoryId",
    component: _0940d50f,
    name: "categories-categoryId"
  }, {
    path: "/requests/:id",
    component: _02c6ad54,
    name: "requests-id"
  }, {
    path: "/services/:id",
    component: _7bcdf273,
    name: "services-id"
  }, {
    path: "/talkrooms/:talkroomId?",
    component: _232d4ff2,
    children: [{
      path: "",
      component: _661f7d4a,
      name: "talkrooms-talkroomId"
    }, {
      path: "information",
      component: _ce9d36b8,
      name: "talkrooms-talkroomId-information"
    }, {
      path: "app/reservation",
      component: _d7e37154,
      name: "talkrooms-talkroomId-app-reservation"
    }]
  }, {
    path: "/users/:id",
    component: _62924cdf,
    name: "users-id"
  }, {
    path: "/videos/:talkroomId?",
    component: _5dc6fc11,
    name: "videos-talkroomId"
  }, {
    path: "/message/:directMessagesRoomId/report",
    component: _c532382a,
    name: "message-directMessagesRoomId-report"
  }, {
    path: "/message/:directMessagesRoomId/videos_application",
    component: _133b4160,
    name: "message-directMessagesRoomId-videos_application"
  }, {
    path: "/services/:id/reservation",
    component: _f7ebd4a6,
    name: "services-id-reservation"
  }, {
    path: "/users/:id/careers",
    component: _76facbfc,
    name: "users-id-careers"
  }, {
    path: "/users/:id/portfolios",
    component: _475b4024,
    children: [{
      path: "",
      component: _eeb2881e,
      name: "users-id-portfolios"
    }, {
      path: ":portfolioId",
      component: _2ce7dbf5,
      name: "users-id-portfolios-portfolioId"
    }]
  }, {
    path: "/users/:id/reviews",
    component: _482d8268,
    name: "users-id-reviews"
  }, {
    path: "/users/:id/services",
    component: _364fad3e,
    name: "users-id-services"
  }, {
    path: "/users/:id/skills",
    component: _26ab6605,
    name: "users-id-skills"
  }, {
    path: "/blogs/:id/:blogId",
    component: _6f08d052,
    name: "blogs-id-blogId",
    children: [{
      path: "complete",
      component: _7bf0f6f4,
      name: "blogs-id-blogId-complete"
    }, {
      path: "confirm",
      component: _55515983,
      name: "blogs-id-blogId-confirm"
    }, {
      path: "input",
      component: _96cf7766,
      name: "blogs-id-blogId-input"
    }]
  }, {
    path: "/categories/:categoryId/:categoryTypeId",
    component: _0d600bf2,
    name: "categories-categoryId-categoryTypeId"
  }, {
    path: "/register/:purpose?/:entity",
    component: _7694feac,
    name: "register-purpose-entity"
  }, {
    path: "/register/:purpose?/:entity?/user_name",
    component: _3c92f052,
    name: "register-purpose-entity-user_name"
  }, {
    path: "/",
    component: _140b13fb,
    name: "index"
  }, {
    path: "/app/requests/add",
    component: _5fe0e4bd,
    name: "app_request_add"
  }],

  parseQuery: function(query) {
      return require('qs').parse(query);
    },
  stringifyQuery: function(query) {
      let result = require('qs').stringify(query, {
        arrayFormat: 'brackets',
        skipNulls: true
      });
      return result ? '?' + result : '';
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
